import Category from '../../Components/Category/Category';
import CategoryCard from '../../Components/CategoryCard/CategoryCard';
import MainCard from '../../Components/MainCard/MainCard';
import FeaturedProduct from '../../Components/FeaturedProduct/FeaturedProduct';
import Header from '../../Components/Header/Header';
import HeaderLower from '../../Components/HeaderLower/HeaderLower';
import exterior from '../../assets/EXTERIOR-1920x793.jpg';
import exteriorMobile from '../../assets/EXTERIOR-mobile@2x.png';

export default function Exterior() {
    const opciones = ['Sillas', 'Mesas'];
    return (
        <div>
            <Header />
            <HeaderLower />
            <Category title={`Exterior`} img={exterior} imgMobile={exteriorMobile} />
            <CategoryCard title={`Exterior`} />
            <MainCard opciones={opciones} category={'EXTERIOR'} />
            <FeaturedProduct />
        </div>
    )
}