import React from 'react';

const Retiro = () => {
    return (
        <div className='text-sm text-left lg:w-3/5 lg:px-0 px-5'>
            <p className='font-semibold mt-10 mb-5'>
                ¿Puedo retirar mi compra en el punto de entrega de Sentate Con Estilo?
            </p>
            <p>
                Sí, podrás pasar a retirar SIN CARGO por nuestro depósito, con previa coordinación con el sector de envíos, en 24hs hábiles una vez acreditado el pago. Presentando la factura de tu compra. Lugar de retiro es el siguiente: CALIFORNIA 1628, Barracas CABA
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Puede retirar mi compra otra persona que no sea yo?
            </p>
            <p>
                Sí, la persona que designes puede retirar tu compra sin problema.
                Quien haga el retiro deberá presentar en la sucursal los siguientes datos:
                <p className='ml-10 mt-1'>1. Número de pedido.</p>
                <p className='ml-10'>2. Fotocopia o Foto de tu DNI en su celular.</p>
                <p className='ml-10'>3. DNI que acredite la persona que retira tu compra.</p>
                <p className='ml-10'>4. Factura de tu compra.</p>
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Qué tengo que presentar para retirar mi pedido?
            </p>
            <p>
                Para retirar tu pedido sólo debes presentar tu DNI, el número de pedido y
                la factura del mismo que te enviamos a tu correo.
                En caso de que envíes a alguien para hacer el retiro,
                tené en cuenta que debe ser mayor de 18 años, podrá retirar tu compra presentando:
                <p className='ml-10 mt-1'>1. Número de pedido.</p>
                <p className='ml-10'>2. Fotocopia o Foto de tu DNI en su celular.</p>
                <p className='ml-10'>3. DNI que acredite la persona que retira tu compra.</p>
                <p className='ml-10'>4. Factura de tu compra.</p>
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Puedo retirar mi compra en una sucursal del correo?
            </p>
            <p>
                No, por el momento no tenemos habilitada esta opción. Sólo podés retirar tu compra en nuestras sucursales o bien seleccionar la opción de envío a domicilio donde dependerá del transportista seleccionado si entrega en domicilio o retiras coordinando con el expreso.
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿En qué horarios puedo retirar mi pedido?
            </p>
            <p>
                Nuestro punto de entrega tiene horarios de L a V de 9:30hs a 14:30hs para
                retirar (previa coordinación con el sector de envíos de acuerdo con los tiempos
                de entrega estipulados para el producto).
            </p>
        </div>
    );
};

export default Retiro;