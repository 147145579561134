import './DropdownMenu.css';
import filter from '../../assets/filter.svg'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeTypeFilter, getFilter, getFilterOrigen, getProduct, priceFilter } from '../../redux/actions';
import filterImg from '../../assets/icon-filtros-sce.svg'

export default function DropdownMenu({
    opciones,
    price,
    category,
    request = false,
    tipo,
    setIsLoading,
    sendOptions,
    setSendOptions,
    selectedOptions,
    setSelectedOptions,
    filtrosPrecios,
    setFiltrosPrecios,
    tipoFilter,
    setTipoFilter,
    isShop,
    title,
    setShowMayorPrecio,
    setShowMenorPrecio,
    isOrigin,
    openOne,
    setIsOpenOne
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectOption, setSelectOption] = useState('');
    const [selectOneOption, setSelectOneOption] = useState('');
    const dispatch = useDispatch();
    const options = opciones ? opciones : ['Acrílico', 'Madera', 'Otro'];

    const toggleOption = (options) => {
        const option = options.toLowerCase();
        setSelectedOptions(prev => ({
            ...prev,
            [option]: !prev[option]
        }));
        console.log(selectedOptions)
        setSendOptions([...sendOptions, option]);
        dispatch(getProduct([...sendOptions, option], undefined, category, setIsLoading));
        if (!price && !tipo) {
            dispatch(getFilter(option));
        }
    };
    const toggleOptionOrder = (option) => {
        setTipoFilter(option);
        dispatch(changeTypeFilter(option));
    }

    const changePrice = (e) => {
        setFiltrosPrecios({
            ...filtrosPrecios,
            [e.target.name]: e.target.value
        });
    }

    const changeTypeFilterOrigen = (option) => {
        dispatch(getFilterOrigen(option));
    }

    const onClickPrice = () => {
        if (filtrosPrecios.mayorPrecio !== 5000000000) {
            setShowMayorPrecio(true);
        }
        if (filtrosPrecios.menorPrecio !== 0) {
            setShowMenorPrecio(true);
        }
        dispatch(priceFilter(filtrosPrecios));
    };
    return (
        <div className=''>
            <div className="dropdown cursor-pointer">
                <div onClick={() => {
                    setIsOpen(!isOpen)
                    setIsOpenOne(title)
                }} className='lg:flex cursor-pointer flex-row hidden'>
                    <span>{title}</span>
                    <img src={filter} alt="Icono" className="w-2 ml-2" />
                </div>
                <div className='lg:hidden'>
                    <img src={filterImg} alt="Icono" className="w-2 mt-1" />
                </div>
                {isOpen && openOne == title && tipo && !price && !isOrigin && (
                    <div onMouseLeave={() => setIsOpen(false)} className="z-[1] absolute mt-6 -left-[5rem] bg-white border-2 border-gray-300">
                        <ul className="py-1">
                            {options.map((option) => (
                                <li key={option} className="flex items-center text-[12px] pr-16 pl-4 py-2 hover:bg-gray-100">
                                    <input
                                        type="radio" // Cambiamos el tipo de input a "radio"
                                        name="options" // Definimos el nombre para el grupo de opciones
                                        checked={tipoFilter === option} // Comparamos con la opción seleccionada actualmente
                                        onChange={() => toggleOptionOrder(option)} // Actualizamos la opción seleccionada
                                        className="h-4 w-4 checked:text-black"

                                    />
                                    <span className="ml-3 whitespace-nowrap">{option}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {isOpen && openOne == title && !tipo && !price && !isShop && !isOrigin && (
                    <div onMouseLeave={() => setIsOpen(false)} className="z-[1] absolute mt-8 -left-[1rem] bg-white border-2 border-gray-300">
                        <ul className="py-1">
                            {options.map((option) => (
                                <li key={option} className="flex items-center text-[12px] pr-16 pl-4 py-2 hover:bg-gray-100">
                                    <input
                                        type="checkbox"
                                        key={option}
                                        checked={selectedOptions[option]}
                                        onChange={() => toggleOption(option)}
                                        className="h-4 w-4 checked:text-black"
                                    />
                                    <span className="ml-3">{option}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {isOpen && openOne == title && !tipo && !price && !isShop && isOrigin && (
                    <div onMouseLeave={() => setIsOpen(false)} className="z-[1] absolute mt-8 -left-[1rem] bg-white border-2 border-gray-300">
                        <ul className="py-1">
                            {options.map((option) => (
                                <li key={option} className="flex items-center text-[12px] pr-16 pl-4 py-2 hover:bg-gray-100">
                                    <input
                                        type="checkbox"
                                        key={option}
                                        checked={selectedOptions[option]}
                                        onChange={() => changeTypeFilterOrigen(option)}
                                        className="h-4 w-4 checked:text-black"
                                    />
                                    <span className="ml-3">{option}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {isOpen && openOne == title && !tipo && !price && isShop && (
                    <div>
                        <div onMouseLeave={() => {
                            if (!selectOneOption || !selectOption) {
                                setIsOpen(false);
                                setSelectOption('');
                            }
                        }} className="z-[1] absolute mt-8 -left-[1rem] bg-white border-2 border-gray-300">
                            <ul className="py-1">
                                {options.map((option) => (
                                    <li onClick={() => {
                                        setSelectOption(option.title)
                                        setSelectOneOption(true);
                                    }} key={option.title} className="flex hover:cursor-pointer items-center text-[12px] pr-16 pl-4 py-2 hover:bg-gray-100">
                                        <span className='ml-1 text-left'>{option.title.split('-').join(' e ')}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {selectOption && <div className="z-[1] absolute mt-8 left-[9.2rem] bg-white border-2 border-gray-300">
                            <ul onMouseLeave={() => !setSelectOneOption(false)} className="py-1">
                                {options.filter(item => item.title == selectOption)?.map(item => item.opciones)[0]?.map((option) => (
                                    <li key={option} onClick={() => window.location.href = `/${selectOption.toLocaleLowerCase()}/${option.toLocaleLowerCase()}`} className="flex hover:cursor-pointer items-center text-[12px] pr-16 pl-4 py-2 hover:bg-gray-100">
                                        <span className='ml-3'>{option}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>}
                    </div>
                )}
                {
                    isOpen && openOne == title && price && !tipo && !isOrigin && (
                        <div onMouseLeave={() => setIsOpen(false)} className="z-[1] absolute mt-8 -left-2 bg-white border-2 border-gray-300">
                            <div className='flex flex-col'>
                                <div className='flex flex-row'>
                                    <p className='mt-5 pl-1'>$</p>
                                    <input type="number" onChange={(e) => changePrice(e)} defaultValue={filtrosPrecios.menorPrecio !== 0 && filtrosPrecios.menorPrecio} placeholder='Desde' name='menorPrecio' className='w-28 pl-2 rounded-sm text-[12px] my-4 py-1 ml-2 mr-4 border' />
                                </div>
                                <div className='flex flex-row'>
                                    <p className='mt-1 pl-1'>$</p>
                                    <input type="number" placeholder='Hasta' defaultValue={filtrosPrecios.mayorPrecio !== 5000000000 && filtrosPrecios.mayorPrecio} onChange={(e) => changePrice(e)} name='mayorPrecio' className='w-28 pl-2 rounded-sm text-[12px] mb-5 py-1 ml-2 mr-4 border' />
                                </div>
                                <button onClick={onClickPrice} className='border py-2 font-semibold hover:bg-gray-600 hover:text-white border-black'>Filtrar</button>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}