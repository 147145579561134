
import Category from '../../Components/Category/Category';
import CategoryCard from '../../Components/CategoryCard/CategoryCard';
import MainCard from '../../Components/MainCard/MainCard';
import FeaturedProduct from '../../Components/FeaturedProduct/FeaturedProduct';
import Header from '../../Components/Header/Header';
import HeaderLower from '../../Components/HeaderLower/HeaderLower';
import Combo from '../../assets/COMBOS-1920x793.jpg';
import ComboMobile from '../../assets/COMBOS-mobile@2x.png';

export default function Collection() {
    return (
        <div>
            <Header />
            <HeaderLower />
            <Category title={`Collection`} img={Combo} imgMobile={ComboMobile} />
            <CategoryCard title={`Collection`} />
            <MainCard category={'COLLECTION'} />
            <FeaturedProduct />
        </div>
    )
}
