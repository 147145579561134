import React, { useEffect, useState } from 'react';
import ver from '../../../../assets/ver.svg';
import axios from 'axios';

const Datos = ({ setShowPass, showPass, setChange }) => {
    const token = { token: localStorage.getItem('token') };

    const [valorNombre, setValorNombre] = useState('');
    const [valorApellido, setValorApellido] = useState('');
    const [datos, setDatos] = useState('');
    const [valorDatos, setValorDatos] = useState({
        nombre: '',
        apellido: '',
        direccion0: '',
        direccion1: '',
        direccion2: '',
        fk_idtipodoc_personal: '',
        razon_social: '',
        documento_personal: '',
        fk_idsituacionimpositiva: '',
        numero_celular: ''
    });
    const [loading, setLoading] = useState(false);
    const [inputDomicilio, setInputDomicilio] = useState([]);
    const [datosPersonales, setDatosPersonales] = useState(true);
    const [datosDomicilio, setDatosDomicilio] = useState(true);

    useEffect(() => {
        setLoading(true);
        const getDatos = async () => {
            const response = await axios.post('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/datos-usuario', {
                httpMethod: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                queryStringParameters: {},
                pathParameters: {},
                body: JSON.stringify(token),
                isBase64Encoded: false,
            });
            const data = JSON.parse(response.data.body)
            if (data.error) {
                window.localStorage.removeItem('token')
                window.location.href = '/ingresar'
            }
            else {
                const datos = JSON.parse(response.data.body);
                setDatos(datos);
                setValorApellido(datos[0].apellido);
                setValorNombre(datos[0].nombre);
                setInputDomicilio(datos);
                setValorDatos({
                    nombre: datos[0].nombre,
                    apellido: datos[0].apellido,
                    direccion0: datos[0].direccion,
                    direccion1: datos[1]?.direccion,
                    direccion2: datos[2]?.direccion,
                    fk_idtipodoc_personal: datos[0].fk_idtipodoc_personal,
                    razon_social: datos[0].razon_social,
                    documento_personal: datos[0].cuit,
                    fk_idsituacionimpositiva: datos[0].fk_idsituacionimpositiva,
                    numero_celular: datos[0].celular
                });
            }
        }
        getDatos();
        setLoading(false);
    }, []);
    const handleChange = (e) => {
        if(e.target.name == 'documento_personal'){
            let value = e.target.value;
            value = value.replace(/[.-]/g, '');
            e.target.value = value;
        }
        setValorDatos({
            ...valorDatos,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async () => {
        valorDatos.fk_idtipodoc_personal = valorDatos.fk_idtipodoc_personal ? valorDatos.fk_idtipodoc_personal : 7;
        valorDatos.fk_idsituacionimpositiva = valorDatos.fk_idsituacionimpositiva ? valorDatos.fk_idsituacionimpositiva : 1;
        await axios.post('https://5fmuobow2m.execute-api.us-east-1.amazonaws.com/prod/cambiar-datos', {
            httpMethod: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            queryStringParameters: {},
            pathParameters: {},
            body: JSON.stringify({
                nombre: valorDatos.nombre,
                apellido: valorDatos.apellido,
                token: localStorage.getItem('token'),
                direccion0: valorDatos.direccion0,
                direccion1: valorDatos.direccion1,
                direccion2: valorDatos.direccion2,
                fk_idtipodoc_personal: valorDatos.fk_idtipodoc_personal,
                razon_social: valorDatos.razon_social,
                documento_personal: valorDatos.cuit,
                fk_idsituacionimpositiva: valorDatos.fk_idsituacionimpositiva,
                numero_celular: valorDatos.numero_celular
            }),
            isBase64Encoded: false,
        });
    };

    const handleDelete = async (iddomicilio) => {
        await axios.post('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/eliminar-domicilio', {
            httpMethod: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            queryStringParameters: {},
            pathParameters: {},
            body: JSON.stringify({ iddomicilio: iddomicilio }),
            isBase64Encoded: false,
        });
        const response = await axios.post('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/datos-usuario', {
            httpMethod: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            queryStringParameters: {},
            pathParameters: {},
            body: JSON.stringify(token),
            isBase64Encoded: false,
        });
        const data = JSON.parse(response.data.body)
        if (data.error) {
            window.localStorage.removeItem('token')
            window.location.href = '/ingresar'
        }
        else {
            const datos = JSON.parse(response.data.body);
            setDatos(datos);
            setValorApellido(datos[0].apellido);
            setValorNombre(datos[0].nombre);
            setInputDomicilio(datos);
            setValorDatos({
                nombre: datos[0].nombre,
                apellido: datos[0].apellido,
                direccion0: datos[0].direccion,
                direccion1: datos[1]?.direccion,
                direccion2: datos[2]?.direccion
            });
        }
    };

    return (
        <div className='flex flex-col justify-start px-10 lg:px-0'>
            <p className='text-left lg:text-sm text-[16px] font-bold'>Datos Personales</p>
            <p className='text-left lg:text-sm text-[16px]'>{valorNombre} {valorApellido}</p>
            <div className='flex lg:flex-row flex-col mt-5'>
                <input type="text" placeholder='Nombre' onChange={handleChange} name='nombre' defaultValue={valorNombre} disabled={datosPersonales} className='disabled: border lg:py-1 py-2.5 lg:w-[50%] w-full my-2.5 pl-2.5 hover:border-[#E40F1B] rounded-md' />
                <div className='flex lg:w-[48.7%]'>
                    <input type="text" defaultValue={valorApellido} name='apellido' onChange={handleChange} disabled={datosPersonales} placeholder='Apellido' className='border py-2.5 lg:ml-2 lg:w-full w-full my-2.5 pl-2.5 hover:border-[#E40F1B] rounded-md' />
                </div>
                <div>
                    <button

                        className='lg:w-40 w-full lg:ml-1.5 lg:mr-16 rounded-md lg:h-13 h-12 mt-2'
                    >
                    </button>
                </div>
            </div>
            <div className='flex lg:flex-row flex-col mt-5'>
                <select value={valorDatos.fk_idtipodoc_personal} name='fk_idtipodoc_personal' onChange={handleChange} disabled={datosPersonales} className='disabled: border lg:py-1 py-2.5 lg:w-[50%] w-full my-2.5 pl-2.5 hover:border-[#E40F1B] rounded-md'>
                    <option value="7">DNI</option>
                    <option value="2">CUIL</option>
                    <option value="1">CUIT</option>
                </select>
                <div className='flex lg:w-[48.7%]'>
                    <input type="text" defaultValue={valorDatos.documento_personal} name='documento_personal' onChange={handleChange} disabled={datosPersonales} placeholder='Documento' className='border py-2.5 lg:ml-2 lg:w-full w-full my-2.5 pl-2.5 hover:border-[#E40F1B] rounded-md' />
                </div>
                <div>
                    <button

                        className='lg:w-40 w-full lg:ml-1.5 lg:mr-16 rounded-md lg:h-13 h-12 mt-2'
                    >
                    </button>
                </div>
            </div>
            <div className='flex mt-5 lg:w-[38.5%]'>
                <input type="text" defaultValue={valorDatos?.numero_celular} name='numero_celular' onChange={handleChange} disabled={datosPersonales} placeholder='Número de celular' className='border py-2.5 lg:w-full w-full my-2.5 pl-2.5 hover:border-[#E40F1B] rounded-md' />
            </div>
            {valorDatos.fk_idtipodoc_personal == '1' &&
                <div className='flex lg:flex-row flex-col mt-5'>
                    <input type="text" defaultValue={valorDatos.razon_social} name='razon_social' onChange={handleChange} disabled={datosPersonales} placeholder='Nombre / Razón social' className='disabled: border lg:py-1 py-2.5 lg:w-[50%] w-full my-2.5 pl-2.5 hover:border-[#E40F1B] rounded-md' />
                    <div className='flex lg:w-[48.7%]'>
                        <select value={valorDatos.fk_idsituacionimpositiva} name='fk_idsituacionimpositiva' onChange={handleChange} disabled={datosPersonales} className='border py-2.5 lg:ml-2 lg:w-full w-full my-2.5 pl-2.5 hover:border-[#E40F1B] rounded-md'>
                            <option value="1">IVA Responsable Inscripto</option>
                            <option value="2">Responsable Monotributo</option>
                            <option value="4">IVA Sujeto Exento</option>
                        </select>
                    </div>
                    <div>
                        <button

                            className='lg:w-40 w-full lg:ml-1.5 lg:mr-16 rounded-md lg:h-13 h-12 mt-2'
                        >
                        </button>
                    </div>
                </div>
            }
            <div className='flex flex-row lg:mt-4 mt-3'>
                <input type="checkbox" name="" id="" />
                <p className='text-sm ml-2'>Suscribirme al Newsletter de novedades</p>
            </div>
            <div className='flex lg:flex-row flex-col mt-5'>
                <button
                    onClick={() => {
                        setDatosPersonales(!datosPersonales);
                        if (!datosPersonales) {
                            handleSubmit();
                        }
                    }}
                    className='lg:w-full w-full rounded-md lg:h-13 h-12 mt-2 border border-bg-gray-700 hover:text-white text-black hover:bg-gray-500'
                >
                    {!datosPersonales ? "Guardar" : "Editar"}
                </button>
                <div>
                    <button

                        className='lg:w-40 w-full lg:ml-1.5 lg:mr-16 rounded-md lg:h-13 h-12 mt-2'
                    >
                    </button>
                </div>
            </div>
            <div className='border-t-2 my-7'></div>
            <p className='text-left text-sm font-bold'>Mis Direcciones</p>
            <div className='flex lg:flex-row flex-col'>
                <div className='flex flex-col w-full'>
                    {!loading && inputDomicilio[0]?.direccion ? inputDomicilio.map((_, index) => (
                        <div className='flex flex-row'>
                            <div className='flex flex-col w-full'>
                                <p className='text-left mt-5 text-sm flex'>Dirección {inputDomicilio[index].tipo === 'Facturación' ? 'de Facturación' : 'de Envío'}</p>
                                <input
                                    key={index}
                                    type="text"
                                    name={`direccion${index}`}
                                    onChange={handleChange}
                                    defaultValue={inputDomicilio[index].alternativa}
                                    disabled={datosDomicilio}
                                    placeholder="Dirección"
                                    className='w-full border pl-4 py-3 rounded-md'
                                />
                            </div>
                            <div className='w-1/3 flex'>
                                <a href={`/mis-datos/editar-domicilio?domicilio=${datos[index].iddomicilio}`}>
                                    <button
                                        className='lg:w-20 w-full lg:ml-1.5 lg:mt-10 rounded-md lg:h-13 h-12 mt-2 border border-bg-gray-700 hover:text-white text-black hover:bg-gray-500'
                                    >
                                        Editar
                                    </button>
                                </a>
                                <button
                                    onClick={() => handleDelete(inputDomicilio[index].iddomicilio)}
                                    className='lg:w-20 w-full lg:ml-1.5 lg:mt-10 rounded-md lg:h-13 h-12 mt-2 border border-bg-gray-700 hover:text-white text-black hover:bg-red-500'
                                >
                                    Eliminar
                                </button>
                            </div>
                        </div>
                    )) : null}
                </div>
            </div>
            <button onClick={() => setChange('domicilio')} className='uppercase hover:bg-gray-500 text-black hover:text-white lg:w-[75%] w-full border lg:py-3 py-5 text-sm lg:mt-10 mt-5 mb-5 border-gray-500 bg-white rounded-md'>Agregar otra dirección</button>
        </div>
    );
};

export default Datos;