import React from 'react';

const Medios = () => {
    return (
        <div className='text-sm text-left lg:w-3/5 lg:px-0 px-5'>
            <p className='mt-10 mb-5'>
                Contamos con los siguientes medios de pago válidos para compras online: Mercado Pago y/o transferencia bancaria. Es derecho de ESTILO 1+1 S.A.S, la inclusión, exclusión o suspensión de cualquier medio de pago. Comprobantes en venta on-line: Se emitirán facturas electrónicas a Consumidor Final (Comprobantes tipo "B") y Responsable Inscripto (Comprobantes tipo "A").
            </p>
            <p className='font-semibold text-lg mt-10 mb-5'>
                MercadoPago / Tarjetas de Crédito
            </p>
            <p>
                6 cuotas sin interés con Plan Ahora 3 con tarjetas bancarias Visa, MasterCard y Amex de bancos autorizados, todos los días en todos los productos de la web. Pagá el total divido en dos tarjetas: Con Mercado Pago ahora podes abonar el total de tu compra con dos tarjetas de crédito y especificar el monto que deseas pagar con cada una.
            </p>
            <p className='font-semibold text-lg mt-10 mb-5'>
                Transferencia bancaria o Efectivo
            </p>
            <p>
                20% Off el descuento se debe aplicar sobre los precios online (acumulable a los precios en SALE). Abonar con transferencia bancaria y luego enviar comprobante de pago a hola@sentate-conestilo.com. La compra quedará confirmada una vez acreditado el pago.
            </p>
            <p className='font-semibold mt-10 mb-2'>Datos bancarios:</p>
            <div className='ml-10'>
                <p>ESTILO 1+1 SAS </p>
                <p>CUIT 30-71617029-9 </p>
                <p>GALICIA Cuenta corriente # 9215-9 064-1 </p>
                <p>CBU 0070064120000009215913</p>
            </div>

            <p className='mt-10 font-semibold mb-5'>Por inconvenientes al momento de la compra o si necesitas asistencia escribinos a: hola@sentate-conestilo.com</p>
            <div className='mt-10 border-b-2 mb-32'></div>
        </div>
    );
};

export default Medios;