import './Category.css';
import icon from '../../assets/icons-whatsapp.png';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min.js';
import { useState, useEffect } from 'react';
import foltanteWhatsapp from '../../assets/flotante_whatsapp.svg';
import foltanteWhatsappHover from '../../assets/flotante_whatsapp_hover.svg';

export default function Category({ title, img, imgMobile }) {
    const { tipoDeProducto } = useParams();
    const [hoverWSP, setHoverWSP] = useState(false);
    const [scrollY, setScrollY] = useState(0);
    const tipoForImage = tipoDeProducto ? tipoDeProducto.toLocaleUpperCase() : '';

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const imagePath = () => {
        try {
            return require(`../../assets/${tipoForImage}-1920x793.jpg`);
        } catch (error) {
            return img;
        }
    }
    const imagePathMobile = () => {
        try {
            return require(`../../assets/${tipoForImage.toLocaleUpperCase()}-mobile@2x.png`);
        } catch (error) {
            return imgMobile;
        }
    }
    console.log(imagePathMobile)
    return (
        <div className="relative overflow-hidden w-full h-full">
            <div className="pt-20 lg:flex hidden font-medium text-[14px]">
                <img src={tipoForImage ? imagePath(tipoForImage) : img} className='object-cover object-right w-full lg:h-full h-[350px]' alt="Imagen Categoría" />
            </div>
            <div className="pt-20 lg:hidden font-medium text-[14px]">
                <img src={tipoForImage ? imagePathMobile(tipoForImage) : imgMobile} className='object-cover object-right w-full lg:h-full' alt="Imagen Categoría" />
            </div>
            {!hoverWSP ? <button onMouseEnter={() => setHoverWSP(true)} className='fixed bottom-0 right-5 z-[1000]'>
                <a target='_blank' href='https://api.whatsapp.com/send?phone=541132619277'>
                    <img src={foltanteWhatsapp} className='mb-5' alt="" />
                </a>
            </button> :
                <button onMouseLeave={() => setHoverWSP(false)} className='fixed bottom-0 right-5 z-[1000]'>
                    <a target='_blank' href='https://api.whatsapp.com/send?phone=541132619277'>
                        <img src={foltanteWhatsappHover} className='mb-5' alt="" />
                    </a>
                </button>
            }
        </div>
    );
}