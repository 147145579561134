import React from 'react';

const About = () => {
    return (
        <div className='text-sm text-left lg:w-3/5 lg:px-0 px-5'>
            <p className='font-semibold text-lg'>Sobre los Productos</p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Tienen stock o se hace todo a pedido?
            </p>
            <p>
                ¡Tenemos gran cantidad de stock en depósito! pero también ofrecemos una amplia variedad de opciones de tonalidades y tamaños para adaptarse a diferentes gustos y necesidades. No es posible tener stock de todas las opciones debido a la diversidad de modelos disponibles. Para entrega inmediata, solo deben comunicarse por WhatsApp y consultarnos los modelos disponibles.
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Se pueden hacer modificaciones en las medidas?
            </p>
            <p>
                Sí, se puede, siempre previa consulta, dependiendo la modificación tiene recargo.
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Destiñen las telas?
            </p>
            <p>
                No, tampoco achican, son telas sanforizadas, es un tratamiento industrial que se realiza a los tejidos previamente. Recomiendo no exponer colores oscuros a la intensidad de los rayos UV, ya que pueden perder su tonalidad.
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Tiempo de producción?
            </p>
            <p>
                El tiempo de producción es de acuerdo a cada tipo de producto y cantidades.
            </p>
            <p className='font-semibold mt-10 mb-5'>
                Embalajes
            </p>
            <p className='font-semibold mt-5 mb-5'>
                Mesas:
            </p>
            <div className='ml-5'>
                <p>• Las mesas llevan un embalaje especial dependiendo del tipo de modelo.</p>
                <p>• Las tapas de las mesas importadas tienen un embalaje con separadores internos y tapas de telgopor, la caja contenedora está conformada por triple cartón craft de exportación. </p>
                <p>• Contiene refuerzos en los sectores de posible apoyo y contacto. </p>
                <p>• Adicionalmente se acompaña con una segunda caja conteniendo las piezas de ensamble para sus patas con la herramienta de ajuste </p>
                <p>• Las mesas de fabricación nacional, se transportan en un esqueleto de madera con interior de telgopor y film de bolitas de aire, consultar costo del mismo.</p>
            </div>
            <p className='font-semibold mt-5 mb-5'>
                Sillas y demás mobiliarios:
            </p>
            <div className='ml-5'>
                <p>• Dependiendo del modelo y cantidad de entrega, se preparan en caja triple craft de cartón exportación. </p>
                <p>• Si la cantidad es de una unidad, se protege con base de cartón triple craft, cobertor de respaldo del mismo material y film contenedor encintando el embalaje. </p>
            </div>
            <div className='mt-10 border-b-2 mb-32'></div>
        </div>
    );
};

export default About;