import React, { useEffect, useState } from 'react';
import Header from '../../../Components/Header/Header';
import HeaderLower from '../../../Components/HeaderLower/HeaderLower';
import axios from 'axios';
import ver from '../../../assets/ver.svg';
import noVer from '../../../assets/no-ver.svg';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const Cambiar = () => {
    const [showPass, setShowPass] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [passwordAgain, setPasswordAgain] = useState('');
    const [error, setError] = useState('');
    const tokenUser = { token: localStorage.getItem('token') };
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (tokenUser.token !== null) {
            window.location.href = '/';
        }
    }, [])
    const passwordRegex = /^(?=.*[A-Z]).{8,}$/;

    const { token } = useParams();

    const asunto = "Tu contraseña en Sentate con Estilo fue cambiada con éxito";

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!passwordRegex.test(newPassword)) {
            setError('La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula, un número y un caracter especial');
            setLoading(false);
            return;
        }
        if (newPassword !== passwordAgain) {
            setError('Las contraseñas no coinciden');
            setLoading(false);
            return;
        }
        else setError('');
        const response = await axios.post(`https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/recuperar/cambiar-clave`, {
            httpMethod: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            queryStringParameters: {},
            pathParameters: {},
            path: "/" + token,
            body: JSON.stringify({ newPassword }),
            isBase64Encoded: false,
        });
        const data = JSON.parse(response.data.body);
        const mail = data.userEmail;
        const cuerpoCorreo = `<body style="margin: 0; padding: 0; font-family: Arial, sans-serif; background-color: #f4f4f4;"><div style="max-width: 600px; margin: 20px auto; background-color: #ffffff; padding: 20px; box-shadow: 0 4px 8px rgba(0,0,0,0.1); border-radius: 10px;"><div style="text-align: left; margin-bottom: 20px;"><img src="https://www.sentateconestilo.com.ar/logo-horizontal-ok+(1).png" alt="Logo SCE" title="Logo SCE" style="width: 190px; height: auto; display:block;"></div><h1 style="color: #333333; font-size: 24px; text-align: left; margin-bottom: 10px;">Tu contraseña en Sentate con Estilo fue cambiada con éxito</h1><p style="font-size: 16px; color: #555555; text-align: left; margin-bottom: 30px;">Tu contraseña fue actualizada correctamente, por favor vuelve a iniciar sesión.</p><div style="text-align: left; margin-bottom: 20px;"><a href="https://www.sentateconestilo.com.ar/ingresar" style="background-color: #000000; color: #ffffff; padding: 10px 20px; text-decoration: none; border-radius: 5px; display: inline-block;">IR A LA TIENDA</a></div><p style="font-size: 16px; color: #555555; text-align: left; margin-bottom: 30px;">¡Contraseña cambiada!</p><div style="font-size: 14px; color: #777777; text-align: left; border-top: 1px solid #dddddd; padding-top: 20px;">Por cualquier consulta podés responder este correo electrónico o contactarnos a través de <a href="mailto:hola@sentate-conestilo.com" style="color: #000000; text-decoration: none;">hola@sentate-conestilo.com</a> o por <a href="https://api.whatsapp.com/send?phone=541132619277" style="color: #000000; text-decoration: none;">WhatsApp</a></div></div></body>`
        await axios.get("https://sistema.depcsuite.com/certificanna/enviarCorreo", {
            params: {
                asunto: asunto,
                correo: mail,
                CuerpoCorreo: cuerpoCorreo
            },
            headers: {
                "Content-Type": "application/json"
            }
        });
        setLoading(false);
        window.location.href = '/ingresar?changePass=true';
    };

    return (
        <div>
            <Header />
            <HeaderLower />
            <div className='flex flex-col pt-20 justify-center'>
                <div className='bg-[#EFEFED] w-full pt-10'>
                    <div className='2xl:pl-[14%] 2xl:pr-[10%]'>
                        <p className='text-[12px] text-left ml-16 font-semibold'><a className='font-normal' href="/">Home {'>'}</a> Cambiar contraseña</p>
                        <p className='text-5xl my-10 pb-20'>
                            Cambiar contraseña
                        </p>
                    </div>
                </div>
                <div className='flex flex-col mt-10 justify-center items-center'>
                    {error && <p className='text-[#E40F1B] pr-4 mb-5 opacity-80 text-sm font-semibold'>{error}</p>}
                    <p className='text-center mb-5 opacity-50 text-[13px]'>Te enviaremos un email para recuperar tu contraseña.</p>
                    <div className='w-full'>
                        <button className='absolute lg:left-[77%] left-[80%] lg:mt-4 mt-8' onClick={() => setShowPass(!showPass)}>
                            {showPass ?
                                <img src={noVer} className='opacity-50' alt="" />
                                :
                                <img src={ver} className='opacity-50' alt="" />
                            }
                        </button>
                        <input onChange={(e) => setNewPassword(e.target.value)} type={showPass ? "text" : "password"} placeholder='Contraseña' className='border lg:py-1 py-5 my-2.5 pl-2.5 hover:border-[#E40F1B] rounded-sm w-4/5 lg:w-[60%]' />
                    </div>
                    <div className='w-full'>
                        <button className='absolute lg:left-[77%] left-[80%] lg:mt-4 mt-8' onClick={() => setShowPass(!showPass)}>
                            {showPass ?
                                <img src={noVer} className='opacity-50' alt="" />
                                :
                                <img src={ver} className='opacity-50' alt="" />
                            }
                        </button>
                        <input onChange={(e) => setPasswordAgain(e.target.value)} type={showPass ? "text" : "password"} placeholder='Repetir Contraseña' className='border lg:py-1 py-5 my-2.5 pl-2.5 hover:border-[#E40F1B] rounded-sm w-4/5 lg:w-[60%]' />
                    </div>
                    <button onClick={(e) => {
                        setLoading(true)
                        handleSubmit(e)
                    }} className='bg-black flex justify-center text-white lg:w-[60%] w-4/5 lg:py-2 py-6 text-sm mt-2.5 mb-5 hover:bg-[#E40F1B] uppercase rounded-sm'>{loading ?
                        <svg aria-hidden="true" class="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-[#E40F1B]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                        : 'CAMBIAR'}</button>
                    <div className='mb-20 text-sm opacity-50'>
                        <div className=''><a className='border-gray-400 border-b-2' href="/ingresar">Cancelar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cambiar;