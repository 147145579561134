import filter from '../../assets/filter.svg'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import filterImg from '../../assets/icon-filtros-sce.svg'
import { changeTypeFilter, getFilter, getProduct, priceFilter } from '../../redux/actions';

export default function FilterMobile({
    opciones,
    price,
    category,
    request = false,
    tipo,
    setIsLoading,
    sendOptions,
    setSendOptions,
    selectedOptions,
    setSelectedOptions,
    filtrosPrecios,
    setFiltrosPrecios,
    tipoFilter,
    setTipoFilter,
    isOpen,
    setIsOpen }) {
    const dispatch = useDispatch();
    const options = opciones ? opciones : ['Acrílico', 'Madera', 'Otro'];

    const toggleOption = (option) => {
        setSelectedOptions(prev => ({
            ...prev,
            [option]: !prev[option]
        }));
        setSendOptions([...sendOptions, option]);
        dispatch(getProduct([...sendOptions, option], undefined, category, setIsLoading));
        if (!price && !tipo) {
            dispatch(getFilter(option));
        }
    };
    const toggleOptionOrder = (option) => {
        setTipoFilter(option);
        dispatch(changeTypeFilter(option));
    }

    const changePrice = (e) => {
        setFiltrosPrecios({
            ...filtrosPrecios,
            [e.target.name]: e.target.value
        });

    }

    const onClickPrice = () => {
        dispatch(priceFilter(filtrosPrecios));
    };

    return (
        <div className=''>
            <div className="bg-white">
                <img src={filterImg} alt="Icono" className="w-full" />
                {isOpen && tipo && !price && (
                    <div className="z-[1] absolute mt-6 -left-[11rem] bg-white border-2 border-gray-300">
                        <ul className="py-1">
                            {options.map((option) => (
                                <li key={option} className="flex items-center text-[12px] pr-16 pl-4 py-2 hover:bg-gray-100">
                                    <input
                                        type="radio" // Cambiamos el tipo de input a "radio"
                                        name="options" // Definimos el nombre para el grupo de opciones
                                        checked={tipoFilter === option} // Comparamos con la opción seleccionada actualmente
                                        onChange={() => toggleOptionOrder(option)} // Actualizamos la opción seleccionada
                                        className="h-4 w-4 checked:text-black"
                                    />
                                    <span className="ml-3 whitespace-nowrap">{option}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <div>
                    {isOpen &&
                        <div className=''>
                            <div className="z-[100] fixed top-20 left-0 w-full h-screen bg-white border-2 border-gray-300">
                                <button
                                    onClick={() => setIsOpen(!isOpen)} // Cambiar el estado de isOpen para cerrar el filtro
                                    className="absolute top-0 right-0 m-4 p-2 rounded-full bg-gray-200 hover:bg-gray-300"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </button>
                                <div className='mx-5'>
                                    <div>
                                        <p className='text-left mt-10 mb-3'>Precio</p>
                                        <div className='flex flex-row'>
                                            <div className='flex flex-row'>
                                                <p className='mt-3 pl-1'>$</p>
                                                <input type="number" onChange={(e) => changePrice(e)} placeholder='Desde' name='menorPrecio' className='w-36 pl-2 rounded-sm text-[12px] py-3 ml-2 mr-4 border' />
                                            </div>
                                            <div className='flex flex-row'>
                                                <p className='mt-3 pl-1'>$</p>
                                                <input type="number" placeholder='Hasta' onChange={(e) => changePrice(e)} name='mayorPrecio' className='w-36 pl-2 rounded-sm text-[12px] py-3 ml-2 mr-4 border' />
                                            </div>
                                        </div>
                                        <div>
                                            <button onClick={() => onClickPrice()} className='rounded-sm w-1/2 mt-3 ml-4 text-black border text-sm py-3'>Aplicar</button>
                                        </div>
                                    </div>
                                    <ul className="py-1">
                                        {options.map((option) => (
                                            <li key={option} className="flex items-center text-[12px] pr-16 py-2 hover:bg-gray-100">
                                                <input
                                                    type="checkbox"
                                                    key={option}
                                                    checked={selectedOptions[option] || false}
                                                    onChange={() => toggleOption(option)}
                                                    className="h-4 w-4 checked:text-black"
                                                />
                                                <span className="ml-3">{option}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
