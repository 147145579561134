import logo from '../../assets/sce-logo-horizontal-blanco.svg'
import ig from '../../assets/instagram.svg'
import fb from '../../assets/facebook.svg'
import wsp from '../../assets/whatsapp.svg'
import { useState } from 'react';
import axios from 'axios';

export default function FooterMobile() {
    const [email, setEmail] = useState('');
    const cuerpoCorreo = `<body><div style='text-align:center;'><p>Hola, se contactó el siguiente correo por venta mayorista ${email}</p></div></body>`

    const params = {
        asunto: "Nuevo mensaje Venta mayorista",
        correo: 'hola@sentate-conestilo.com',
        CuerpoCorreo: cuerpoCorreo
    };

    const onClickEmail = async () => {
        await axios.get("https://sistema.depcsuite.com/certificanna/enviarCorreo", {
            params: params,
            headers: {
                "Content-Type": "application/json"
            }
        });
    }

    return (
        <div className='flex flex-col bg-[#1C1C1C] text-white items-center'>
            <div>
                <img className="w-[195.43px] h-[18.01px] mt-5 mb-4" src={logo} alt="Logo" />
                <span className="text-left mt-20 text-sm my-5">
                    <a href="mailto:
                        hola@sentate-conestilo.com
                    ">
                        hola@sentate-conestilo.com
                    </a>
                </span>
                <div className="flex flex-row justify-center mt-5">
                    <a href='https://www.instagram.com/sentate_con_estilo/' target="_blank" rel="noopener noreferrer">
                        <img src={ig} className='h-6 w-6' alt="social" />
                    </a>
                    <a href='https://www.facebook.com/profile.php?id=100063771705288&mibextid=WC7FNe&rdid=vDdwEXVSg5Kxwvfq' target="_blank" rel="noopener noreferrer">
                        <img src={fb} className='h-6 w-6 mx-4' alt="social" />
                    </a>
                    <a href='https://api.whatsapp.com/send?phone=541132619277' target="_blank" rel="noopener noreferrer">
                        <img src={wsp} className='h-6 w-6' alt="social" />
                    </a>
                </div>
            </div>
            <div className='grid grid-cols-2 text-left gap-10 mt-4 px-5'>
                <div className='col-span-1 flex flex-col'>
                    <p className='font-bold text-[16px]'>Atención al cliente</p>
                    <a href="/atencion-al-cliente/entrega" className='opacity-90 text-[14px]'>Preguntas frecuentes</a>
                    <a href="/atencion-al-cliente/politicas-envio" className='opacity-90 text-[14px]'>Políticas de envío</a>
                    <a href="/atencion-al-cliente/politicas-devolucion" className='opacity-90 text-[14px]'>Cambios y devoluciones</a>
                    <a href="/atencion-al-cliente/politicas" className='opacity-90 text-[14px]'>Garantía</a>
                    <a href="/atencion-al-cliente/tienda" className='opacity-90 text-[14px]'>¿Cómo comprar online?</a>
                    <a href="/nosotros" className='font-bold text-[14px]'>Nosotros</a>
                    <a href="/atencion-al-cliente/medios" className='opacity-90 text-[14px]'>Medios de pago</a>
                    <a href="/atencion-al-cliente/metodos" className='opacity-90 text-[14px]'>Métodos de envío</a>
                    <a href="/atencion-al-cliente/retiro" className='opacity-90 text-[14px]'>Retiro de deposito</a>
                </div>
                <div className='col-span-1 flex flex-col'>
                    <p className='font-bold text-[16px]'>Productos</p>
                    <a href="/sillas" className='opacity-90 text-[14px]'>Sillas & Banquetes</a>
                    <a href="/mesas" className='opacity-90 text-[14px]'>Mesas</a>
                    <a href="/puffs-sillones" className='opacity-90 text-[14px]'>Puffs & Sillones</a>
                    <a href="/deco-iluminaciones" className='opacity-90 text-[14px]'>Deco & Iluminación</a>
                    <a href="/exterior" className='opacity-90 text-[14px]'>Exterior</a>
                    <a href="/otros" className='opacity-90 text-[14px]'>Otros</a>
                </div>
            </div>
            <div className="col-span-2 flex flex-col mx-5 mt-5 mb-14">
                <span className='text-center'>Ventas mayoristas</span>
                <div className='relative'>
                    <input onChange={(e) => setEmail(e.target.value)} type="text" className='bg-[#1C1C1C] text-base border pr-3 w-72 pl-2 py-2 border-white rounded-sm' placeholder="Dejanos tu mail" />
                    <img onClick={() => onClickEmail()} className='hover:cursor-pointer absolute right-3 top-3.5 w-3' src="https://img.icons8.com/ios-filled/50/FFFFFF/sort-right.png" alt="sort-right" />
                </div>
            </div>
        </div>
    );
}