import React, { useEffect, useState } from 'react';
import truck from '../../../assets/truck_black.svg';
import calendar from '../../../assets/calendar_black.svg';
import CardDatos from './cardDatos/CardDatos';
import { useSelector } from 'react-redux';
import axios from 'axios';
import cart from '../../../assets/carrito_Enough.svg';
import plus from '../../../assets/__plus.svg';
import minus from '../../../assets/__minus.svg';
import Select from 'react-select';

const initialDatosDomicilio = () => ({
    direccion: '',
    fk_idprov: 1,
    codpost: '',
    fk_idpais: 12,
    dpto: '',
    piso: '',
    numero_celular: '',
    calle: '',
    alias: '',
    entre_calles: '',
    alternativa: '',
    descripcion: '',
    email: '',
    retiro: '',
    fk_idtipodoc_persona: 7,
    documento_personal: '',
    nombre: '',
    apellido: '',
    fk_idlocalidad: 0,
    razon_social: '',
    situacion: '3'
});

const initialDatosFacturacion = () => ({
    direccionFacturacion: '',
    fk_idprovFacturacion: 0,
    codpostFacturacion: '',
    fk_idpaisFacturacion: 12,
    fk_idtipodoc_personaFacturacion: 7,
    documento_personalFacturacion: '',
    fk_idlocalidadFacturacion: 0,
    razon_socialFacturacion: '',
    situacionFacturacion: '1'
});

const Datos = ({ setStep, step, cuponTrue, setCuponTrue, setEnvio, envio, setCuponProductos, cuponProductos }) => {
    const carrito = useSelector((state) => state.carrito);
    const precioDolar = useSelector((state) => state.dolarPrice);
    const [provincias, setProvincias] = useState([]);
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('token');
    const [loadingStep, setLoadingStep] = useState(false);
    const [retiro, setRetiro] = useState('Envía a domicilio');
    const [errorCupon, setErrorCupon] = useState(false);
    const [tipoDireccion, setTipoDireccion] = useState("Envío");
    const [datos, setDatos] = useState();
    const [domicilioNew, setDomicilioNew] = useState('new');
    const [cupon, setCupon] = useState();
    const [formErrors, setFormErrors] = useState({});
    const [localidades, setLocalidades] = useState([]);
    const [localidadesFacturacion, setLocalidadesFacturacion] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [retiroAlLocal, setRetiroAlLocal] = useState(false);
    const [facturacionDomicilio, setFacturacionDomicilio] = useState(false);
    const [datosDomicilio, setDatosDomicilio] = useState(initialDatosDomicilio());
    const [datosFacturacion, setDatosFacturacion] = useState(initialDatosFacturacion());

    useEffect(() => {
        const getDatos = async () => {
            const response = await axiosPost('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/datos-usuario', { token });
            const datosResponse = JSON.parse(response.data.body);
            setDatos(datosResponse);
            setDatosDomicilio(mapDatosDomicilio(datosResponse[0]));
            setDomicilioNew(datosResponse[0].iddomicilio || 'new');
            await fetchProvincias();
            await fetchLocalidades(datosResponse[0].fk_idprov || 1);
            setLoading(true);
        };
        const fetchLocalidadesFacturacion = async () => {
            const responseLocalidades = await axios.post('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/conseguir-localidades', {
                httpMethod: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                queryStringParameters: {},
                pathParameters: {},
                body: JSON.stringify({ fk_idprov: 1 }),
                isBase64Encoded: false,
            });
            const response = JSON.parse(responseLocalidades?.data?.body)
            setLocalidadesFacturacion(response);
            setDatosFacturacion((prevState) => ({
                ...prevState,
                fk_idlocalidadFacturacion: response.length > 0 ? response[0].idlocalidad : 0,
            }));

        }
        fetchLocalidadesFacturacion();
        getDatos();
    }, []);

    const calculatePrecioTotal = () => {
        return carrito.reduce((total, item) => {
            return total + Math.round((item.precio_dolar * precioDolar) * item.cantidad);
        }, 0);
    };
    const mapDatosDomicilio = (datos) => ({
        nombre: datos.nombre,
        apellido: datos.apellido,
        email: datos.email,
        documento_personal: datos.documento_personal,
        direccion: datos.direccion,
        fk_idprov: datos.fk_idprov,
        codpost: datos.codpost,
        fk_idpais: datos.fk_idpais,
        dpto: datos.dpto,
        piso: datos.piso,
        numero_celular: datos.numero_celular,
        calle: datos.calle,
        alias: datos.alias,
        entre_calles: datos.entre_calles,
        alternativa: datos.alternativa,
        descripcion: datos.observaciones,
        retiro: datos.retiro,
        fk_idtipodoc_persona: datos.fk_idtipodoc_persona,
        fk_idlocalidad: datos.fk_idlocalidad,
        razon_social: datos.razon_social,
        situacion: datos.situacion
    });

    const axiosPost = async (url, data) => {
        return await axios.post(url, {
            httpMethod: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
            isBase64Encoded: false,
        });
    };

    const fetchLocalidades = async (fk_idprov) => {
        const response = await axiosPost('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/conseguir-localidades', { fk_idprov });
        const localidadesData = JSON.parse(response?.data?.body);
        setLocalidades(localidadesData);
        setDatosDomicilio((prevState) => ({
            ...prevState,
            fk_idlocalidad: localidadesData.length > 0 ? localidadesData[0].idlocalidad : 0,
        }));
    };

    const fetchProvincias = async () => {
        const response = await fetch('https://api.depcsuite.com/td/getProvincias?id=12', {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        });
        setProvincias(await response.json());
    };

    const handleChange = (e) => {
        if(e.target.name == 'documento_personal'){
            let value = e.target.value;
            value = value.replace(/[.-]/g, '');
            e.target.value = value;
        }
        setDatosDomicilio({ ...datosDomicilio, [e.target.name]: e.target.value });
        if (e.target.name === 'fk_idprov') {
            setLocalidades([]);
            fetchLocalidades(e.target.value);
        }
        setFormErrors({ ...formErrors, [e.target.name]: null });
    };

    const handleChangeFacturacion = (e) => {
        if(e.target.name == 'documento_personalFacturacion'){
            let value = e.target.value;
            value = value.replace(/[.-]/g, '');
            e.target.value = value;
        }
        setDatosFacturacion({
            ...datosFacturacion,
            [e.target.name]: e.target.value
        });
        if (e.target.name === 'fk_idprovFacturacion') {
            setLocalidadesFacturacion([]);
            const fetchLocalidades = async () => {
                const responseLocalidades = await axios.post('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/conseguir-localidades', {
                    httpMethod: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    queryStringParameters: {},
                    pathParameters: {},
                    body: JSON.stringify({ fk_idprov: e.target.value }),
                    isBase64Encoded: false,
                });
                const response = JSON.parse(responseLocalidades?.data?.body)
                setLocalidadesFacturacion(response);
                setDatosFacturacion((prevState) => ({
                    ...prevState,
                    fk_idlocalidad: response.length > 0 ? response[0].idlocalidad : 0,
                }));
            }
            fetchLocalidades();
        }

        setFormErrors({
            ...formErrors,
            [e.target.name]: null,
        });
    };

    const onClickCupon = async () => {
        const response = await axios.post('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/agregar-cupon', {
            httpMethod: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            queryStringParameters: {},
            pathParameters: {},
            body: JSON.stringify({ token, cuponNombre: (cupon || '') }),
            isBase64Encoded: false,
        });
        const data = JSON.parse(response.data.body);
        if (data.message) {
            setCuponTrue(true);
            setCuponProductos(data.productos);
        } else {
            setErrorCupon(true);
        }
    }

    const handleSelectChange = (e) => {
        const selectedId = e.target.value;
        setDomicilioNew(selectedId);
        if (selectedId === "new") {
            setDatosDomicilio(initialDatosDomicilio());
        } else {
            const selectedData = datos.find((dato) => dato.iddomicilio === selectedId);
            if (selectedData) {
                setDatosDomicilio(mapDatosDomicilio(selectedData));
                fetchLocalidades(selectedData.fk_idprov);
            }
        }
    };

    const handleSubmit = async () => {
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            scrollToTop();
            setFormErrors(errors);
            setLoadingStep(false);
            return;
        }

        const mapaLocalidades = {};
        localidades.forEach(localidad => {
            mapaLocalidades[localidad.idlocalidad] = localidad.nombre;
        });

        const mapaProvincias = {};

        provincias.forEach(provincia => {
            mapaProvincias[provincia.idprov] = provincia.descprov;
        });

        localStorage.setItem('domicilio', JSON.stringify({ ...datosDomicilio, domicilioNew }));
        localStorage.setItem('personal', retiro);

        datosDomicilio.alternativa = datosDomicilio.direccion + ', ' + mapaLocalidades[datosDomicilio.fk_idlocalidad] + ', ' + mapaProvincias[datosDomicilio.fk_idprov];
        if (!facturacionDomicilio) {
            await axios.post('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/editar-domicilio', {
                httpMethod: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                queryStringParameters: {},
                pathParameters: {},
                body: JSON.stringify({ ...datosDomicilio, token, tipoDireccion, domicilioNew }),
                isBase64Encoded: false,
            });
        } else {
            await axios.post('https://43a8phnwnh.execute-api.us-east-1.amazonaws.com/prod/agregar-domicilio/facturacion', {
                httpMethod: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                queryStringParameters: {},
                pathParameters: {},
                body: JSON.stringify({ ...datosDomicilio, token, tipoDireccion: 'Casa', iddomicilio: domicilioNew }),
                isBase64Encoded: false,
            });
            await axios.post('https://43a8phnwnh.execute-api.us-east-1.amazonaws.com/prod/agregar-domicilio/facturacion', {
                httpMethod: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                queryStringParameters: {},
                pathParameters: {},
                body: JSON.stringify({
                    direccion: datosFacturacion.direccionFacturacion,
                    fk_idprov: datosFacturacion.fk_idprovFacturacion,
                    codpost: datosFacturacion.codpostFacturacion,
                    fk_idpais: 12,
                    dpto: datosDomicilio.dpto,
                    piso: datosDomicilio.piso,
                    numero_celular: datosDomicilio.numero_celular,
                    calle: '',
                    alias: '',
                    entre_calles: datosDomicilio.entre_calles,
                    alternativa: '',
                    descripcion: '',
                    email: '',
                    retiro: 1,
                    fk_idtipodoc_persona: 1,
                    documento_personal: datosFacturacion.documento_personalFacturacion,
                    nombre: datosFacturacion.razon_socialFacturacion.split(' ')[0],
                    apellido: datosFacturacion.razon_socialFacturacion.split(' ')[1] || '',
                    fk_idlocalidad: datosFacturacion.fk_idlocalidadFacturacion,
                    razon_social: datosFacturacion.razon_socialFacturacion,
                    situacion: datosFacturacion.situacionFacturacion,
                    token,
                    tipoDireccion: 'Facturación',
                    domicilioNew
                }),
                isBase64Encoded: false,
            });
        }
        await handleEnvio();
        setLoadingStep(false);
        scrollToTop();
        setStep(3);
    };

    const validateForm = () => {
        const requiredFields = ['nombre', 'apellido', 'documento_personal', 'direccion', 'fk_idprov', 'codpost', 'numero_celular', 'piso', 'dpto', 'fk_idlocalidad'];
        if (retiro == 'Envía a domicilio') {
            requiredFields.push('descripcion');
            requiredFields.push('entre_calles');
        }
        const errors = {};
        requiredFields.forEach((field) => {
            if (!datosDomicilio[field]) errors[field] = 'Este campo es requerido';
        });

        if (facturacionDomicilio) {
            const requiredFieldsFacturacion = ['razon_socialFacturacion', 'documento_personalFacturacion', 'direccionFacturacion', 'codpostFacturacion'];
            requiredFieldsFacturacion.forEach((field) => {
                if (!datosFacturacion[field]) errors[field] = 'Este campo es requerido';
            });
        }

        return errors;
    };

    const handleEnvio = async () => {
        const response = await axiosPost('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/envio', { codPost: datosDomicilio?.fk_idlocalidad });
        const envioData = JSON.parse(response.data.body);
        setEnvio(retiro === 'Envía a domicilio' && envioData.length > 0 ? envioData[0]?.importe : 0);
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 500, behavior: 'smooth' });
    };

    // const calculatePrecioTotalCupon = () => {
    //     return carrito.reduce((total, item) => {
    //         if (typeof cuponProductos == 'object' && cuponProductos && cuponProductos.filter(p => p.idproducto === item.idproducto)) {
    //             const descuento = cuponProductos?.find(p => p.idproducto === item.idproducto).descuento;
    //             const precioConDescuento = item.precio_dolar * precioDolar - descuento;
    //             return total + (precioConDescuento * item.cantidad).toFixed(0);
    //         }
    //         return total + (item.precio_dolar * precioDolar * item.cantidad).toFixed(0);
    //     }, 0);
    // };

    const precioTotalCupon = cuponProductos
    const precioTotal = carrito && carrito.reduce((previous, current) => {
        const itemPriceUSD = current.precio_dolar;
        const precioConIncremento = itemPriceUSD * precioDolar;
        
        const porcentajePromo = Number(current.porcentaje_promo) || 0;
        const cantidadPromo = Number(current.cantidad_promo) || 0;
        
        let resultado;
        if (porcentajePromo) {
            resultado = precioConIncremento * (1 - porcentajePromo / 100);
        } else if (cantidadPromo) {
            resultado = precioConIncremento - cantidadPromo;
        } else {
            resultado = precioConIncremento;
        }
        
        const precioConDescuentoAdicional = resultado;
      
        const precioFinal = (precioConDescuentoAdicional * current.cantidad).toFixed(0);
    
        return previous + Number(precioFinal);
    }, 0);
    
    const precioCuponRestante = precioTotal - precioTotalCupon;

    const handleChangeLocalidad = (newValue, fieldName) => {
        setDatosDomicilio({
            ...datosDomicilio,
            [fieldName]: newValue
        });
        if (fieldName === 'fk_idprov') {
            setLocalidades([]);
            const fetchLocalidades = async () => {
                const responseLocalidades = await axios.post('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/conseguir-localidades', {
                    httpMethod: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    queryStringParameters: {},
                    pathParameters: {},
                    body: JSON.stringify({ fk_idprov: newValue }),
                    isBase64Encoded: false,
                });
                const response = JSON.parse(responseLocalidades?.data?.body)
                setLocalidades(response);
                datosDomicilio((prevState) => ({
                    ...prevState,
                    fk_idlocalidad: response.length > 0 ? response[0].idlocalidad : 0,
                }));

            }
            fetchLocalidades();
        }

        setFormErrors({
            ...formErrors,
            [fieldName]: null,
        });
    };
    const handleChangeLocalidadFacturacion = (newValue, fieldName) => {
        setDatosFacturacion({
            ...datosFacturacion,
            [fieldName]: newValue
        });
        if (fieldName === 'fk_idprov') {
            setLocalidadesFacturacion([]);
            const fetchLocalidadesFacturacion = async () => {
                const responseLocalidades = await axios.post('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/conseguir-localidades', {
                    httpMethod: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    queryStringParameters: {},
                    pathParameters: {},
                    body: JSON.stringify({ fk_idprov: newValue }),
                    isBase64Encoded: false,
                });
                const response = JSON.parse(responseLocalidades?.data?.body)
                setLocalidadesFacturacion(response);
                setDatosFacturacion((prevState) => ({
                    ...prevState,
                    fk_idlocalidadFacturacion: response.length > 0 ? response[0].idlocalidad : 0,
                }));

            }
            fetchLocalidadesFacturacion();
        }

        setFormErrors({
            ...formErrors,
            [fieldName]: null,
        });
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            fontSize: '14px',
        }),
    };

    const getLocalidadOption = (idlocalidad) => {
        const localidad = localidades.find(loc => loc.idlocalidad === idlocalidad);
        return localidad ? { value: localidad.idlocalidad, label: localidad.nombre } : null;
    };
    const getLocalidadOptionFacturacion = (idlocalidad) => {
        const localidad = localidadesFacturacion.find(loc => loc.idlocalidad === idlocalidad);
        return localidad ? { value: localidad.idlocalidad, label: localidad.nombre } : null;
    };

    const MySelect = ({ value, onChange }) => (
        <div className='w-full border-black border-opacity-60 py-1'>
            <Select
                options={localidades.map(localidad => ({ value: localidad.idlocalidad, label: localidad.nombre }))}
                value={getLocalidadOption(value)}
                onChange={(selectedOption) => onChange(selectedOption.value)}
                placesholder="Localidad*"
                isSearchable
                styles={customStyles}
                classNamePrefix="react-select"
            />
        </div>
    );
    const MySelectFacturación = ({ value, onChange }) => (
        <div className='w-full border-black border-opacity-60 py-1'>
            <Select
                options={localidadesFacturacion.map(localidad => ({ value: localidad.idlocalidad, label: localidad.nombre }))}
                value={getLocalidadOptionFacturacion(value)}
                onChange={(selectedOption) => onChange(selectedOption.value)}
                placesholder="Localidad*"
                isSearchable
                styles={customStyles}
                classNamePrefix="react-select"
            />
        </div>
    );

    return (
        <div>
            <div className='lg:hidden mx-3'>
                <div className='flex bg-[#f9f9fb] ml-1 pt-3 pb-3 mt-5 px-4 flex-row justify-between'>
                    <div className='flex flex-row'>
                        <img src={cart} className='w-4' alt="cart" />
                        <p className='text-left text-sm mt-1 font-semibold ml-1 text-[#E40F1B]'>
                            Mi carrito: {Number(precioTotal).toLocaleString('es-AR', { style: 'currency', currency: 'ARS', maximumFractionDigits: 0 })}
                        </p>
                    </div>
                    <div>
                        {isOpen ? (
                            <button onClick={() => setIsOpen(false)}>
                                <img src={minus} alt='cerrar' />
                            </button>
                        ) : (
                            <button onClick={() => setIsOpen(true)}>
                                <img src={plus} alt='abrir' />
                            </button>
                        )}
                    </div>
                </div>
                {isOpen && (
                    <div className='bg-[#f9f9fb] h-auto ml-1 px-4 pb-4 pt-2'>
                        <div className='space-y-2'>
                            <div>
                                {carrito && loading ? (
                                    carrito.map((item, index) => (
                                        <div key={index}>
                                            {Number(item.precio_dolar) !== 0 && (
                                                <div>
                                                    <CardDatos dolar={precioDolar} item={item} setLoading={setLoading} />
                                                    <div className='border-b-2 my-2 border-opacity-30'></div>
                                                </div>
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    <div className="flex justify-center items-center mt-32 mb-60">
                                        <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#E40F1B]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='flex flex-row items-center my-4'>
                            <input className='border pl-1 py-2 rounded-sm hover:border-[#E40F1B] bg-[#f8f0f0] font-bold flex-grow mr-3' placesholder='Tengo un cupón de descuento' type="text" />
                            <button className='border hover:bg-gray-500 hover:text-white border-gray-500 w-1/4 rounded-sm'>APLICAR</button>
                        </div>
                        <div>
                            <div className='flex justify-between font-semibold my-2'>
                                <p>TOTAL</p>
                                <p>{Number(cuponTrue ? precioTotal - precioCuponRestante : precioTotal).toLocaleString('es-AR', { style: 'currency', currency: 'ARS', maximumFractionDigits: 0 })}</p>
                            </div>
                            <div className='border-b-2 my-2'></div>
                            <p className='text-right text-sm opacity-70'>Precio en pesos argentinos. Incluye impuestos.</p>
                        </div>
                    </div>
                )}
            </div>

            {!token && step === 2 && <p className='lg:ml-10 mt-5 text-left font-semibold text-lg'>Contacto</p>}
            {step === 3 && <p className='lg:ml-10 mt-5 text-left font-semibold text-lg'>Datos del pedido</p>}

            <div className='mt-5 lg:mx-10 mx-3 lg:ml-12 ml-5 lg:grid lg:grid-cols-2 flex flex-col mb-20'>
                <div className='flex flex-col lg:mr-10'>
                    <div className='mt-10 text-left'>
                        <p className='font-semibold text-lg mb-5'>Forma de entrega</p>
                        <div className='grid grid-cols-2'>
                            <button onClick={() => setRetiro('Envía a domicilio')} className={`${retiro === 'Envía a domicilio' ? 'bg-gray-200' : ''} border border-black border-opacity-60 mr-2 rounded-sm text-sm h-16 items-center flex flex-row`}>
                                <input type="radio" id='Envía a domicilio' checked={retiro === 'Envía a domicilio'} onChange={() => setRetiro('Envía a domicilio')} className='ml-2' />
                                <img src={truck} className='w-5 ml-2' alt="Envía a domicilio" />
                                <p className='lg:ml-2 ml-0.5'>Envía a domicilio</p>
                            </button>
                            <button onClick={() => setRetiro('Retiro al local')} className={`${retiro === 'Retiro al local' ? 'bg-gray-200' : ''} border border-black border-opacity-60 lg:mr-2 rounded-sm text-sm h-16 items-center flex flex-row`}>
                                <input type="radio" className='ml-2' id='Retiro al local' checked={retiro === 'Retiro al local'} onChange={() => setRetiro('Retiro al local')} />
                                <img src={calendar} className='w-5 ml-2' alt="Retiro al local" />
                                <p className='ml-2'>Retiro en el local</p>
                            </button>
                        </div>
                    </div>
                    {retiro === 'Retiro al local' && (
                        <div className='text-left flex flex-col'>
                            <p className='text-lg my-3 font-semibold'>Lugar de Retiro</p>
                            <div className='border border-[#E40F1B] bg-[#ffb3b3] font-bold text-left'>
                                <div className='m-3'>
                                    <p className='font-semibold'>Depósito - GRATIS</p>
                                    <span className='text-left'>
                                        Lunes a Viernes (hábiles) de 9:30 a 13:30 hs. - Requiere coordinación previa - WA <a className='border-b-[0.5px]' href="https://api.whatsapp.com/send?phone=541132619277">11-3261-9277</a> CABA, Barracas, California 1628 - Portón gris, sin timbre.
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className='text-left flex flex-col'>
                        <p className='font-semibold text-lg mt-10'>Datos personales</p>

                        <div className='flex flex-col'>
                            <div className='flex flex-col'>
                                <div>
                                    <div className='flex justify-between my-5'>
                                        <div className='w-1/2'>
                                            <label className='text-sm'>Nombre*</label>
                                            <input type="text" className={`border ${formErrors.nombre ? 'border-[#E40F1B] bg-[#f8f0f0] font-bold' : 'border-black'} border-opacity-60 py-1 w-full pl-1 rounded-sm `} required value={datosDomicilio?.nombre} name='nombre' onChange={handleChange} placesholder='Nombre*' />
                                            {formErrors.nombre && <p className='text-xs font-semibold text-[#E40F1B]'>Campo obligatorio</p>}
                                        </div>
                                        <div className='w-1/2 ml-3'>
                                            <label className='text-sm'>Apellido*</label>
                                            <input className={`border ${formErrors.apellido ? 'border-[#E40F1B] bg-[#f8f0f0] font-bold' : 'border-black'} border-opacity-60 w-full py-1 pl-1 rounded-sm`} type="text" required value={datosDomicilio?.apellido} onChange={handleChange} name='apellido' placesholder='Apellido*' />
                                            {formErrors.apellido && <p className='text-xs font-semibold text-[#E40F1B]'>Campo obligatorio</p>}
                                        </div>
                                    </div>
                                    <div className='flex flex-row justify-between my-5'>
                                        <div className='w-1/2 flex flex-col'>
                                            <label className='text-sm'>Tipo de documento*</label>
                                            <select type="text" name='fk_idtipodoc_persona' disabled value={datosDomicilio?.fk_idtipodoc_persona} onChange={handleChange} className='border border-black border-opacity-60 h-9 rounded-sm' placesholder='Documento*'>
                                                <option value="7">DNI</option>
                                            </select>
                                        </div>
                                        <div className='w-1/2 ml-3'>
                                            <label className='text-sm'>Número de documento*</label>
                                            <input className={`border ${formErrors.documento_personal ? 'border-[#E40F1B] bg-[#f8f0f0] font-bold' : 'border-black'} w-full border-opacity-60 py-1 pl-1 rounded-sm`} name='documento_personal' value={datosDomicilio?.documento_personal} onChange={handleChange} type="text" placesholder='Número de documento*' />
                                            {formErrors.documento_personal && <p className='text-xs font-semibold text-[#E40F1B]'>Campo obligatorio</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {retiro == 'Envía a domicilio' && <p className='font-semibold text-lg mt-3'>Dirección de envío</p>}
                            <select name="id" className='border-opacity-60 w-full py-3 pl-1 mt-3 border rounded-sm border-black' value={domicilioNew} onChange={(e) => handleSelectChange(e)}>
                                {datos && datos.map((dato, index) => {
                                    if (!dato.alternativa) return;
                                    return <option key={index} value={dato.iddomicilio}>{dato.alternativa}</option>
                                })}
                                <option value="new">Nueva dirección</option>
                            </select>
                            <div className='flex justify-between mt-3'>
                                <div className='flex flex-col w-1/2'>
                                    <label className='text-sm'>Provincia*</label>
                                    <select name='fk_idprov' onChange={handleChange} required value={datosDomicilio?.fk_idprov} type="text" className='border mt-1.5 h-9 border-black border-opacity-60 py-1 rounded-sm' placesholder='Provincia*'>
                                        <option disabled selected>Provincia</option>
                                        {provincias.map((provincia) => (
                                            <option key={provincia.idprov} value={provincia.idprov}>{provincia.descprov}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='w-1/2 ml-3'>
                                    <label className='text-sm'>Localidad*</label>
                                    <MySelect value={datosDomicilio?.fk_idlocalidad} onChange={(newValue) => handleChangeLocalidad(newValue, 'fk_idlocalidad')} />
                                </div>
                            </div>
                            <div className='flex justify-between mt-3'>
                                <div className='w-1/2'>
                                    <label className='text-sm'>Dirección*</label>
                                    <input className={`border ${formErrors.direccion ? 'border-[#E40F1B] bg-[#f8f0f0] font-bold' : 'border-black'} border-opacity-60 pl-1 w-full py-1 rounded-sm`} type="text" name='direccion' value={datosDomicilio?.direccion} onChange={handleChange} placesholder='Dirección*' />
                                    {formErrors.direccion && <p className='text-xs font-semibold text-[#E40F1B]'>Campo obligatorio</p>}
                                </div>
                                <div className='w-1/2 ml-3'>
                                    <label className='text-sm'>Piso*</label>
                                    <input type="text" className={`border ${formErrors.piso ? 'border-[#E40F1B] bg-[#f8f0f0] font-bold' : 'border-black'} border-opacity-60 w-full rounded-sm py-1 pl-1`} name='piso' value={datosDomicilio?.piso} onChange={handleChange} placesholder='Piso' />
                                    {formErrors.piso && <p className='text-xs font-semibold text-[#E40F1B]'>Campo obligatorio</p>}
                                </div>

                            </div>
                            <div className='flex justify-between mt-3'>
                                <div className='w-1/2'>
                                    <label className='text-sm'>Departamento*</label>
                                    <input type="text" className={`border ${formErrors.dpto ? 'border-[#E40F1B] bg-[#f8f0f0] font-bold' : 'border-black'} border-opacity-60 w-full rounded-sm py-1 pl-1`} placesholder='Departamento' value={datosDomicilio?.dpto} name='dpto' onChange={handleChange} />
                                    {formErrors.dpto && <p className='text-xs font-semibold text-[#E40F1B]'>Campo obligatorio</p>}
                                </div>
                                <div className='w-1/2 ml-3'>
                                    <label className='text-sm'>Código postal*</label>
                                    <input className={`border ${formErrors.codpost ? 'border-[#E40F1B] bg-[#f8f0f0] font-bold' : 'border-black'} border-opacity-60 pl-1 w-full py-1 rounded-sm`} type="text" name='codpost' value={datosDomicilio?.codpost} onChange={handleChange} placesholder='Código Postal*' />
                                    {formErrors.codpost && <p className='text-xs font-semibold text-[#E40F1B]'>Campo obligatorio</p>}
                                </div>
                            </div>
                            {retiro == 'Envía a domicilio' && <div className='mt-3'>
                                <label className='text-sm'>Entre calles*</label>
                                <input type="text" className={` border ${formErrors.entre_calles ? 'border-[#E40F1B] bg-[#f8f0f0] font-bold' : 'border-black'} border-opacity-60 w-full rounded-sm py-1 pl-1`} required name='entre_calles' value={datosDomicilio?.entre_calles} onChange={handleChange} placesholder='Entre calles*' />
                                {formErrors.entre_calles && <p className='text-xs font-semibold text-[#E40F1B]'>Campo obligatorio</p>}
                            </div>}
                            <div className='my-3'>
                                <label className='text-sm'>Número de celular*</label>
                                <input type="text" className={`border ${formErrors.numero_celular ? 'border-[#E40F1B] bg-[#f8f0f0] font-bold' : 'border-black'} border-opacity-60 w-full rounded-sm py-1 pl-1`} required name='numero_celular' onChange={handleChange} value={datosDomicilio?.numero_celular} placesholder='Teléfono de contacto*' />
                                {formErrors.numero_celular && <p className='text-xs font-semibold text-[#E40F1B]'>Campo obligatorio</p>}
                            </div>
                            {retiro == 'Envía a domicilio' && <div className='mt-3'>
                                <label className='text-sm'>Nota para la entrega:*</label>
                                <input type="text" className={`${formErrors.descripcion ? 'border-[#E40F1B] bg-[#f8f0f0] font-bold' : 'border-black'} border border-opacity-60 w-full rounded-sm h-52 pb-44 pl-1 py-1`} name='descripcion' value={datosDomicilio?.descripcion} onChange={handleChange} placesholder='Notas para la entrega*:' />
                                {formErrors.descripcion && <p className='text-xs font-semibold text-[#E40F1B] mb-2'>Campo obligatorio</p>}
                            </div>}
                            <div className='flex flex-row'>
                                <input type="checkbox" checked={facturacionDomicilio} id='facturaA' name='facturaA' onChange={() => setFacturacionDomicilio(!facturacionDomicilio)} className={``} />
                                <label className='ml-1' for='facturaA'>¿Desea factura A?</label>
                            </div>
                            {facturacionDomicilio &&
                                <div>
                                    <p className='font-semibold text-lg mt-5'>Datos de facturación</p>
                                    <div className='justify-between my-3 flex flex-row'>
                                        <div className='flex flex-col w-1/2'>
                                            <label className='text-sm'>Situación impositiva*</label>
                                            <select type="text" name='situacion' value={datosFacturacion?.situacionFacturacion} onChange={handleChangeFacturacion} className='border border-black border-opacity-60 h-9 rounded-sm' placesholder='Situación impositiva*'>
                                                <option value="1">IVA Responsable Inscripto</option>
                                                <option value="2">Responsable Monotributo</option>
                                                <option value="4">IVA Sujeto Exento</option>
                                            </select>
                                        </div>
                                        <div className='flex flex-col w-1/2 ml-3'>
                                            <label className='text-sm'>Nombre / Razón social*</label>
                                            <input className={`border ${formErrors.razon_socialFacturacion ? 'border-[#E40F1B] bg-[#f8f0f0] font-bold' : 'border-black'} border-black w-full border-opacity-60 py-1 pl-1 rounded-sm`} name='razon_socialFacturacion' value={datosFacturacion?.razon_socialFacturacion} onChange={handleChangeFacturacion} type="text" placesholder='Nombre / Razón social*' />
                                            {formErrors.razon_socialFacturacion && <p className='text-xs font-semibold text-[#E40F1B]'>Campo obligatorio</p>}
                                        </div>
                                    </div>
                                    <div className='flex'>
                                        <div className='w-1/2 flex flex-col'>
                                            <label className='text-sm'>Documento*</label>
                                            <select type="text" name='fk_idtipodoc_persona' disabled value={datosFacturacion?.fk_idtipodoc_personaFacturacion} onChange={handleChangeFacturacion} className={`border ${formErrors.documento_personal ? 'border-[#E40F1B] bg-[#f8f0f0] font-bold' : 'border-black'} border-black border-opacity-60 h-9 rounded-sm`} placesholder='Documento*'>
                                                <option value="1">CUIT</option>
                                            </select>
                                        </div>
                                        <div className='w-1/2 ml-3'>
                                            <label className='text-sm'>Número de documento*</label>
                                            <input className={`border ${formErrors.documento_personalFacturacion ? 'border-[#E40F1B] bg-[#f8f0f0] font-bold' : 'border-black'} w-full border-opacity-60 py-1 pl-1 rounded-sm`} name='documento_personalFacturacion' value={datosFacturacion?.documento_personalFacturacion} onChange={handleChangeFacturacion} type="text" placesholder='Número de documento*' />
                                            {formErrors.documento_personalFacturacion && <p className='text-xs font-semibold text-[#E40F1B]'>Campo obligatorio</p>}
                                        </div>
                                    </div>
                                    <div className='flex justify-between mt-3'>
                                        <div className='flex flex-col w-1/2 mt-1'>
                                            <label className='text-sm'>Provincia*</label>
                                            <select name='fk_idprovFacturacion' onChange={handleChangeFacturacion} required value={datosFacturacion?.fk_idprovFacturacion} type="text" className='border mt-1.5 h-9 border-black border-opacity-60 py-1 rounded-sm' placesholder='Provincia*'>
                                                <option disabled selected={true}>Provincia</option>
                                                {provincias.map((provincia) => (
                                                    <option key={provincia.idprov} value={provincia.idprov}>{provincia.descprov}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='w-1/2 ml-3'>
                                            <label className='text-sm'>Localidad*</label>
                                            <MySelectFacturación value={datosFacturacion?.fk_idlocalidadFacturacion} onChange={(newValue) => handleChangeLocalidadFacturacion(newValue, 'fk_idlocalidadFacturacion')} />
                                        </div>
                                    </div>
                                    <div className='justify-between mt-3 flex flex-row'>
                                        <div className='flex flex-col w-1/2'>
                                            <label className='text-sm'>Dirección*</label>
                                            <input className={`border ${formErrors.direccionFacturacion ? 'border-[#E40F1B] bg-[#f8f0f0] font-bold' : 'border-black'} border-opacity-60 pl-1 w-full py-1 rounded-sm`} type="text" name='direccionFacturacion' value={datosFacturacion?.direccionFacturacion} onChange={handleChangeFacturacion} placesholder='Dirección*' />
                                            {formErrors.direccionFacturacion && <p className='text-xs font-semibold text-[#E40F1B]'>Campo obligatorio</p>}
                                        </div>
                                        <div className='flex flex-col w-1/2 ml-3'>
                                            <label className='text-sm'>Código postal*</label>
                                            <input className={`border ${formErrors.codpostFacturacion ? 'border-[#E40F1B] bg-[#f8f0f0] font-bold' : 'border-black'} border-opacity-60 pl-1 w-full py-1 rounded-sm`} type="text" name='codpostFacturacion' value={datosFacturacion?.codpostFacturacion} onChange={handleChangeFacturacion} placesholder='Código Postal*' />
                                            {formErrors.codpostFacturacion && <p className='text-xs font-semibold text-[#E40F1B]'>Campo obligatorio</p>}
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className='flex justify-between mt-16'>
                                <button onClick={() => {
                                    setStep((step - 1));
                                    scrollToTop();
                                }} className='opacity-70'>Cancelar</button>
                                <button onClick={() => {
                                    setLoadingStep(true);
                                    handleSubmit();

                                }} className='bg-[#1c1c1c] text-white flex justify-center items-center px-16 h-10 py-1 uppercase hover:bg-gray-500'>
                                    {loadingStep ? <svg aria-hidden="true" className="w-4 h-4 mx-full text-gray-200 animate-spin dark:text-gray-600 fill-[#E40F1B]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg> : 'Siguiente paso'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bg-[#f9f9fb] col-span-1 lg:block hidden mt-10 h-1/2 ml-1'>
                    <div className='mx-4'>
                        <p className='uppercase text-left text-lg font-semibold mt-2 '>Mi compra</p>
                        <div>
                            {carrito && loading ? (
                                carrito.map((item, index) => (
                                    <div key={index}>
                                        {Number(item.precio_dolar) !== 0 && (
                                            <div>
                                                <CardDatos dolar={precioDolar} item={item} setLoading={setLoading} />
                                                <div className='border-b-2 my-2 border-opacity-30'></div>
                                            </div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <div className="flex justify-center items-center mt-32 mb-60">
                                    <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#E40F1B]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg>
                                    <span className="sr-only">Loading...</span>
                                </div>
                            )}
                        </div>
                        <div className='flex flex-col'>
                            <div className='flex flex-row'>
                                <input onChange={(e) => setCupon(e.target.value)} className='border pl-1 py-2 rounded-sm hover:border-[#E40F1B] text-white mr-3 w-2/3' placesholder='Tengo un cupón de descuento' type="text" />
                                <button onClick={() => onClickCupon()} className='border hover:bg-gray-500 hover:text-white border-gray-500 w-1/3 rounded-sm' >APLICAR</button>
                            </div>
                            {errorCupon && <p className='my-2 text-sm text-[#E40F1B] text-left'>El cupon no existe o ya ha sido utilizado</p>}
                        </div>
                        <div>
                            {cuponTrue &&
                                <div>
                                    <div className='my-2'></div>
                                    <div className='flex justify-between'>
                                        <p className='text-left'>Cupon: 15% OFF</p>
                                        <p className='text-right'>-{Number(precioCuponRestante).toLocaleString('es-AR', {
                                            style: 'currency',
                                            currency: 'ARS',
                                            maximumFractionDigits: 0,
                                        })}</p>
                                    </div>
                                </div>
                            }
                            <div className='border-b-2 my-2'></div>
                            <div className='flex justify-between'>
                                <p className='text-left'>TOTAL</p>
                                <p className='text-right'>{Number(cuponTrue ? precioTotal - precioCuponRestante : precioTotal).toLocaleString('es-AR', {
                                    style: 'currency',
                                    currency: 'ARS',
                                    maximumFractionDigits: 0,
                                })}</p>
                            </div>
                            <div className='border-b-2 my-2'></div>
                            <p className='text-right text-sm opacity-70'>Precio en pesos argentinos. Incluye impuestos.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Datos;