import React, { useEffect, useState } from 'react';
import Header from '../../Components/Header/Header';
import HeaderLower from '../../Components/HeaderLower/HeaderLower';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';

const Nosotros = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get('q');
    const [formErrors, setFormErrors] = useState({});
    const [formSuccess, setFormSuccess] = useState(false);

    useEffect(() => {
        if (query === 'arrepentimiento') {
            window.scrollTo(0, 860)
        }
    }, [])

    const [form, setForm] = useState({
        nombre: undefined,
        mail: undefined,
        provincia: 'Buenos Aires',
        telefono: undefined,
        consulta: undefined,
        motivo: query === 'arrepentimiento' ? 'Me arrepiento de mi compra' : 'Atención al cliente'
    });

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });

        setFormErrors({
            ...formErrors,
            [e.target.name]: null,
        });
    };

    const cuerpoCorreo = `<body>
    <p>Hola, se contactó la siguiente persona:</p>
    <ul>
        <li><strong>Nombre:</strong> <span id="nombre">${form.nombre}</span></li>
        <li><strong>Teléfono:</strong> <span id="telefono">${form.telefono}</span></li>
        <li><strong>Correo:</strong> <span id="correo">${form.mail}</span></li>
        <li><strong>Provincia:</strong> <span id="provincia">${form.provincia}</span></li>
        <li><strong>Motivo:</strong> <span id="motivo">${form.motivo}</span></li>
    </ul>
    <p><strong>Mensaje:</strong></p>
    <p id="mensaje">${form.consulta}</p>
    <p>Saludos</p></body>`

    const asunto = 'Nuevo mensaje Web SCE'

    const params = {
        asunto: asunto,
        correo: 'hola@sentate-conestilo.com',
        CuerpoCorreo: cuerpoCorreo
    };

    const handleClick = async (e) => {
        e.preventDefault();
        const requiredFields = ['nombre', 'telefono', 'mail', 'provincia', 'motivo', 'consulta'];
        const errors = {};

        requiredFields.forEach((fieldName) => {
            if (form[fieldName] === "" || form[fieldName] === undefined || form[fieldName] === null) {
                errors[fieldName] = 'Este campo es requerido';
            }
        });

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            console.log(formErrors)
            return;
        }

        const response = await axios.get("https://sistema.depcsuite.com/certificanna/enviarCorreo", {
            params: params,
            headers: {
                "Content-Type": "application/json"
            }
        });
        console.log(response)
        if (response.data["ESTADO"] == 'success') {
            setFormSuccess(true);
        }
    }

    return (
        <div>
            <Header />
            <HeaderLower />
            <div className="pt-20">
                <div className='bg-[#EFEFED] w-full pt-10'>
                    <p className='text-[12px] text-left ml-16 font-semibold'><a className='font-normal' href="/">Home {'>'}</a> Nosotros</p>
                    <p className='text-6xl my-10 pb-20'>
                        Nosotros
                    </p>
                </div>
            </div>
            <div className='flex justify-center mt-16 text-center'>
                <div className='w-1/2 text-left'>
                    <p className='mb-3'>
                        Somos una empresa del rubro de venta de mobiliario importado y de fabricación nacional, con productos de gran impacto visual a través del diseño con altos estándares de calidad en materia prima y mano de obra calificada abasteciendo a todo el país argentino.
                    </p>
                    <p className='my-3'>
                        Nos especializamos en la incorporación de productos modernos y sofisticados replicando la mejor versión de los diseñadores más prestigiosos del mundo que versionan los modelos antiguos originales con elementos nobles tales como: policarbonato, cristal, acero, mármol, pana.
                    </p>
                    <p className='my-3'>
                        En SCE ponemos a disposición mobiliarios que pueden adquirirse para un hogar, oficina, local comercial, eventos o donde la imaginación los lleve.
                    </p>
                    <p className='my-3'>
                        Queremos que nuestros clientes pertenezcan a una comunidad selecta que destaca la calidad y la estética de un buen diseño dándole valor, y tenemos la responsabilidad de cumplir con esa promesa en todo el proceso de producción, tanto en materia prima, productores y servicio de atención al cliente amable, cálido y personalizado.
                    </p>
                    <p className='mt-3'>
                        Con nuestros muebles hacemos de tus espacios algo tan innovador que inspira y enamora a quienes lo ven.
                    </p>
                    <div className='mt-32'>
                        <p className='text-[30px]'>¿Tenés consultas o sugerencias?</p>
                        <p className='text-sm mt-4'>Completá el formulario y te contestaremos a la brevedad.</p>
                    </div>
                    <div className='mt-5'>
                        <select
                            name="motivo"
                            id=""
                            onChange={(e) => handleChange(e)}
                            className='border pl-4 pr-44 py-3 rounded-md'
                            required
                        >
                            <option disabled>Motivo de la consulta</option>
                            {query !== 'arrepentimiento' &&
                                <option value="Atención al cliente">Atención al cliente</option>
                            }
                            <option defaultChecked={query === 'arrepentimiento'} value="Me arrepiento de mi compra">Me arrepiento de mi compra</option>
                        </select>
                        <div>
                            <input
                                type="text"
                                className='w-full border pl-4 py-3 mt-5 rounded-md'
                                placeholder='Nombre*'
                                required
                                onChange={(e) => handleChange(e)}
                                name='nombre'
                            />
                            {formErrors.nombre && <p className='text-[#E40F1B] text-xs font-semibold'>{formErrors.consulta}</p>}
                        </div>
                        <div>
                            <input
                                type="text"
                                className='w-full border pl-4 py-3 mt-5 rounded-md'
                                placeholder='Email*'
                                required
                                name='mail'
                                onChange={(e) => handleChange(e)}
                            />
                            {formErrors.mail && <p className='text-[#E40F1B] text-xs font-semibold'>{formErrors.consulta}</p>}
                        </div>
                        <div className='flex flex-row'>
                            <select onChange={(e) => handleChange(e)} className='border h-12 rounded-md mt-5 w-1/2 mr-3' name="provincia">
                                <option value="Buenos Aires">Buenos Aires</option>
                                <option value="Catamarca">Catamarca</option>
                                <option value="Chaco">Chaco</option>
                                <option value="Chubut">Chubut</option>
                                <option value="Córdoba">Córdoba</option>
                                <option value="Corrientes">Corrientes</option>
                                <option value="Entre Ríos">Entre Ríos</option>
                                <option value="Formosa">Formosa</option>
                                <option value="Jujuy">Jujuy</option>
                                <option value="La Pampa">La Pampa</option>
                                <option value="La Rioja">La Rioja</option>
                                <option value="Mendoza">Mendoza</option>
                                <option value="Misiones">Misiones</option>
                                <option value="Neuquén">Neuquén</option>
                                <option value="Río Negro">Río Negro</option>
                                <option value="Salta">Salta</option>
                                <option value="San Juan">San Juan</option>
                                <option value="San Luis">San Luis</option>
                                <option value="Santa Cruz">Santa Cruz</option>
                                <option value="Santa Fe">Santa Fe</option>
                                <option value="Santiago del Estero">Santiago del Estero</option>
                                <option value="Tierra del Fuego">Tierra del Fuego</option>
                                <option value="Tucumán">Tucumán</option>
                            </select>
                            <div className='w-1/2'>
                                <input
                                    type="text"
                                    className='border pl-4 py-3 w-full mt-5 rounded-md'
                                    placeholder='Teléfono*'
                                    required
                                    onChange={(e) => handleChange(e)}
                                    name='telefono'
                                />
                                {formErrors.telefono && <p className='text-[#E40F1B] text-xs font-semibold'>{formErrors.consulta}</p>}
                            </div>
                        </div>
                        <input
                            type="text"
                            className='w-full border pl-4 pb-40 pt-2 mt-5 rounded-md'
                            placeholder='Dejanos tu consulta*'
                            required
                            onChange={(e) => handleChange(e)}
                            name='consulta'
                        />
                        {formErrors.consulta && <p className='text-[#E40F1B] text-xs font-semibold'>{formErrors.consulta}</p>}
                        {formSuccess && <p className='text-green-700 text-xs font-semibold'>¡Se ha enviado con exito!</p>}
                        <div className='flex flex-row justify-between mt-5 mb-40 h-12'>
                            <p className='text-[11px]'>Todos los campos son obligatorios.</p>
                            <button
                                className='border bg-black text-white rounded-md w-[46.666667%]'
                                type="text"
                                onClick={(e) => handleClick(e)}
                            >Enviar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Nosotros;