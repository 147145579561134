import Category from '../../Components/Category/Category';
import CategoryCard from '../../Components/CategoryCard/CategoryCard';
import MainCard from '../../Components/MainCard/MainCard';
import FeaturedProduct from '../../Components/FeaturedProduct/FeaturedProduct';
import Header from '../../Components/Header/Header';
import HeaderLower from '../../Components/HeaderLower/HeaderLower';
import shop from '../../assets/SHOP-1920x793.jpg';
import shopMobile from '../../assets/SHOP-mobile@2x.png';

export default function Shop() {
    const opcionesSillas = ['Prolipopileno', 'Policarbonato', 'Transparentes', 'Madera', 'Metálicas', 'Tapizadas', 'Combinadas', 'Banquetas', 'de Escritorio'];
    const opcionesMesas = ['de Comedor', 'Ratonas', 'Auxiliar', 'Arrime', 'de Luz', 'Escritorios', 'Organizador'];
    const opcionesPuffs = ['Puffs', 'Sillones'];
    const opcionesExterior = ['Sillas', 'Mesas', 'Sillones'];
    const opcionesDeco = ['Almohadones', 'Lamparas', 'Objetos'];
    const opcionesOtros = ['Kids', 'Pets'];

    const opciones = [
        {
            title: 'Sillas',
            opciones: opcionesSillas
        },
        {
            title: 'Mesas',
            opciones: opcionesMesas
        },
        {
            title: 'Puffs',
            opciones: opcionesPuffs
        },
        {
            title: 'Exterior',
            opciones: opcionesExterior
        },
        {
            title: 'Deco-Iluminaciones',
            opciones: opcionesDeco
        },
        {
            title: 'Otros',
            opciones: opcionesOtros
        }
    ];

    return (
        <div>
            <Header />
            <HeaderLower />
            <Category title={`Shop`} img={shop} />
            <CategoryCard title={`Todos los productos`} />
            <MainCard opciones={opciones} category={'shop'} />
            <FeaturedProduct />
        </div>
    )
}