import React from 'react';

const CardComprasHistorial = ({ item, dolar }) => {
    const cant = item.cantidad;
    console.log(item)
    const itemPriceUSD = item.precio_dolar;
    const precioConIncremento = itemPriceUSD * dolar;
    const descuento = precioConIncremento * 0.20;

    const precioFinal = ((precioConIncremento - descuento) * item.cantidad).toFixed(0);
    return (
        <div className="lg:my-1 my-3 border-b-[0.5px]">
            <div className='flex flex-row items-center pb-2 text-sm'>
                <div>
                    <img
                        src={`https://sistema.depcsuite.com/productos/${item.imagen}`}
                        alt={item.nombre}
                        className='w-14 border'
                    />
                </div>
                <div className='flex'>
                    <p className='mx-7'>{cant}</p>
                </div>
                <div className='flex flex-row'>
                    <h5 className="w-56 text-[14px] text-left">{item.nombre}</h5>
                </div>
                <div className='flex flex-row ml-auto pr-10'>
                    <p className='lg:mr-2'>Precio: </p>
                    <span className="font-semibold">{Number(precioFinal).toLocaleString('es-AR', {
                        style: 'currency',
                        currency: 'ARS',
                        maximumFractionDigits: 0,
                    })}.-</span>
                </div>
            </div>
        </div>
    );
};

export default CardComprasHistorial;