import React from 'react';

const Entrega = () => {
    return (
        <div className='text-sm text-left lg:w-3/5 lg:px-0 px-5'>
            <p className='text-lg font-semibold mt-10 '>Entregas a Domicilio</p>
            <p className='font-semibold mb-5 mt-4'>¿Cómo puedo saber dónde está mi compra?</p>
            <p className=''>
                Para saber el estado de tu compra podés ingresar a tu cuenta
                o accediendo al link de seguimiento que te enviamos a tu casilla
                de correo electrónico. En caso de no haber recibido esta información
                podés contactarnos por mail a hola@sentate-conestilo.com o a través
                de WhatsApp al +54 9 11 3261 9277 de lunes a viernes de 9 a 18 hs.
                Recordá tener a mano el número de DNI del/la comprador/a o
                el número de tu pedido.
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Cómo realizo el seguimiento?
            </p>
            <p>
                Lo verás reflejado en el mail que te indica:
                hola@entate-conestilo.com, el cual recibirás en los próximos
                minutos luego de haber realizado tu compra. En caso de no
                haberlo recibido podes obtenerlo contactándonos a través
                de WhatsApp al +54 9 11 3261 9277 de lunes a viernes de 9 a 18 hs.
                Recordá tener a mano el número de DNI del/la comprador/a o el
                número de tu pedido.
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Cuál es el costo de envío?
            </p>
            <p>
                Si elegís envío a domicilio en CABA con una compra superior al monto que
                informa la Tienda Web el envío es gratis dentro de CABA, por compras inferiores
                el costo del envío podes verlo en el check out con tu código postal (en ambos
                casos no incluye subida y entrega e departamento, tampoco por escaleras, ni
                exteriores) previa coordinación con el sector de envíos de acuerdo con los
                tiempos de entrega estipulados para este producto se podrá solicitar peón de
                manera adicional. Si elegís envío a domicilio en GBA o al Interior del país: con
                una compra superior al monto que informa la Tienda Web el envío es gratis
                hasta la dirección del servicio de transporte de tu preferencia dentro de CABA,
                para entregas en transportes o expresos en GBA el costo de entrega lo podrás
                ver con el CP de la empresa transportista al momento del check out; El costo
                del envío desde el servicio del transporte hasta el domicilio es a cargo del
                cliente. (Recordá informarle al equipo de logística el nombre y dirección del
                expreso) Si elegís retiro en nuestro punto de entrega: Retiro SIN CARGO por el
                deposito ubicado en Barracas, en 24hs hábiles una vez acreditado el pago.
                Presentando comprobante correspondiente.
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Tengo que estar presente para recibir mi compra?
            </p>
            <p>
                Si seleccionaste Envío a Domicilio,
                no es necesario que el/la titular de la compra reciba el pedido,
                cualquier persona mayor de 18 años podrá recibir tu compra
                presentando al servicio de entrega responsable su DNI y firmando
                el correspondiente remito.
            </p>
            <p className='font-semibold mt-10 mb-5'>
            </p>
            ¿Con qué correo envían mi compra?
            <p>
                No realizamos envíos por correo.
                Realizamos las entregas con servicios especializados para mayor cuidado.
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Cuánto demora en llegar mi compra?
            </p>
            <p>
                En CABA y GBA: Recibirás tu pedido al domicilio indicado al momento de la
                compra. El plazo de entrega es de hasta 5 días hábiles, una vez confirmado el
                pago. En el interior del país: Enviaremos tu pedido hasta el transporte dentro de
                CABA solicitado por el cliente, el plazo de entrega al expreso es de hasta 5 días
                hábiles, Sentate con Estilo no tiene injerencia sobre los plazos de entrega del
                expreso, para conocerlos el cliente debe comunicarse con la empresa de
                transporte.  Te recordamos que para eventos especiales como Hot Sale o Cyber
                Monday puede llegar a demorar 72 horas hábiles más de lo estipulado
                anteriormente
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Qué tengo que hacer si mi entrega se encuentra demorada?
            </p>
            <p>
                Podés escribirnos a hola@sentate-conestilo.com o comunicarte a
                través de WhatsApp al +54 9 11 3261-9277 de lunes a viernes de 9 a 18 hs.
                Recordá tener a mano el
                número de DNI del/la comprador/a o el número de tu pedido.
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Qué tengo que hacer si escribí mal mi dirección?
            </p>
            <p>
                Podés escribirnos a hola@sentate-conestilo.com indicándonos los siguientes datos:
                <p className='ml-10 mt-1'>Número de pedido:</p>
                <p className='ml-10'>Domicilio de entrega: Calle - Altura - Dpto.</p>
                <p className='ml-10'>Localidad - Provincia y Código Postal</p>
                <p className='ml-10 mb-1'>Datos Adicionales</p>
                De esta forma podremos enviar los datos corregidos al responsable de la entrega. También podés comunicarte a través de WhatsApp al +54 9 11 3261-9277 de lunes a viernes de 9 a 18 hs. Recordá tener a mano el número de DNI del/la comprador/a o el número de tu pedido.
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Puedo retirar mi compra en el punto de entrega Sentate Con Estilo?
            </p>
            <p>
                Sí, podrás pasar a retirar SIN CARGO por el depósito ubicado en Barracas, previa coordinación con el sector de envíos. Presentando la factura de tu compra.
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Puede retirar mi compra otra persona que no sea yo?
            </p>
            <p>
                Sí, la persona que designes puede retirar tu compra sin problema.
                Quien haga el retiro deberá presentar en la sucursal los siguientes datos:
                <p className='ml-10 mt-1'>1. Número de pedido.</p>
                <p className='ml-10'>2. Fotocopia o Foto de tu DNI en su celular.</p>
                <p className='ml-10'>3. DNI que acredite la persona que retira tu compra.</p>
                <p className='ml-10'>4. Factura de tu compra.</p>
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Qué tengo que presentar para retirar mi pedido?
            </p>
            <p>
                Para retirar tu pedido sólo debes presentar tu DNI, el número de pedido y
                la factura del mismo que te enviamos a tu correo.
                En caso de que envíes a alguien para hacer el retiro,
                tené en cuenta que debe ser mayor de 18 años, podrá retirar tu compra presentando:
                <p className='ml-10 mt-1'>1. Número de pedido.</p>
                <p className='ml-10'>2. Fotocopia o Foto de tu DNI en su celular.</p>
                <p className='ml-10'>3. DNI que acredite la persona que retira tu compra.</p>
                <p className='ml-10'>4. Factura de tu compra.</p>
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Puedo retirar mi compra en una sucursal del correo?
            </p>
            <p>
                No, por el momento no tenemos habilitada esta opción. Sólo podés retirar tu compra en nuestras sucursales o bien seleccionar la opción de envío a domicilio donde dependerá del transportista seleccionado si entrega en domicilio o retiras coordinando con el expreso.
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿En qué horarios puedo retirar mi pedido?
            </p>
            <p>
                Nuestro punto de entrega tiene horarios de L a V de 9:30hs a 14:30hs para
                retirar (previa coordinación con el sector de envíos de acuerdo con los tiempos
                de entrega estipulados para el producto)
            </p>
            <p className='mt-10'>
                Si elegís envío a domicilio en CABA con una compra superior al monto que informa la Tienda Web el envío es gratis dentro de CABA (), por compras inferiores el costo del envío podes verlo en el check out con tu código postal (en ambos casos no incluye subida y entrega e departamento, tampoco por escaleras, ni exteriores) previa coordinación con el sector de envíos de acuerdo con los tiempos de entrega estipulados para este producto se podrá solicitar peón de manera adicional. Si elegís envío a domicilio en GBA o al Interior del país: con una compra superior al monto que informa la Tienda Web el envío es gratis () hasta la dirección del servicio de transporte de tu preferencia dentro de CABA, para entregas en transportes o expresos en GBA el costo de entrega lo podrás ver con el CP de la empresa transportista al momento del check out; El costo del envío desde el servicio del transporte hasta el domicilio es a cargo del cliente. (Recordá informarle al equipo de logística el nombre y dirección del expreso) Si elegís retiro en nuestro punto de entrega: Retiro SIN CARGO por el depósito ubicado en Barracas, en 24hs hábiles una vez acreditado el pago. Presentando comprobante correspondiente.
                (*) El monto mínimo de compra para envío Gratis en CABA se calcula sobre el precio de transferencia.
            </p>
            <div className='mt-10 border-b-2 mb-32'></div>
        </div>
    );
};

export default Entrega;
