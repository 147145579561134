import Category from '../../Components/Category/Category';
import CategoryCard from '../../Components/CategoryCard/CategoryCard';
import MainCard from '../../Components/MainCard/MainCard';
import FeaturedProduct from '../../Components/FeaturedProduct/FeaturedProduct';
import Header from '../../Components/Header/Header';
import HeaderLower from '../../Components/HeaderLower/HeaderLower';
import deco from '../../assets/DECO-ILUMINACION-1920x793.jpg';
import decoMobile from '../../assets/DECO-ILUMINACION-mobile@2x.png';

export default function Deco() {
    const opciones = ['Almohadones', 'Lámparas', 'Objetos']
    return (
        <div>
            <Header />
            <HeaderLower />
            <Category img={deco} title={`Deco & Iluminación`} imgMobile={decoMobile} />
            <CategoryCard title={`Deco & Iluminación`} />
            <MainCard opciones={opciones} category={'DECO'} />
            <FeaturedProduct />
        </div>
    )
}
