import React, { useEffect } from 'react';
import Header from '../../Components/Header/Header';
import HeaderLower from '../../Components/HeaderLower/HeaderLower';
import ProductoCarrito from './Productos/Productos';
import FeaturedProduct from '../../Components/FeaturedProduct/FeaturedProduct';
import { useState } from 'react';
import Datos from './Datos/datos';
import Pago from './Confirmacion/confirmacion';
import PagoDatos from './Pago/pago';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';

const CarritoPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [cuponTrue, setCuponTrue] = useState(false);
  const [cuponProductos, setCuponProductos] = useState({});
  const [envio, setEnvio] = useState(0);
  const query = searchParams.get('step');
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (query !== null && query == '2' || query == '4' || query == '3') {
      setStep(parseInt(query));
    }
  }, [])

  return (
    <div>
      <Header />
      <HeaderLower />
      <div className='bg-[#EFEFED] w-full pt-32'>
        <p className='text-sm text-left lg:ml-16 ml-8 font-semibold'><a className='font-normal' href="/">Home {'>'}</a> Login</p>
        <p className='text-5xl my-10 pb-24'>
          Tu Carrito
        </p>
      </div>
      <div className='flex flex-row lg:mx-10 mx-20 lg:justify-between text-center items-center'>
        <div className='flex flex-row lg:ml-5'>
          <div className={`flex flex-row ${step == 1 ? '' : 'opacity-60'}`}>
            <p className={`lg:w-6 w-5 pb-4 mr-1 lg:h-6 h-1 lg:mr-1.5 lg:text-sm text-xs lg:pb-[22px] pt-0.5 lg:mt-1 mt-2 ${step == 1 ? 'bg-[#E40F1B] text-white' : 'bg-white text-black'} border border-black rounded-full`}>1</p>
            <p className='lg:mt-1 mt-2.5 lg:text-sm text-xs font-semibold whitespace-nowrap lg:block hidden'>Tus productos</p>
          </div>
          <div className={`border-t-2 w-10 mt-4 lg:mx-2 mx-0.5 ${step !== 2 ? '' : 'border-[#E40F1B] opacity-80'}`}></div>
          <div className={`flex flex-row ${step == 2 ? '' : 'opacity-60'}`}>
            <p className={`lg:w-6 w-5 pb-4 mr-1 lg:h-6 h-1 lg:mr-1.5 lg:text-sm text-xs lg:pb-[22px] pt-0.5 lg:mt-1 mt-2 ${step == 2 ? 'bg-[#E40F1B] text-white' : 'bg-white text-black'} border border-black rounded-full`}>2</p>
            <p className='lg:mt-1 mt-2.5 lg:text-sm text-xs font-semibold whitespace-nowrap lg:block hidden'>Tus Datos</p>
          </div>
          <div className={`border-t-2 w-10 mt-4 lg:mx-2 mx-0.5 ${step !== 3 ? '' : 'border-[#E40F1B] opacity-80'}`}></div>
          <div className={`flex flex-row ${step == 3 ? '' : 'opacity-60'}`}>
            <p className={`lg:w-6 w-5 pb-4 mr-1 lg:h-6 h-1 lg:mr-1.5 lg:text-sm text-xs lg:pb-[22px] pt-0.5 lg:mt-1 mt-2 ${step == 3 ? 'bg-[#E40F1B] text-white' : 'bg-white text-black'} border border-black rounded-full`}>3</p>
            <p className='lg:mt-1 mt-2.5 lg:text-sm text-xs font-semibold whitespace-nowrap lg:block hidden'>Pago</p>
          </div>
          <div className={`border-t-2 w-10 mt-4 lg:mx-2 mx-0.5 ${step !== 4 ? '' : 'border-[#E40F1B] opacity-80'}`}></div>
          <div className={`flex flex-row ${step == 4 ? '' : 'opacity-60'}`}>
            <p className={`lg:w-6 w-5 pb-4 mr-1 lg:h-6 h-1 lg:mr-1.5 lg:text-sm text-xs lg:pb-[22px] pt-0.5 lg:mt-1 mt-2 ${step == 4 ? 'bg-[#E40F1B] text-white' : 'bg-white text-black'} border border-black rounded-full`}>4</p>
            <p className='lg:mt-1 mt-2.5 lg:text-sm text-xs font-semibold whitespace-nowrap lg:block hidden'>Confirmación</p>
          </div>
        </div>
        {step !== 1 && step !== 4 ?
          <button
            className='hover:bg-red-400 lg:block hidden w-1/3 uppercase bg-[#E40F1B] py-1.5 rounded-sm text-white px-24'
            onClick={() => setStep(1)}
          >
            Volver al carrito
          </button>
          :
          <a
            className='ml-20 hover:bg-red-400 lg:block hidden bg-[#E40F1B] py-1.5 rounded-sm text-white uppercase w-1/3'
            href='/'
          >
            {step == 1 ? 'Seguir comprando' : 'Volver a la tienda'}
          </a>
        }
      </div>
      <div className='lg:ml-3'>
        {step === 1 && <ProductoCarrito setStep={setStep} />}
        {step === 2 && <Datos setCuponProductos={setCuponProductos} cuponProductos={cuponProductos} cuponTrue={cuponTrue} envio={envio} setEnvio={setEnvio} setCuponTrue={setCuponTrue} setStep={setStep} step={step} />}
        {step === 3 && <PagoDatos setCuponProductos={setCuponProductos} cuponProductos={cuponProductos} cuponTrue={cuponTrue} setCuponTrue={setCuponTrue} envio={envio} setEnvio={setEnvio} setStep={setStep} step={step} />}
        {step === 4 && <Pago setCuponProductos={setCuponProductos} cuponProductos={cuponProductos} cuponTrue={cuponTrue} setStep={setStep} step={step} envio={envio} setEnvio={setEnvio} />}
      </div>
      <FeaturedProduct />
    </div>
  );
};

export default CarritoPage;