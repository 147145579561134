import Category from '../../Components/Category/Category';
import CategoryCard from '../../Components/CategoryCard/CategoryCard';
import MainCard from '../../Components/MainCard/MainCard';
import FeaturedProduct from '../../Components/FeaturedProduct/FeaturedProduct';
import Header from '../../Components/Header/Header';
import HeaderLower from '../../Components/HeaderLower/HeaderLower';
import entregaLogo from '../../assets/ENTREGA-INMEDIATA-1920x793.jpg';
import entregaLogoMobile from '../../assets/ENREGA-INMEDIATA-mobile@2x.png';

export default function Entrega() {
    return (
        <div>
            <Header />
            <HeaderLower />
            <Category title={`Entrega Inmediata`} imgMobile={entregaLogoMobile} img={entregaLogo} />
            <CategoryCard title={`Entrega Inmediata`} />
            <MainCard category={'ENTREGA INMEDIATA'} />
            <FeaturedProduct />
        </div>
    )
}