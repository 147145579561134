import React from 'react';

const Politicas = () => {
    return (
        <div className='text-sm text-left lg:w-3/5 lg:px-0 px-5'>
            <p className='mb-2'>• El plazo legal de garantía para productos nuevos es de seis (6) meses. El
                plazo se cuenta a partir de la entrega del producto y la condición de la garantía
                es del vendedor y es aplicable a cualquier defecto estructural o falla de fábrica,
                la garantía no cubre defectos y/ o roturas por mal uso de los productos. La
                garantía se aplica con el cambio sobre el mismo producto o  en el caso de no
                contar con el stock Sentate con Estilo realiza la devolución del dinero tal como
                figura en el ticket de compra. </p>
            <p className='my-2'>• Las Condiciones de Uso representan el acuerdo completo entre las partes y sustituyen a todos los acuerdos anteriores que pudieran existir entre ellas. Los títulos utilizados en estas Condiciones de Uso son sólo con fines de referencia y en ninguna manera definen o limitan el alcance de la disposición que titulan. Si cualquier disposición de las mismas se considerara inaplicable por cualquier razón, tal disposición deberá reformarse sólo en la medida necesaria para hacerla exigible y las demás condiciones del presente Acuerdo permanecerán en pleno vigor y efecto. La inacción de ESTILO 1+1 SAS con respecto a un incumplimiento de este acuerdo por el cliente o por otros no constituye una renuncia y no limitará los derechos de ESTILO 1+1 SAS con respecto a dicho incumplimiento o infracciones posteriores. </p>
            <p className='my-2'>• Ley aplicable, resolución de controversias, medidas procesales y notificaciones. Este contrato será gobernado por y se interpretará según la legislación vigente en la República Argentina. Cualquier conflicto relacionado con este contrato o con el uso que el cliente haga de este sitio será resuelto por los tribunales ordinarios competentes según la legislación vigente y aplicable a la relación de consumo existente entre las partes. En caso de que dicha legislación no defina una competencia específica, será competente la justicia nacional ordinaria en asuntos comerciales con asiento en la Ciudad Autónoma de Buenos Aires, siendo aplicable esta disposición aunque el Usuario estuviera realmente domiciliado fuera de los límites de la Ciudad Autónoma de Buenos Aires o de la República Argentina, por entenderse que este lugar ha sido el lugar de celebración del presente contrato. Salvo que lo contrario haya sido acordado previamente y por escrito firmado entre el cliente y ESTILO 1+1 SAS, todas las notificaciones que se hagan a los usuarios en relación a las presentes Condiciones de Uso se publicarán en el sitio y tendrán efecto desde la fecha de su publicación. </p>
            <p className='mt-2'>• Las notificaciones que el Usuario quiera dirigir a ESTILO 1+1 SAS SRL deberán ser dirigidas a California 1628, Ciudad Autónoma de Buenos Aires donde queda constituido el domicilio. Por dudas sobre las Condiciones de Uso o demás políticas y principios que rigen el sitio el cliente podrá efectuar las consultas que estime corresponder comunicándose al teléfono +54 9 11 3261-9277 de Lunes a Viernes de 10:00hs. a 18:00hs. ESTILO 1+1 SAS. Todos los derechos reservados.</p>
            <div className='mt-10 border-b-2 mb-32'></div>
        </div>
    );
};

export default Politicas;