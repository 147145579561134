import './NewsletterComponent.css';
import star from '../../assets/estrella.png'
import google from '../../assets/_google.svg';
import mail from '../../assets/_mail.svg';
import { useState } from 'react';
import axios from 'axios';

export default function NewsletterComponent() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('¡Suscribete al newsletter!');
    const [loading, setLoading] = useState(false);

    const formatFecha = (date) => {
        const yyyy = date.getFullYear();
        const MM = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const HH = String(date.getHours()).padStart(2, '0');
        const mm = String(date.getMinutes()).padStart(2, '0');
        const ss = String(date.getSeconds()).padStart(2, '0');

        return `${yyyy}/${MM}/${dd} ${HH}:${mm}:${ss}`;
    };

    const handleSubmit = async () => {
        setLoading(true);
        if (!email) {
            setLoading(false);
            return;
        }
        const fecha_alta = formatFecha(new Date());
        const response = await axios.post(`https://43a8phnwnh.execute-api.us-east-1.amazonaws.com/prod/insertar-prospecto`, {
            httpMethod: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            queryStringParameters: {},
            pathParameters: {},
            body: JSON.stringify({ fk_idformulario: 185, nombre: '', whatsapp: '', correo: email, fecha_alta, fk_idarea: 4704 }),
            isBase64Encoded: false,
        });
        if (response.status === 200) {
            setLoading(false);
            setMessage('¡Gracias por suscribirte!');
            setEmail('');
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !loading) {
            handleSubmit();
        }
    };

    return (
        <div className="border text-center border-t-[2px] w-full lg:h-[128px] flex lg:flex-row flex-col justify-center items-center">
            <div className="w-full 2xl:pl-[14%] 2xl:pr-[12%] flex lg:flex-row flex-col lg:h-[46px] lg:mx-60 lg:justify-between items-center">
                <div className='flex lg:flex-row lg:mt-8 mt-4 flex-col'>
                    <div className="lg:mr-5 h-[66px] flex">
                        <img width="80" height="80" className="w-[46px] lg:ml-0 ml-12 h-[46px]" src={mail} alt="email" />
                        <span className='whitespace-nowrap mt-2.5 ml-2'>{message}</span>
                    </div>
                    <div className="relative">
                        <input value={email} onChange={(e) => setEmail(e.target.value)} onKeyDown={handleKeyDown} type="text" className={`bg-white border pr-5 pl-2 py-2 w-72 ${message == '¡Gracias por suscribirte!' ? 'border-green-500' : 'border-black'} rounded-sm`} placeholder="Dejanos tu mail" />
                        <img onClick={() => {
                            !loading &&
                                handleSubmit();
                        }} src="https://img.icons8.com/material-rounded/24/sort-right.png" className="absolute top-3 right-3 w-4" alt="sort-right" />
                    </div>
                </div>
                <a className='mt-5 ml-3' href="https://www.google.com/maps/place/Sentate+con+Estilo/@-34.6465384,-58.372199,17z/data=!4m8!3m7!1s0x95a335c9279d85e1:0xdc6d3d2de796049d!8m2!3d-34.6465384!4d-58.372199!9m1!1b1!16s%2Fg%2F11j43yz7zx?entry=ttu" target="_blank" rel="noopener noreferrer">
                    <div className="reviews-section-newsletter mt-4 lg:mt-0 mb-5 lg:mb-0 lg:mr-0 mr-4">
                        <img width="80" height="80" className="icon-newsletter" src={google} alt="google-plus" />
                        <span>Ver reseñas de Google</span>
                        <div className="stars-newsletter">
                            <img src={star} alt="Star" />
                            <img src={star} alt="Star" />
                            <img src={star} alt="Star" />
                            <img src={star} alt="Star" />
                            <img src={star} alt="Star" />
                        </div>
                    </div>
                </a>
            </div>
        </div>
    );
}
