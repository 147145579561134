import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFavoritos, getMoneyChange } from '../../../../redux/actions';
import Card from '../../../../Components/Card/Card';

const Favoritos = () => {
  const token = { token: localStorage.getItem('token') };
  const dispatch = useDispatch();
  const dolar = useSelector(state => state.dolarPrice);
  const favoritos = useSelector(state => state.favoritos);
  useEffect(() => {
    dispatch(getFavoritos(token));
  }, [])
  return (
    <div>
      {
        favoritos.length > 0 ?
          <div className='grid lg:grid-cols-3 grid-cols-2 lg:mr-32'>
            {favoritos.map((card) => (
              <Card key={card.idproducto}
                imageUrl={card.imagen}
                nombre={card.nombre}
                price={card.precio_pesos}
                item={card}
                dolar={dolar}
                label={false}
                favorite={true}
                deleteFav={true}
                priceOffert={card.precio_oferta}
              />
            ))}
          </div>
          : <p className='text-left lg:mr-10 lg:mx-0 mx-3 mb-60 font-semibold border-b-2'>No hay favoritos</p>
      }
    </div>
  );
};

export default Favoritos;