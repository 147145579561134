import useLocalStorage from "../utils/useLocalStorage/useLocalStorage"
import { DELETE_FILTER, LOGIN_SUCCESS, GET_FAVORITOS, LOGOUT, LOGIN_FAILURE, GET_CART_SHOP, GET_DESTACADAS, GET_FILTER, GET_PRODUCT, GET_PRODUCT_PAGE, GET_CHANGE_DOLAR, GET_RELACIONADOS, GET_BUSCADOR, GET_CARRITO, CHANGE_PRODUCT_QUANTITY } from "./actions"

//Acá se crean los estados, se inicializan con lo que trae el endpoint
const initialState = {
    allProduct: [],
    allDestacadas: [],
    allShopCart: [],
    allFilter: [],
    productPage: {},
    isAuthenticated: false,
    token: "",
    error: null,
    favoritos: [],
    dolarPrice: 0,
    relacionados: [],
    buscador: [],
    carrito: [],
    cartCantidad: {},
    show: false,
    allPriceFilter: [],
    emptyCart: false
}

//Acá se inician y se guardan dependiendo la action
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCT:
            //Siempre debe retornar "...state"
            return {
                ...state,
                allProduct: action.payload,
                allPriceFilter: action.payload
            }
        case GET_DESTACADAS:
            return {
                ...state,
                allDestacadas: action.payload
            }
        case GET_RELACIONADOS:
            return {
                ...state,
                relacionados: action.payload
            }
        case GET_CHANGE_DOLAR:
            return {
                ...state,
                dolarPrice: action.payload
            };
        case GET_BUSCADOR:
            return {
                ...state,
                buscador: action.payload,
                allPriceFilter: action.payload,
                allProduct: action.payload
            }
        case GET_CART_SHOP:
            return {
                ...state,
                allShopCart: [...state.allShopCart, action.payload],
            };
        case 'GET_EMPTY_CART':
            return {
                ...state,
                emptyCart: action.payload,
            }
        case GET_FAVORITOS:
            return {
                ...state,
                favoritos: action.payload,
            };
        case GET_CARRITO:
            return {
                ...state,
                emptyCart: action.payload.length > 0 ? true : false,
                carrito: action.payload,
            };
        case GET_FILTER:
            return {
                ...state,
                allFilter: [...new Set([...state.allFilter, action.payload])]
            };
        case 'GET_FILTER_ORIGEN':
            console.log('entré')
            const origen = [...state.allProduct].filter((item) => {
                const atributosJson = item.atributos;
                const atributos = JSON.parse(atributosJson);
                if (action.payload == 'Todos') return true;
                if (atributos['Origen'] == action.payload) return true;
                return false;
            })

            return {
                ...state,
                allPriceFilter: origen
            };

        case 'CASE_TYPE_FILTER':
            if (action.payload === 'Mayor precio') {
                const sortedList = [...state.allProduct].sort((a, b) => b.precio_dolar - a.precio_dolar);

                return {
                    ...state,
                    allPriceFilter: sortedList
                };
            }
            if (action.payload === 'Menor precio') {
                const sortedList = [...state.allProduct].sort((a, b) => {
                    if (a.precio_dolar === 0) return 1;
                    if (b.precio_dolar === 0) return -1;

                    return a.precio_dolar - b.precio_dolar;
                });

                return {
                    ...state,
                    allPriceFilter: sortedList
                };
            }
            if (action.payload === 'A-Z') {
                const sortedList = [...state.allProduct].sort((a, b) => a.nombre.localeCompare(b.nombre));

                return {
                    ...state,
                    allPriceFilter: sortedList
                };
            }
            if (action.payload === 'Z-A') {
                const sortedList = [...state.allProduct].sort((a, b) => b.nombre.localeCompare(a.nombre));

                return {
                    ...state,
                    allPriceFilter: sortedList
                };
            }
        case 'PRICE_FILTER':
            const dolar = action.payload.dolar;
            const mayorPrecio = action.payload.mayorPrecio;
            const menorPrecio = action.payload.menorPrecio;

            // Filtrar y luego ordenar
            const sortedList = [...state.allProduct].filter((item) => {
                const itemPriceUSD = item.precio_oferta_dolares ? item.precio_oferta_dolares : item.precio_dolar;

                const precioConIncremento = itemPriceUSD * dolar;
                const descuento = precioConIncremento * 0.20;
                const precioFinal = (precioConIncremento - descuento);

                return (itemPriceUSD !== 0 && Number(precioFinal) >= Number(menorPrecio) && Number(precioFinal) <= Number(mayorPrecio));
            }).sort((a, b) => {
                if (a.precio_dolar === 0) return 1;
                if (b.precio_dolar === 0) return -1;
                return 0;
            });
            console.log(action.payload)
            if (Number(mayorPrecio) === 5000000000 && Number(menorPrecio) === 0) {
                return {
                    ...state,
                    allPriceFilter: state.allProduct
                };
            }

            return {
                ...state,
                allPriceFilter: sortedList
            };
        case DELETE_FILTER:
            if ('Eliminar filtros' === action.payload) {
                return {
                    ...state,
                    allFilter: []
                }
            }
            let deleteFilter = state.allFilter.filter((item) => item != action.payload)

            return {
                ...state,
                allFilter: deleteFilter
            }
        case GET_PRODUCT_PAGE:
            return {
                ...state,
                productPage: action.payload
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                token: action.payload,
                error: null
            };
        case LOGOUT:
            return {
                ...state,
                isAuthenticated: false,
                token: null,
                error: null
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                isAuthenticated: false,
                token: null,
                error: action.payload
            };
        case CHANGE_PRODUCT_QUANTITY:
            return {
                ...state,
                cartCantidad: {
                    ...state.cart,
                    [action.payload.productId]: action.payload.newQuantity,
                },
            };
        case 'GET_NAVBAR_SHOW':
            return {
                ...state,
                show: action.payload
            };
        default:
            return state;
    }
}