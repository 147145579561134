import React, { useEffect, useState } from 'react';
import Header from '../../../Components/Header/Header';
import HeaderLower from '../../../Components/HeaderLower/HeaderLower';
import Datos from './Datos/datos.jsx';
import Favoritos from './Favoritos/favoritos.jsx';
import Compras from './compras/compras.jsx';
import Envios from './Envios/envios.jsx';
import AgregarDomiclio from './Datos/agregar-domicilio/agregar-domicilio.jsx';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { getMoneyChange } from '../../../redux/actions.js';

const MisDatos = () => {
    const [showPass, setShowPass] = useState(false);
    const [change, setChange] = useState('mis-datos');
    const token = { token: localStorage.getItem('token') };

    const { fav } = useParams();

    const handleQuitSession = () => {
        localStorage.removeItem('token');
        window.location.href = '/';
    }
    const dispatch = useDispatch();
    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setChange(selectedValue);
    }

    useEffect(() => {
        dispatch(getMoneyChange())
        if (token.token === null) {
            window.location.href = '/ingresar';
        }
        if (fav) {
            setChange('favoritos');
        }
    }, []);

    return (
        <div>
            <Header />
            <HeaderLower />
            <div className='flex flex-col pt-20 justify-center'>
                <div className='bg-[#EFEFED] w-full lg:pt-10 pt-5'>
                    <div className='2xl:pl-[14%] 2xl:pr-[10%]'>
                        <p className='text-sm lg:block hidden text-left lg:ml-16 ml-10 font-semibold'><a className='font-normal' href="/">Home {'>'}</a> Login</p>
                        <select
                            value={change}
                            className="flex justify-start w-32 ml-6 lg:hidden border-gray-300 rounded-md text-black border-opacity-0 p-2 bg-opacity-0 bg-transparent"
                            onChange={handleSelectChange}
                        >
                            <option value="mis-datos">Mis Datos</option>
                            <option value="favoritos">Mis Favoritos</option>
                            <option value="compras">Mis Compras</option>
                            <option value="envios">Seguimiento de envíos</option>
                        </select>

                        <p className='text-5xl my-10 lg:pb-20 pb-10'>
                            Mis datos
                        </p>
                    </div>
                </div>
                <div className='lg:grid 2xl:pl-[14%] 2xl:pr-[10%] lg:grid-cols-7'>
                    <div className='col-span-2 mt-12 lg:block hidden'>
                        <p className={`border-b-[1px] ${change === ('mis-datos') ? 'font-semibold' : ''} border-opacity-70 ml-20 mr-10 text-[14px] text-left`}>
                            <button onClick={() => setChange('mis-datos')}>Mis Datos</button>
                        </p>
                        <p className={`border-b-[1px] ${change === ('favoritos') ? 'font-semibold' : ''} border-opacity-70 my-2 ml-20 mr-10 text-[14px] text-left`}>
                            <button onClick={() => setChange('favoritos')}>Mis Favoritos</button>
                        </p>
                        <p className={`border-b-[1px] ${change === ('compras') ? 'font-semibold' : ''} border-opacity-70 my-2 ml-20 mr-10 text-[14px] text-left`} >
                            <button onClick={() => setChange('compras')}>Mis Compras</button>
                        </p>
                        <p className={`border-b-[1px] ${change === ('envios') ? 'font-semibold' : ''} border-opacity-70 ml-20 mr-10 text-[14px] text-left`}>
                            <button onClick={() => setChange('envios')}>Seguimientos de envíos</button>
                        </p>
                        <button
                            onClick={() => handleQuitSession()}
                            className='w-[70%] ml-20 mr-10 rounded-[4px] h-9 mt-20 border border-bg-gray-700 hover:text-white text-black hover:bg-gray-500'>Cerrar Sesión</button>
                    </div>
                    <div className='col-span-5 flex flex-col mt-10'>
                        {
                            change == 'mis-datos' && <Datos showPass={showPass} setShowPass={setShowPass} setChange={setChange} />
                        }
                        {
                            change == 'favoritos' && <Favoritos />
                        }
                        {
                            change == 'compras' && <Compras />
                        }
                        {
                            change == 'envios' && <Envios />
                        }
                        {
                            change == 'domicilio' && <AgregarDomiclio />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MisDatos;