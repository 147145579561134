import React from 'react';

const Promocion = () => {
    return (
        <div className='text-sm text-left lg:w-3/5 lg:px-0 px-5'>
            <p className='font-semibold text-lg'>Promociones y Pagos</p>
            <p className='mt-5 mb-5 font-semibold'>
                ¿Tengo las mismas promociones en Tienda Online y en sucursales?
            </p>
            <p>
                Nuestros precios de lista son los mismos en ambos canales, pero las promociones pueden variar tanto en nuestros locales como en la Tienda Online.
            </p>
            <p className='mt-10 mb-5 font-semibold'>
                ¿Tengo promociones bancarias por Tienda Online?
            </p>
            <p>
                La mayoría de las promociones bancarias son ofrecidas en nuestras sucursales y en Tienda Online. Podés registrarte en nuestra Web para no perderte ninguna, suscribiéndote a nuestro Newsletter.
            </p>
            <p className='mt-10 mb-5 font-semibold'>
                No puedo realizar en pago en Tienda Online, ¿qué puedo hacer?
            </p>
            <p>
                Si tu problema se da a una notificación de autorización, intenta con otro mail u otra tarjeta, si el problema persiste podés comunicarte a través de WhatsApp al +54 9 11 3261-9277 de lunes a viernes de 9 a 18 hs. Recuerda tener a mano el nombre y número de DNI con el que intentaste realizar la compra.
            </p>
            <p className='mt-10 mb-5 font-semibold'>
                ¿Puedo pagar con dinero en mi cuenta de Mercado Pago?
            </p>
            <p>
                Sí, en la opción de pago debes seleccionar Mercado Pago, te direcciona a su página y desde ahí puedes abonar con tu dinero en cuenta.
            </p>
            <p className='mt-10 mb-5 font-semibold'>
                ¿Por qué no me toma el código de descuento que tengo?
            </p>
            <p>
                Recuerda verificar la vigencia del código que estás ingresando. Si está vigente podemos ayudarte por mail en hola@sentate-conestilo.com o a través de WhatsApp al +54 9 11 3261-9277 de lunes a viernes de 9 a 18 hs.
            </p>
            <p className='mt-10 mb-5 font-semibold'>
                Necesito Factura A para mi compra, ¿cómo hago?
            </p>
            <p>
                En caso de necesitar factura A, podés indicarnos el CUIT al momento de realizar tu compra. Si no lo informaste, escribirnos a hola@sentate-conestilo.com e indicándonos los siguientes datos:
                <p className='ml-10 mt-1'>CUIT</p>
                <p className='ml-10'>Razón Social</p>
                <p className='ml-10 mb-1'>Domicilio Fiscal</p>
                Te recordamos que somos agentes de retención de IIBB por lo que te contactaremos para informarte de los mismos.
            </p>
            <div className='mt-10 border-b-2 mb-32'></div>
        </div>
    );
};

export default Promocion;