import React, { useEffect, useState } from 'react';
import CardCarritoPage from '../../../ui/cardCarritoPage/CardCarritoPage';
import card from '../../../assets/black-card.svg';
import embalaje from '../../../assets/__embalaje_1.svg';
import envios from '../../../assets/__envios.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getCarrito, getMoneyChange } from '../../../redux/actions';
import CardCarritoPageMobile from '../../../ui/cardCarritoMobile/CardCarritoMobile';

const ProductoCarrito = ({ setStep }) => {
    const [loading, setLoading] = useState(false);
    const cantidadProducto = [];

    const carrito = useSelector((state) => state.carrito);
    const precioDolar = useSelector((state) => state.dolarPrice);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCarrito(localStorage.getItem('token')));

        let timer;
        timer = setTimeout(() => {
            setLoading(true);
        }, 1500);

        return () => clearTimeout(timer);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const precioTotalOferta = carrito && carrito.reduce((previous, current) => {
        const itemPriceUSDOffert = current.precio_oferta_dolares;
        const itemPriceUSD = current.precio_dolar;
        const precioConIncremento = (itemPriceUSD * precioDolar);
        const precioConIncrementoOferta = itemPriceUSDOffert * precioDolar;

        const descuento = precioConIncremento;
        const descuentoOferta = precioConIncrementoOferta;

        const precioFinal = ((precioConIncremento || descuento) * current.cantidad).toFixed(0);
        const precioFinalOferta = ((precioConIncrementoOferta || descuentoOferta) * current.cantidad).toFixed(0);
        return previous + (Number(itemPriceUSDOffert) != 0 ? Number(precioFinalOferta) : Number(precioFinal));
    }, 0);

    const precioTotal = carrito && carrito.reduce((previous, current) => {
        const itemPriceUSD = current.precio_dolar;
        const precioConIncremento = itemPriceUSD * precioDolar;
        
        // Definimos porcentaje y cantidad de la promoción
        const porcentajePromo = Number(current.porcentaje_promo) || 0;
        const cantidadPromo = Number(current.cantidad_promo) || 0;
      
        let resultado;
        if (porcentajePromo) {
            resultado = precioConIncremento * (1 - porcentajePromo / 100);
        } else if (cantidadPromo) {
            resultado = precioConIncremento - cantidadPromo;
        } else {
            resultado = precioConIncremento; // Si no hay promo, se toma el precio con incremento directamente
        }
        
        // Aplicamos un descuento adicional del 20%
        const precioConDescuentoAdicional = resultado;
      
        // Calculamos el precio final por la cantidad de artículos
        const precioFinal = (precioConDescuentoAdicional * current.cantidad).toFixed(0);
    
        // Sumar el precio final al total acumulado
        return previous + Number(precioFinal);
    }, 0);
    

    return (
        <div className='mt-10 lg:mx-10 flex-col lg:flex-row flex mb-20'>
            <div className='lg:w-2/3 w-full'>
                <div className='lg:flex hidden flex-row justify-between border-b-2 text-lg mb-5 border-opacity-30 border-black'>
                    <p className='text-sm '>Productos agregados</p>
                    <p className='text-sm ml-14'>Entrega</p>
                    <p className='text-sm ml-5'>Cantidad</p>
                    <p className='text-sm pr-28'>Precio</p>
                </div>
                <div className='lg:block hidden'>
                    {
                        carrito && loading ? carrito.map((item) => (
                            <div>
                                {Number(item.precio_dolar) !== 0 &&
                                    <div>
                                        <CardCarritoPage dolar={precioDolar} item={item} setLoading={setLoading} cantidadProducto={cantidadProducto} />
                                        <div className='border-b-2 my-2 border-opacity-30'></div>
                                    </div>
                                }
                            </div>
                        ))
                            :
                            <div className="flex justify-center items-center mt-32 mb-60">
                                <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#E40F1B]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                                <span class="sr-only">Loading...</span>
                            </div>
                    }
                </div>
                <div className='lg:hidden'>
                    {
                        carrito && loading ? carrito.map((item) => (
                            <div>
                                {Number(item.precio_dolar) !== 0 &&
                                    <div>
                                        <CardCarritoPageMobile dolar={precioDolar} item={item} setLoading={setLoading} cantidadProducto={cantidadProducto} />
                                        <div className='border-b-2 my-2 border-opacity-30'></div>
                                    </div>
                                }
                            </div>
                        ))
                            :
                            <div className="flex justify-center items-center mt-32 mb-60">
                                <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#E40F1B]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                                <span class="sr-only">Loading...</span>
                            </div>
                    }
                </div>
            </div>
            <div className='lg:w-1/3 w-full lg:ml-5 h-96 mt-5 bg-[#f9f9fb]'>
                <div className='font-semibold text-sm text-left m-3'>
                    <p className='uppercase'>Subtotal:</p>
                    <p className='text-3xl font-medium my-1'>{precioTotal.toLocaleString("es-AR", {
                        style: "currency",
                        currency: "ARS",
                        maximumFractionDigits: 0,
                    })}</p>
                    <div className='flex flex-row mt-2 opacity-40 font-normal'>
                        <img src={card} alt="" className='w-6 mr-1' />
                        <p className='text-lg'>o en 6 cuotas de {(precioTotal / 6).toLocaleString("es-AR", {
                            style: "currency",
                            currency: "ARS",
                            maximumFractionDigits: 0,
                        })}-</p>
                    </div>
                    <div className='border-y-2 mt-3'>
                        <p className='my-1'>El envío se calcula en el siguiente paso.</p>
                    </div>
                    <div className='flex flex-row mt-3'>
                        <img src={envios} alt="" className='w-12 mr-1' />
                        <div className='text-sm ml-2 opacity-60'>
                            <p className='whitespace-nowrap'><strong className='font-semibold'> Envío gratis en CABA</strong> a partir de $200.000.<br /></p>
                            <p className=''>Envíos a todo el país por expreso a cargo del comprador.</p>
                        </div>
                    </div>
                    <div className='flex flex-row mt-3 items-center'>
                        <img src={embalaje} alt="" className='w-12 mr-1' />
                        <p className='text-sm ml-2 opacity-60'>⁠Embalaje simple (nylon/film) GRATIS dentro de CABA y Gran Buenos Aires.
                            ⁠Embalaje completo (film/cartón/caja) GRATIS envíos interior del País.
                            ⁠Embalajes especiales (encofrados) para producto frágil (vidrio, etc) consultar.</p>
                    </div>
                    <button onClick={() => {
                        window.location.href = '/carrito?step=2';
                        scrollToTop();
                    }} className='bg-[#1c1c1c] hover:bg-gray-400 text-white px-20 uppercase w-full py-2 ml-1 rounded-sm mt-5'>Siguiente paso</button>
                </div>
            </div>
        </div>
    );
};

export default ProductoCarrito;