import { useState } from 'react';
import search from '../../assets/search.svg';

const Search = ({ isSearch, handleSearch }) => {
    const [searchItem, setSearchItem] = useState('');

    const handleChange = (e) => {
        setSearchItem(e.target.value);
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            window.location.href = `/buscador?b=${searchItem}`;
        }
    }

    return (
        <div>
            {isSearch &&
                <div onClick={() => handleSearch(!isSearch)} className='w-full inset-0 backdrop-filter bg-black bg-opacity-20 backdrop-blur-[1px] z-[2000] fixed'>
                    <div onClick={(e) => e.stopPropagation()} className='flex relative flex-row lg:my-[15rem] mt-28 lg:mx-60 mx-10'>
                        <a href={`/buscador?b=${searchItem}`}>
                            <img src={search} className='w-5 m-1 left-1 lg:mt-2.5 mt-7 absolute' alt="" />
                        </a>
                        <input onKeyPress={handleKeyPress} onChange={handleChange} type="text" className='text-sm border-black rounded-lg pl-10 lg:py-2.5 py-7 border px-30 lg:w-full w-full' placeholder='¿Qué estas buscando?' />
                        <button onClick={() => handleSearch(!isSearch)} className='absolute right-5 lg:mt-2 mt-6'>x</button>
                    </div>
                </div>
            }
        </div>
    )
}

export default Search;