import card from '../../assets/card.svg';
import arrowLeft from '../../assets/arrow-left.svg';
import arrowRight from '../../assets/arrow-right.svg';
import truck from '../../assets/shiping.svg';
import calendar from '../../assets/calendar.svg';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getNavbarShow } from '../../redux/actions';


export default function Header() {
    const [changeInfo, setChangeInfo] = useState(0);
    const infoHeader = [
        {
            info: '6 cuotas sin interés',
            icon: card,
            class: ''
        },
        {
            info: '20% de descuento con Transferencia o Efectivo ',
            icon: '',
            class: ''
        },
        {
            info: 'Envío gratis en CABA a partir de $ 200.000',
            icon: truck,
            class: ''
        },
        {
            info: 'Retiro Gratis en nuestro depósito de Barracas',
            icon: calendar,
            class: ''
        }
    ];

    const dispatch = useDispatch();
    useEffect(() => {
        const sliderInterval = setInterval(() => {

            setTimeout(() => {
                setChangeInfo((changeInfo) => (changeInfo + 1) % infoHeader.length);
            }, 500);
        }, 5000);

        return () => clearInterval(sliderInterval);
    }, []);
    const handleClick = (e, isLeftArrow) => {
        e.preventDefault();
        const newIndex = (changeInfo + (isLeftArrow ? -1 : 1) + infoHeader.length) % infoHeader.length;
        setChangeInfo(newIndex);
    }


    return (
        <>
            <div className="h-[2rem] 2xl:pl-[12%] 2xl:pr-[12%] z-[1000] w-full fixed lg:w-full bg-black"
                onMouseEnter={() => {
                    dispatch(getNavbarShow(true))
                }}
            >
                <div className='flex flex-row justify-between mx-3'>
                    <div className='mt-3 lg:ml-60'>
                        <img src={arrowLeft} onClick={(e) => handleClick(e, true)} alt='arrow left' className='w-3 mt4' />
                    </div>
                    <div onClick={(e) => handleClick(e, true)} className='flex flex-row items-center mt-0.5'>
                        {infoHeader[changeInfo].icon &&
                            <div>
                                <img src={infoHeader[changeInfo].icon} alt='tarjeta' className='w-4 mt-[5px] mx-1' />
                            </div>
                        }
                        <div className='text-[12px] mt-[5px]'>
                            <p className='text-white'>{infoHeader[changeInfo].info}</p>
                        </div>
                    </div>
                    <div className='flex flex-row mt-3'>
                        <a href="">
                            <img onClick={(e) => handleClick(e, false)} src={arrowRight} alt='arrow right' className='w-3 lg:mr-60' />
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}