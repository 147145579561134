import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CardComprasHistorial from './cardcompras/cardcompras';
import { useDispatch, useSelector } from 'react-redux';
import { getMoneyChange } from '../../../../redux/actions';
import cancelar from '../../../../assets/close.svg';

const Compra = () => {
  const [loading, setLoading] = useState(true);
  const [datos, setDatos] = useState([]);
  const [ver, setVer] = useState(false);
  const dolar = useSelector((state) => state.dolarPrice);
  const dispatch = useDispatch();

  // Nuevo estado para el control de visibilidad de detalles por ID de pedido
  const [detallesVisibles, setDetallesVisibles] = useState(null);
  useEffect(() => {
    const conseguirPedidos = async () => {
      const token = localStorage.getItem('token');
      try {
        const res = await axios.post(
          'https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/mis-pedidos',
          {
            httpMethod: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            queryStringParameters: {},
            pathParameters: {},
            body: JSON.stringify({ token }),
            isBase64Encoded: false,
          }
        );
        const response = JSON.parse(res.data.body)
        console.log(response)
        if (response.error) {
          window.localStorage.removeItem('token')
          window.location.href = '/ingresar'
        }
        setDatos(response);
        setLoading(false);
      } catch (error) {
        console.error('Error al conseguir los pedidos:', error);
        setLoading(false);
      }
    };
    dispatch(getMoneyChange())
    conseguirPedidos();
  }, []);

  const cancelarPedido = async (idpedido) => {
    const token = localStorage.getItem('token');
    try {
      await axios.post(
        'https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/cancelar_pedido',
        {
          httpMethod: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          queryStringParameters: {},
          pathParameters: {},
          body: JSON.stringify({ token, idpedido }),
          isBase64Encoded: false,
        }
      );
      window.location.reload();

    } catch (error) {
      console.error('Error al conseguir los pedidos:', error);
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Cargando historial de compras...</div>;
  }

  return (
    <div className='pb-4 pr-4 lg:pr-60 mx-3'>

      <p className='border-b-2 mb-2 text-left'>
        Historial de compras {ver && detallesVisibles && `- Pedido #${detallesVisibles}`}
      </p>

      <div className='w-full text-sm text-left overflow-x-auto max-w-full-md mx-auto'>
        <table className='w-full'>
          <thead>
            <tr className='whitespace-nowrap'>
              <th className='font-normal opacity-90'>Fecha</th>
              <th className='font-normal opacity-90 mx-20'>Número de pedido</th>
              <th className='font-normal opacity-90 mx-20'>Importe</th>
              <th className='font-normal opacity-90 mx-20'>Estado</th>
              <th className='font-normal opacity-90 mx-20'>Comprobantes</th>
              <th className='font-normal opacity-90 mx-20'>Detalles</th>
              <th className='font-normal opacity-90 mx-20'>Cancelar</th>
            </tr>
          </thead>
          <tbody>
            {[...datos].reverse().map((item) => {
              const hora = item.fecha.split('T')[1].split('.')[0].split(':').slice(0, 2).join(':');
              let tipo_estado;
              let color;
              console.log(item.fk_idpedidoestado)
              switch (item.estado) {
                case '1':
                  tipo_estado = 'Recibido'
                  color = 'text-yellow-600'
                  break;
                case '2':
                  tipo_estado = 'Confirmado'
                  color = 'text-yellow-600'
                  break;
                case '3':
                  tipo_estado = 'En distribución'
                  color = 'text-yellow-600'
                  break;
                case '4':
                  tipo_estado = 'En preparación'
                  color = 'text-yellow-600'
                  break;
                case '5':
                  tipo_estado = 'Entregado'
                  color = 'text-green-600'
                  break;
                case '6':
                  tipo_estado = 'Cancelado'
                  color = 'text-[#E40F1B]'
                  break;
                default:
                  tipo_estado = 'Recibido';
                  color = 'text-yellow-600';
                  break;
              }

              return (
                <React.Fragment key={item.idpedido}>
                  <tr className='whitespace-nowrap'>
                    <td>{new Date(item.fecha).toLocaleDateString('es-AR') + ' ' + hora}</td>
                    <td className='mx-20'>#{item.idpedido}</td>
                    <td className='mx-20'>{Number(item.total).toLocaleString("es-AR", {
                      style: "currency",
                      currency: "ARS",
                      maximumFractionDigits: 0,
                    })}</td>
                    <td className={`mx-20 ${item.estado == 'Cancelado' || item.estado == 'Entregado' ? item.estado == 'Cancelado' ? 'text-[#E40F1B]' : 'text-green-600' : 'text-yellow-600'}`}>{item.estado}</td>
                    <td className='mx-20'>Descargar</td>
                    <td
                      onClick={() => {
                        if (detallesVisibles === item.idpedido && ver) {
                          setVer(false);
                          setDetallesVisibles(null);
                        } else {
                          setDetallesVisibles(item.idpedido);
                          setVer(true);
                        }
                      }}
                      className='hover:cursor-pointer mx-20'
                    >
                      {detallesVisibles === item.idpedido && ver ? 'Ocultar' : 'Ver'}
                    </td>
                    <td className='text-center mx-20'>
                      {item.mediopago !== 'Mercadopago checkout' && <img
                        onClick={() => {
                          cancelarPedido(item.idpedido);
                        }}
                        src={cancelar}
                        className='w-2.5 mt-1.5 hover:cursor-pointer'
                      />}
                    </td>
                  </tr>
                  {detallesVisibles === item.idpedido && (
                    <tr key={`detalles-${item.idpedido}`} className='whitespace-nowrap'>
                      <td colSpan="7">
                        {JSON.parse(item.productos).map((producto) => (
                          <CardComprasHistorial dolar={dolar} item={producto} setLoading={setLoading} />
                        ))}
                        <div className='flex flex-row font-semibold text-sm mt-3 justify-between'>
                          <p className='flex flex-row'>Forma de pago: <p className='ml-2 font-normal'>{item.mediopago}</p></p>
                          <p className='mr-10'>Total: <span>{Number(item.total).toLocaleString("es-AR", {
                            style: "currency",
                            currency: "ARS",
                            maximumFractionDigits: 0,
                          })}.-</span></p>
                        </div>
                        <div className="mt-3 border-b-[0.5px]"></div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>

  );
};

export default Compra;
