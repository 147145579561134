import './CategoryCard.css';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min.js';

export default function CategoryCard({ title }) {
    const { tipoDeProducto } = useParams();
    function formatString(str) {
        return str?.split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }
    let formattedString = formatString(tipoDeProducto);
    if (formattedString === 'Todos') {
        formattedString = 'Todos los productos'
    }
    return (
        <div className="flex mb-16 2xl:pl-[14%] 2xl:pr-[10%] mt-8 whitespace-nowrap flex-col lg:mx-7">
            <div className={`flex opacity-90 font-normal lg:text-sm text-xs text-left mb-9`}>
                <a className="mr-0.5 ml-4"><a href='/'>Home</a></a>
                <a className="mx-1">{'>'}</a>
                <a className="mx-0.5"><a href='/'>Shop</a></a>
                <a className="mx-1">{'>'}</a>
                <a className="ml-0.5"><a href={`/${title.toLocaleLowerCase()}`}>{title}</a></a>
            </div>
            <div className=''>
                <span className="lg:text-[3rem] text-3xl font-extralight">{formattedString || title}</span>
            </div>
        </div>
    );
}