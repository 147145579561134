import React from 'react';

const Tienda = () => {
    return (
        <div className='text-sm text-left lg:w-3/5 lg:px-0 px-5'>
            <p className='font-semibold text-lg'>Tienda Online</p>
            <p className='font-semibold mt-10 mb-5'>¿Tengo las mismas promociones en Tienda Online y en sucursales?</p>
            <p>
                Nuestros precios de lista son los mismos en todos los canales, sin embargo, las promociones pueden variar tanto en la Tienda Online como en otras Plataformas donde vendemos.
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Cómo me registro para recibir promociones de Sentate Con Estilo?
            </p>
            <p>
                Podés registrarte en nuestra web para no perderte ninguna, suscribiéndote a nuestro Newsletter.
            </p>
            <p className='font-semibold mt-10 mb-5'>
                Necesito hacer un cambio en mis datos personales. ¿Qué puedo hacer?
            </p>
            <p>
                Podés solicitar el cambio de datos vía mail a hola@sentate-conestilo.com o a través de WhatsApp al +54 9 11 3261-9277 de lunes a viernes de 9 a 18 hs.
            </p>
            <div className='mt-10 border-b-2 mb-32'></div>
        </div>
    );
};

export default Tienda;