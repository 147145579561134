import React, { useEffect, useState } from 'react';
import Header from '../../../Components/Header/Header';
import HeaderLower from '../../../Components/HeaderLower/HeaderLower';
import ver from '../../../assets/ver.svg';
import noVer from '../../../assets/no-ver.svg';
import axios from 'axios';

const URL_API_LOGIN = 'https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com';

const Registro = () => {
    const [showPass, setShowPass] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [user, setUser] = useState({
        nombre: '',
        apellido: '',
        mail: '',
        confirmEmail: '',
        clave: '',
        celular: 0
    });

    const token = { token: localStorage.getItem('token') };

    useEffect(() => {
        if (token.token !== null) {
            window.location.href = '/';
        }
    }, [token.token]);

    const cuerpoCorreo = `<html lang="es"><body><div style="flex: content ;font-family: Quicksand; text-align: left; color: #333; max-width: 600px; margin: 20px auto; border: 1px solid #ddd; padding: 20px; box-shadow: 0 0 10px rgba(0,0,0,0.1);"><div style="text-align: center;"><img src="https://www.sentateconestilo.com.ar/logo-horizontal-ok+(1).png" alt="Logo" title="Logo SCE" style="width: 30%; margin-right: 47%; height:auto; display:block;"></div><h1 style="color: #333; margin-left: 65px; ">¡Bienvenido/a!</h1><p style="margin-left: 65px;">Cuenta creada con éxito, te invitamos a que conozcas nuestros productos.</p><p style="margin-left: 65px;">¡Gracias por registrarte!</p><div style="text-align: center; margin: 40px 0;"><a href="https://www.sentateconestilo.com.ar/" style="background-color: #000; color: #fff; padding: 10px 30%; text-decoration: none; border-radius: 5px;">IR A LA TIENDA</a></div><p style="font-size: 12px; margin-left: 65px; opacity: 70%; ">Por cualquier consulta podés responder este correo electrónico o contactarnos a través de <a href="mailto:hola@senateconestilo.com">hola@senateconestilo.com</a> o por <a href="https://api.whatsapp.com/send?phone=541132619277">WhatsApp</a></div></body></html>`;
    const asunto = "¡Bienvenido/a a Sentate con Estilo!";

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const passwordRegex = /^(?=.*[A-Z]).{8,}$/;
    const nameRegex = /^[a-zA-Z' ]+$/;

    const params = {
        asunto: asunto,
        correo: user.mail,
        CuerpoCorreo: cuerpoCorreo
    };

    const handleChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!emailRegex.test(user.mail)) {
            setError('El email ingresado no es válido');
            setLoading(false);
            return;
        }
        if (user.mail !== user.confirmEmail || !emailRegex.test(user.confirmEmail)) {
            setError('Los emails no coinciden');
            setLoading(false);
            return;
        }
        if (!passwordRegex.test(user.clave)) {
            setError('La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula, un número y un caracter especial');
            setLoading(false);
            return;
        }
        if (!nameRegex.test(user.nombre) || !nameRegex.test(user.apellido)) {
            setError('El nombre y el apellido deben contener solo letras');
            setLoading(false);
            return;
        }

        if (!user.celular) {
            setError('El teléfono es obligatorio');
            setLoading(false);
            return;
        } else {
            setError('');
        }

        try {
            const response = await axios.post(`${URL_API_LOGIN}/prod/sce-register`, {
                httpMethod: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(user),
                isBase64Encoded: false,
            });
            const existError = JSON.parse(response.data.body);
            if (existError.code === "ER_DUP_ENTRY") {
                setError('Este correo ya existe.');
                setLoading(false);
            } else if (existError.message === "Este correo ya existe") {
                setError('Este correo ya existe.');
                setLoading(false);
            } else {
                await axios.get("https://sistema.depcsuite.com/certificanna/enviarCorreo", {
                    params: params,
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                window.location.href = '/ingresar?n=true';
            }
        } catch (error) {
            console.error('Error during request:', error);
            setLoading(false);
        }
        setLoading(false);
    };

    return (
        <div>
            <Header />
            <HeaderLower />
            <div className='flex flex-col pt-20 justify-center'>
                <div className='bg-[#EFEFED] w-full lg:pt-10 pt-8'>
                    <div className='2xl:pl-[14%] 2xl:pr-[10%]'>
                        <p className='text-xs text-left lg:ml-16 ml-10 font-semibold'>
                            <a className='font-normal' href="/">Home {'>'}</a> Registro
                        </p>
                        <p className='text-5xl my-10 lg:pb-20 pb-10'>Creá tu cuenta</p>
                    </div>
                </div>
                <div className='flex flex-col mt-10 justify-center text-left items-center'>
                    {error && <p className='text-[#E40F1B] pr-4 mb-5 font-semibold opacity-80 text-sm'>{error}</p>}
                    <form className='flex flex-col lg:w-[60%] w-full mx-auto' onSubmit={handleSubmit}>
                        <div className='flex flex-col mb-4'>
                            <label htmlFor="nombre">Nombre*</label>
                            <input onChange={handleChange} type="text" required name='nombre' className='border py-2 my-2.5 px-2.5 hover:border-[#E40F1B] rounded-sm w-full' />
                        </div>
                        <div className='flex flex-col mb-4'>
                            <label htmlFor="apellido">Apellido*</label>
                            <input onChange={handleChange} type="text" required name='apellido' className='border py-2 my-2.5 px-2.5 hover:border-[#E40F1B] rounded-sm w-full' />
                        </div>
                        <div className='flex flex-col mb-4'>
                            <label htmlFor="mail">Email*</label>
                            <input onChange={handleChange} type="text" required name='mail' className='border py-2 my-2.5 px-2.5 hover:border-[#E40F1B] rounded-sm w-full' />
                        </div>
                        <div className='flex flex-col mb-4'>
                            <label htmlFor="confirmEmail">Confirmar email*</label>
                            <input onChange={handleChange} type="text" required name='confirmEmail' className='border py-2 my-2.5 px-2.5 hover:border-[#E40F1B] rounded-sm w-full' />
                        </div>
                        <div className='flex flex-col mb-4'>
                            <label htmlFor="celular">Teléfono*</label>
                            <input onChange={handleChange} type="number" required name='celular' className='border py-2 my-2.5 px-2.5 hover:border-[#E40F1B] rounded-sm w-full' />
                        </div>
                        <div className='flex flex-col mb-4 relative'>
                            <label htmlFor="clave">Contraseña*</label>
                            <div className='relative'>
                                <button type='button' className='absolute right-2 top-2' onClick={() => setShowPass(!showPass)}>
                                    <img src={showPass ? noVer : ver} className='opacity-50' alt="Mostrar/Ocultar contraseña" />
                                </button>
                                <input required onChange={handleChange} name='clave' type={showPass ? "text" : "password"} className='border py-2 my-2.5 px-2.5 hover:border-[#E40F1B] rounded-sm w-full' />
                            </div>
                        </div>
                        <button type="submit" className='uppercase flex justify-center bg-black text-white py-2 text-sm mt-2.5 mb-5 hover:bg-[#E40F1B] rounded-sm w-full'>
                            {loading ?
                                <svg aria-hidden="true" className="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-[#E40F1B]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                                : 'Registrarme'}
                        </button>
                        <div className='mb-20'>
                            <div>
                                <a href='/' className='lg:text-sm text-lg opacity-50 border-gray-400 border-b-2'>Cancelar y volver a la tienda</a>
                            </div>
                            <div>
                                <p></p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Registro;
