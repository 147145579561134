import Category from '../../Components/Category/Category';
import CategoryCard from '../../Components/CategoryCard/CategoryCard';
import MainCard from '../../Components/MainCard/MainCard';
import FeaturedProduct from '../../Components/FeaturedProduct/FeaturedProduct';
import Header from '../../Components/Header/Header';
import HeaderLower from '../../Components/HeaderLower/HeaderLower';
import otros from '../../assets/OTROS-1920x793.png';
import otrosMobile from '../../assets/OTROS-1920x793.png';


export default function Otros() {
    const opciones = ['Muebles', 'Kids', 'Pets'];
    return (
        <div>
            <Header />
            <HeaderLower />
            <Category title={`Otros`} img={otros} imgMobile={otrosMobile} />
            <CategoryCard title={`Otros`} />
            <MainCard opciones={opciones} category={'OTROS'} />
            <FeaturedProduct />
        </div>
    )
}
