
import React, { useEffect } from 'react';
import Header from '../../Components/Header/Header';
import HeaderLower from '../../Components/HeaderLower/HeaderLower';
import Entrega from './Preguntas/entregas';
import { useState } from 'react';
import Promocion from './Preguntas/promocion';
import Tienda from './Preguntas/tiendas';
import Cambios from './Preguntas/cambios';
import Cuidado from './Preguntas/cuidados';
import Pedido from './Preguntas/pedidos';
import About from './Preguntas/about';
import menuImg from '../../assets/menuWhite.svg';
import Medios from './Medios/medios';
import Metodos from './Medios/metodos';
import Retiro from './Medios/retiro';
import Terminos from './Terminos';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Politicas from './Preguntas/politicas';
import PoliticasDevolucion from './Preguntas/politicas-devolucion';
import PoliticasEnvio from './Preguntas/politicas-envio';

const Atencion = () => {
    const { inicio } = useParams();
    const [question, setQuestion] = useState(inicio || 'entrega');
    const [menu, setMenu] = useState(false);
    const [titulo, setTitulo] = useState('Preguntas Frecuentesss');
    useEffect(() => {
        const changeTitle = () => {
            switch (question || inicio) {
                case 'cuidado':
                    setTitulo('Cuidado de los Productos');
                    break;
                case 'medios':
                    setTitulo('Medios de Pago');
                    break;
                case 'metodos':
                    setTitulo('Medios de Envío');
                    break;
                case 'retiro':
                    setTitulo('Retiro en Depósito');
                    break;
                case 'politicas-devolucion':
                    setTitulo('Políticas de Devolución');
                    break;
                case 'politicas-envio':
                    setTitulo('Políticas de Envío');
                    break;
                case 'politicas':
                    setTitulo('Políticas de Garantía');
                    break;
                case 'terminos':
                    setTitulo('Términos y Condiciones');
                    break;
                default:
                    setTitulo('Preguntas Frecuentes');
            }
        }
        changeTitle();
    }, [inicio, question]);


    return (
        <div>
            <Header />
            <HeaderLower />
            <div className='pt-20'>
                <div className='bg-[#EFEFED] w-full lg:pt-10 pt-7'>
                    <div className='2xl:pl-[14%] 2xl:pr-[10%]'>
                        <p className='text-xs text-left lg:ml-16 ml-8 font-semibold'><a className='font-normal' href="/">Home {'>'}</a> {titulo}</p>
                        <p className='text-5xl my-10 lg:pb-20 pb-10'>
                            Atención al cliente
                        </p>
                    </div>
                </div>
                <div className='grid grid-cols-4'>
                    <div className='lg:flex lg:flex-col col-span-1 hidden text-[13px] text-left border-t-2 mx-7'>
                        <div className='mr-16'>
                            <p className='mt-5 lg:ml-0 ml-5 font-semibold'>Preguntas Frecuentes</p>
                            <div className='mr-2.5 border-b-[0.5px] border-black'></div>
                        </div>
                        <div className='ml-10 mt-4 flex flex-col items-start'>
                            <button onClick={() => setQuestion('entrega')} className={`${question == "entrega" ? 'font-semibold' : ''}`}>Entregas a Domicilio</button>
                            <button onClick={() => setQuestion('promocion')} className={`${question == "promocion" ? 'font-semibold' : ''} mt-4`}>Promociones y Pagos</button>
                            <button onClick={() => setQuestion('tienda')} className={`${question == "tienda" ? 'font-semibold' : ''} mt-4`}>Tienda Online</button>
                            <button onClick={() => setQuestion('cambios')} className={`${question == "cambios" ? 'font-semibold' : ''} mt-4`}>Cambios y Devoluciones</button>
                            <button onClick={() => setQuestion('cuidados')} className={`${question == "cuidados" ? 'font-semibold' : ''} mt-4`}>Cuidados</button>
                            <button onClick={() => setQuestion('pedidos')} className={`${question == "pedidos" ? 'font-semibold' : ''} mt-4`}>Pedidos Online</button>
                            <button onClick={() => setQuestion('about')} className={`${question == "about" ? 'font-semibold' : ''} mt-4`}>Sobre los Productos</button>
                        </div>
                        <div className='border-y-2 mt-5'>
                            <button onClick={() => setQuestion('cuidado')} className={`${question == "cuidado" ? 'font-semibold' : ''} my-4`}>Cuidado de los Productos</button>
                        </div>
                        <div className='border-b-2 flex flex-col text-left items-start'>
                            <button onClick={() => setQuestion('medios')} className={`${question == "medios" ? 'font-semibold' : ''} mb-2 mt-5`}>Medios de Pago</button>
                            <button onClick={() => setQuestion('metodos')} className={`${question == "metodos" ? 'font-semibold' : ''} my-2 mr-5`}>Métodos de Envío</button>
                            <button onClick={() => setQuestion('retiro')} className={`${question == "retiro" ? 'font-semibold' : ''} mt-2 mb-5`}>Retiro de Depósito</button>
                        </div>
                        <div className='border-b-2 flex flex-col text-left items-start'>
                            <button onClick={() => setQuestion('politicas-devolucion')} className={`${question == "politicas-devolucion" ? 'font-semibold' : ''} mb-2 mt-5`}>Políticas de Devolución y Cambio</button>
                            <button onClick={() => setQuestion('politicas-envio')} className={`${question == "politicas-envio" ? 'font-semibold' : ''} my-2 mr-5`}>Políticas de Envíos</button>
                            <button onClick={() => setQuestion('politicas')} className={`${question == "politicas" ? 'font-semibold' : ''} mt-2 mb-5`}>Políticas de Garantía</button>
                        </div>
                        <div className='border-b-2 mb-5 flex flex-col text-left items-start'>
                            <button onClick={() => setQuestion('terminos')} className={`${question == "terminos" ? 'font-semibold' : ''} my-4`}>Términos y Condiciones</button>
                        </div>
                    </div>
                    <div className='lg:col-span-3 col-span-4 lg:ml-5'>
                        {!menu ? <button onClick={() => setMenu(!menu)} className="w-8 ml-5 mb-5 flex justify-start lg:hidden">
                            <img src={menuImg} className='w-8' alt="menu" />
                        </button> : ''}
                        {menu &&
                            <div className='lg:hidden bg-white z-[50000] border-t-2 top-0 border-black h-screen w-full right-0 fixed'>
                                <button onClick={() => setMenu(!menu)} className="w-8 ml-5 mb-2 mt-3 flex justify-start lg:hidden">
                                    <p className='text-3xl ml-2'>X</p>
                                </button>
                                <div className='mx-5'>
                                    <div className='flex flex-col justify-start px-5 items-start'>
                                        <button onClick={() => { setQuestion('entrega'); setMenu(false) }} className={`${question == "entrega" ? 'font-semibold' : ''}`}>Entregas a Domicilio</button>
                                        <button onClick={() => { setQuestion('promocion'); setMenu(false) }} className={`${question == "promocion" ? 'font-semibold' : ''} mt-3`}>Promociones y Pagos</button>
                                        <button onClick={() => { setQuestion('tienda'); setMenu(false) }} className={`${question == "tienda" ? 'font-semibold' : ''} mt-3`}>Tienda Online</button>
                                        <button onClick={() => { setQuestion('cambios'); setMenu(false) }} className={`${question == "cambios" ? 'font-semibold' : ''} mt-3`}>Cambios y Devoluciones</button>
                                        <button onClick={() => { setQuestion('cuidados'); setMenu(false) }} className={`${question == "cuidados" ? 'font-semibold' : ''} mt-3`}>Cuidados</button>
                                        <button onClick={() => { setQuestion('pedidos'); setMenu(false) }} className={`${question == "pedidos" ? 'font-semibold' : ''} mt-3`}>Pedidos Online</button>
                                        <button onClick={() => { setQuestion('about'); setMenu(false) }} className={`${question == "about" ? 'font-semibold' : ''} mt-3`}>Sobre los Productos</button>
                                        <div className='border-b-2 border w-full'></div>
                                        <button onClick={() => { setQuestion('cuidado'); setMenu(false) }} className={`${question == "cuidado" ? 'font-semibold' : ''} my-3`}>Cuidado de los Productos</button>
                                        <div className='border-b-2 border w-full'></div>
                                        <button onClick={() => { setQuestion('medios'); setMenu(false) }} className={`${question == "medios" ? 'font-semibold' : ''} mb-2 mt-3`}>Medios de Pago</button>
                                        <button onClick={() => { setQuestion('metodos'); setMenu(false) }} className={`${question == "metodos" ? 'font-semibold' : ''} mt-2 mr-3`}>Métodos de Envío</button>
                                        <button onClick={() => { setQuestion('retiro'); setMenu(false) }} className={`${question == "retiro" ? 'font-semibold' : ''} mt-2 mb-3`}>Retiro de Depósito</button>
                                        <div className='border-b-2 border w-full'></div>
                                        <button onClick={() => { setQuestion('politicas-devolucion'); setMenu(false) }} className={`${question == "politicas-devolucion" ? 'font-semibold' : ''} mb-2 mt-3`}>Políticas de Devolución y Cambio</button>
                                        <button onClick={() => { setQuestion('politicas-envio'); setMenu(false) }} className={`${question == "politicas-envio" ? 'font-semibold' : ''} my-2 mr-3`}>Políticas de Envíos</button>
                                        <button onClick={() => { setQuestion('politicas'); setMenu(false) }} className={`${question == "politicas" ? 'font-semibold' : ''} mt-2 mb-3`}>Políticas de Garantía</button>
                                        <div className='border-b-2 border w-full'></div>
                                        <button onClick={() => { setQuestion('terminos'); setMenu(false) }} className={`${question == "terminos" ? 'font-semibold' : ''} mt-3`}>Términos y Condiciones</button>
                                    </div>
                                </div>
                            </div>
                        }
                        <p className='text-left lg:pl-0 pl-5 text-2xl'>{titulo}</p>
                        {question === 'entrega' && <Entrega />}
                        {question === 'politicas-devolucion' && <PoliticasDevolucion />}
                        {question === 'politicas-envio' && <PoliticasEnvio />}
                        {question === 'promocion' && <Promocion />}
                        {question === 'politicas' && <Politicas />}
                        {question === 'tienda' && <Tienda />}
                        {question === 'cambios' && <Cambios />}
                        {question === 'cuidados' && <Cuidado />}
                        {question === 'cuidado' && <Cuidado />}
                        {question === 'pedidos' && <Pedido />}
                        {question === 'about' && <About />}
                        {question === 'medios' && <Medios />}
                        {question === 'metodos' && <Metodos />}
                        {question === 'retiro' && <Retiro />}
                        {question === 'terminos' && <Terminos />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Atencion;
