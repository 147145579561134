import Category from '../../Components/Category/Category';
import CategoryCard from '../../Components/CategoryCard/CategoryCard';
import MainCard from '../../Components/MainCard/MainCard';
import FeaturedProduct from '../../Components/FeaturedProduct/FeaturedProduct';
import Header from '../../Components/Header/Header';
import HeaderLower from '../../Components/HeaderLower/HeaderLower';
import sillas from '../../assets/SILLAS-1920x793.jpg';
import sillasMobile from '../../assets/SILLAS-mobile@2x.png';

export default function Sillas() {
    const opciones = ['Polipropileno', 'Policarbonato', 'Transparentes', 'Madera', 'Metálicas', 'Tapizadas', 'Combinada', 'Banquetas', 'de Escritorio']
    return (
        <div>
            <Header />
            <HeaderLower />
            <Category title={`Sillas`} img={sillas} imgMobile={sillasMobile} />
            <CategoryCard title={`Sillas`} />
            <MainCard opciones={opciones} category={'SILLAS'} />
            <FeaturedProduct />
        </div>
    )
}
