import React from 'react';

const Cuidado = () => {
    return (
        <div className='text-sm text-left lg:w-3/5 lg:px-0 px-5'>
            <p className='font-semibold text-lg'>Cómo cuidar cada tipo de Producto</p>
            <p className='font-semibold mt-5 mb-1'>Maderas:</p>
            <div className='lg:ml-14 ml-5 text-[12px]'>
                <p>• Limpieza en seco. Utilizar paño suave o fibra para quitar polvo superficial. </p>
                <p>• En caso de derramar líquidos, secar rápidamente con papel absorbente o paño seco. </p>
                <p>• Para manchas específicas, utilizar un paño húmedo en agua y jabón neutro para remover; no frotar. </p>
                <p>• Evitar productos abrasivos que contengan amoníaco, silicona o alcohol. </p>
                <p>• Su uso en espacios húmedos puede provocar variaciones con el paso del tiempo. </p>
                <p>• Apto para interiores o galerías. No colocar a la intemperie (Exceptuado el mobiliario exclusivo de exteriores) </p>
                <p>• Utilizar siempre posavasos, posafuentes, manteles, etc. para proteger la superficie de posibles marcas.</p>
                <p>• Evitar la exposición de luz directa para mejor preservación. </p>
                <p>• No exponer el producto a fuentes de calor.</p>
            </div>
            <p className='font-semibold mt-5 mb-1'>Metales:</p>
            <div className='lg:ml-14 ml-5 text-[12px]'>
                <p>• Se recomienda limpieza diaria con paño suave o fibra para quitar el polvo superficial. </p>
                <p>• Periódicamente limpiar con paño húmedo con agua tibia y jabón neutro. Secar. </p>
                <p>• En caso de derramar líquidos, secar rápidamente con papel absorbente o paño seco. </p>
                <p>• No utilizar paños, cepillos, esponjas o limpiadores con fibras ásperas ya que es un material sensible a las rayaduras.</p>
                <p>• El uso de ceras especiales para pulido y brillo están permitidas. Evitar productos abrasivos. </p>
                <p>• Apto para interiores o galerías. No colocar a la intemperie. </p>
                <p>• Su uso en espacios húmedos puede provocar variaciones con el paso del tiempo. </p>
                <p>• Utilizar siempre posavasos, posafuentes, manteles, etc. para proteger la superficie de posibles marcas.</p>
            </div>
            <p className='font-semibold mt-5 mb-1'>Ratán:</p>
            <div className='lg:ml-14 ml-5 text-[12px]'>
                <p>• Se recomienda limpieza diaria con paño suave o fibra para quitar el polvo superficial. Limpiar hendiduras con cepillo de cerdas suave. </p>
                <p>• En caso de derramar líquidos, secar rápidamente con papel absorbente o paño seco. </p>
                <p>• Para manchas específicas, utilizar espuma de jabón neutro con la menor cantidad de agua posible para remover; no frotar. Secar posteriormente.</p>
                <p>• Evitar productos abrasivos. </p>
                <p>• Apto para interiores o galerías. No colocar a la intemperie. </p>
                <p>• Su uso en espacios húmedos puede provocar variaciones con el paso del tiempo. </p>
                <p>• Utilizar siempre posavasos, posafuentes, manteles, etc. para proteger la superficie de posibles marcas. </p>
                <p>• Evitar contacto con el agua y la exposición de luz directa para mejor preservación. </p>
                <p>• No exponer el producto a fuentes de calor.</p>
            </div>
            <p className='font-semibold mt-5 mb-1'>Mármol:</p>
            <div className='lg:ml-14 ml-5 text-[12px]'>
                <p>• Se recomienda limpieza diaria con paño suave y húmedo para quitar el polvo superficial.</p>
                <p>• En caso de derramar líquidos, secar rápidamente con papel absorbente o paño seco.</p>
                <p>• Para manchas específicas, utilizar agua tibia y una gota de jabón neutro para remover. Secar bien para evitar pérdida de brillo y manchas de líquidos.</p>
                <p>• Evitar productos abrasivos. No utilizar limpiadores con ácido clorhídrico ya que altera su color y brillo. </p>
                <p>• Utilizar siempre posavasos, posafuentes, manteles, etc. para proteger la superficie de posibles marcas.</p>
            </div>
            <p className='font-semibold mt-5 mb-1'>Cristal y Vidrio:</p>
            <div className='lg:ml-14 ml-5 text-[12px]'>
                <p>• Se recomienda limpieza diaria con paño suave y húmedo para quitar el polvo superficial. </p>
                <p>• En caso de derramar líquidos, secar rápidamente con papel absorbente o paño seco. </p>
                <p>• Para manchas específicas, utilizar agua tibia y una gota de jabón neutro para remover. Secar bien para evitar pérdida de brillo y manchas de líquidos. </p>
                <p>• Evitar productos abrasivos. No utilizar limpiadores con ácido clorhídrico ya que altera su color y brillo. </p>
                <p>• Utilizar siempre posavasos, posafuentes, manteles, etc. para proteger la superficie de posibles marcas. </p>
                <p>• Vidrio mercurizado: evitar el contacto directo con el agua.</p>
            </div>
            <p className='font-semibold mt-5 mb-1'>Caireles Luminaria:</p>
            <div className='lg:ml-14 ml-5 text-[12px]'>
                <p>• Manipular con cuidado. En caso de movimiento o limpieza, desmontar piezas en su totalidad. </p>
                <p>• Por seguridad, desmontar hasta 3 piezas por vez para evitar el desequilibrio de la lámpara. </p>
                <p>• Se recomienda limpieza diaria con paño suave para quitar el polvo superficial. (No es necesario remover sus piezas) </p>
                <p>• Periódicamente limpiar con detergente o jabón neutro y bastante agua para remover cualquier tipo de suciedad. Secar con paño suave para evitar rayaduras. </p>
                <p>• Secar a la perfección cada pieza antes de volver a montar. </p>
                <p>• Vidrio mercurizado: evitar el contacto directo con el agua.</p>
            </div>
            <p className='font-semibold mt-5 mb-1'>Resina y Plástico:</p>
            <div className='lg:ml-14 ml-5 text-[12px]'>
                <p>• Se recomienda limpiar periódicamente limpiar con paño de fibra húmedo con agua tibia y jabón neutro o con 70% agua y 30% alcohol. Secar. </p>
                <p>• NO FROTAR ni utilizar limpiadores con ácido clorhídrico ya que altera su color y brillo dejando manchas irrecuperables. </p>
                <p>• En caso de derramar líquidos, secar rápidamente con papel absorbente o paño de fibra seco. </p>
                <p>• No utilizar paños, cepillos, esponjas o limpiadores con fibras ásperas ya que es un material sensible a las rayaduras. </p>
                <p>• Evitar productos abrasivos. </p>
                <p>• Apto para interiores o galerías. No colocar a la intemperie (Exceptuado el mobiliario exclusivo de exteriores) </p>
                <p>• Si se utiliza en galerías cercanas a piscinas, evitar sentarse con trajes de baño húmedos o mojados ya que el cloro puede manchar irremediablemente el policarbonato.</p>
            </div>
            <p className='font-semibold mt-5 mb-1'>Acrílico:</p>
            <div className='lg:ml-14 ml-5 text-[12px]'>
                <p>• Se recomienda limpiar periódicamente limpiar con paño de fibra húmedo con agua tibia y jabón neutro o con 70% agua y 30% alcohol. Secar.</p>
                <p>• NO FROTAR ni utilizar limpiadores con ácido clorhídrico ya que altera su color y brillo dejando manchas irrecuperables.</p>
                <p>• En caso de derramar líquidos, secar rápidamente con papel absorbente o paño de fibra seco. </p>
                <p>• No utilizar paños, cepillos, esponjas o limpiadores con fibras ásperas ya que es un material sensible a las rayaduras. </p>
                <p>• Evitar productos abrasivos. </p>
                <p>• Apto para interiores o galerías. No colocar a la intemperie (Exceptuado el mobiliario exclusivo de exteriores) </p>
                <p>• Utilizar siempre posavasos, posafuentes, manteles, etc. para proteger la superficie de posibles marcas.</p>
            </div>
            <p className='font-semibold mt-5 mb-1'>Cuero:</p>
            <div className='lg:ml-14 ml-5 text-[12px]'>
                <p>• Limpieza en seco. Utilizar paño suave o gamuza para quitar polvo superficial. </p>
                <p>• En caso de derramar líquidos, secar rápidamente con papel absorbente o paño seco. </p>
                <p>• Para manchas específicas/puntuales, utilizar un paño húmedo en agua y jabón neutro para remover; frotar con cuidado de afuera hacia adentro y luego secar rápidamente. </p>
                <p>• No utilizar aspiradoras ni cepillos duros. </p>
                <p>• No utilizar CIF ni cremas limpiadoras abrasivas </p>
                <p>• Para mejor conservar, humectar con crema incolora específica para cueros y quitar excedente. </p>
                <p>• No utilizar productos abrasivos ya que pueden dañar la terminación del producto. </p>
                <p>• Evitar la exposición de luz directa para mejor preservación. </p>
                <p>• No exponer el producto a fuentes de calor.</p>
            </div>
            <p className='font-semibold mt-5 mb-1'>Terciopelo, Pana y Velvet:</p>
            <div className='lg:ml-14 ml-5 text-[12px]'>
                <p>• Limpieza en seco. Utilizar aspiradora o cepillo suave para quitar el polvo superficial. (Siempre en el sentido del tejido) </p>
                <p>• En caso de derramar líquidos, secar rápidamente con papel absorbente o paño seco. </p>
                <p>• Para manchas específicas/puntuales, utilizar un paño húmedo en agua y jabón neutro para remover; dejar secar y pasar cepillo suave. No frotar.</p>
                <p>• No utilizar productos abrasivos ya que pueden dañar la terminación del producto. </p>
                <p>• Evitar la exposición de luz directa para mejor preservación. </p>
                <p>• No exponer el producto a fuentes de calor.</p>
            </div>
            <p className='font-semibold mt-5 mb-1'>Lino y otros géneros:</p>
            <div className='lg:ml-14 ml-5 text-[12px]'>
                <p>• Limpieza en seco. Utilizar aspiradora o cepillo suave para quitar el polvo superficial. </p>
                <p>• En caso de fundas, lavar eventualmente con agua fría únicamente. Evitar centrifugado. No secar al sol, ni planchar. </p>
                <p>• En caso de derramar líquidos, secar rápidamente con papel absorbente o paño seco. </p>
                <p>• Para manchas específicas/puntuales, utilizar un paño húmedo en agua y jabón neutro para remover; dejar secar. No frotar. </p>
                <p>• No utilizar productos abrasivos ya que pueden dañar la terminación del producto. </p>
                <p>• Evitar la exposición de luz directa para mejor preservación. </p>
                <p>• No exponer el producto a fuentes de calor.</p>
            </div>
            <div className='mt-10 border-b-2 mb-32'></div>
        </div>
    );
};

export default Cuidado;