import Category from '../../Components/Category/Category';
import CategoryCard from '../../Components/CategoryCard/CategoryCard';
import MainCard from '../../Components/MainCard/MainCard';
import FeaturedProduct from '../../Components/FeaturedProduct/FeaturedProduct';
import Header from '../../Components/Header/Header';
import HeaderLower from '../../Components/HeaderLower/HeaderLower';
import puffs from '../../assets/PUFFS-SILLONES-1920x793.jpg';
import puffsMobile from '../../assets/SILLONES-mobile@2x.png';

export default function Puffs() {
    const opciones = ['Puff', 'Sillones']
    return (
        <div>
            <Header />
            <HeaderLower />
            <Category title={`Puff's & Sillones`} img={puffs} imgMobile={puffsMobile} />
            <CategoryCard title={`Puff's & Sillones`} />
            <MainCard opciones={opciones} category={'SILLONES&PUFF'} />
            <FeaturedProduct />
        </div>
    )
}