import React from 'react';

const Metodos = () => {
    return (
        <div className='text-sm text-left lg:w-3/5 lg:px-0 px-5'>
            <p className='font-semibold mt-10 mb-5'>
                ¿Cómo puedo saber dónde está mi compra?
            </p>
            <p>
                Para saber el estado de tu compra podés ingresar a tu cuenta o accediendo al link de seguimiento que te enviamos a tu casilla de correo electrónico. En caso de no haber recibido esta información podés contactarnos por mail a hola@sentate-conestilo.com o a través de WhatsApp al +54 9 11 3261 9277 de lunes a viernes de 9 a 18 hs. Recordá tener a mano el número de DNI del/la comprador/a o el número de tu pedido.
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Cuál es el costo de envío?
            </p>
            <div className='flex flex-col'>
                <div className='flex flex-row'>
                    <span className='mx-2'>•</span><span className='mb-3'>Si elegís envío a domicilio en CABA: Con una compra superior al monto que informa la Tienda Web, el envío es gratis dentro de CABA (*), por compras inferiores al monto que informa la Tienda Web y otros destinos el costo del envió podes verlo en el check out, con tu código postal (en ambos casos no incluye subida por escaleras, ni exteriores, ni ingreso al lugar) previa coordinación con el sector de envíos de acuerdo con los tiempos de entrega estipulados para este producto.</span>
                </div>
                <div className='flex flex-row'>
                    <span className='mx-2'>•</span><span className='mb-3'>Si elegís envío a domicilio al Interior: El envío es gratis hasta la dirección del servicio de transporte de tu preferencia dentro de CABA () por compras superiores al monto que informa la Tienda Web, por compras inferiores al monto que informa la Tienda Web (), comunícate con Nosotros a través de WhatsApp y consúltanos 11 3261-9277. El costo del envío desde el servicio de transporte hasta el domicilio es a cargo del cliente. (Recordá informarle al equipo de logística el nombre y dirección del expreso)</span>
                </div>
                <div className='flex flex-row'>
                    <span className='mx-2'>•</span><span className='mb-3'>Si elegís retiro en una de nuestras Sucursales: Retiro SIN CARGO por Nuestro deposito ubicado en Barracas, seleccionada en el check out en 5 días hábiles una vez acreditado el pago. Retiras presentando la factura de tu compra con coordinación previa..</span>
                </div>
                <p>(*) El monto mínimo de compra para envío Gratis en CABA se calcula sobre el precio de transferencia.</p>
            </div>
            <p className='font-semibold mt-10 mb-5'>
                ¿Cómo realizo el seguimiento?
            </p>
            <p>
                Lo verás reflejado en el mail que te indica: hola@sentate-conestilo.com el cual recibirás en los próximos minutos luego de haber realizado tu compra. En caso de no haberlo recibido podes obtenerlo contactándonos a través de WhatsApp al +54 9 11 3261 9277 de lunes a viernes de 9 a 18 hs. Recordá tener a mano el número de DNI del/la comprador/a o el número de tu pedido.
            </p>
            <p className='font-semibold mt-10 mb-5'>¿Cuál es el costo de envío?</p>
            <p className='flex flex-col'>
                <div className='flex flex-row'>
                    <span className='mx-2'>•</span><span className='mb-3'>Si elegís envío a domicilio en CABA: Con una compra superior al monto  que informa la Tienda Web, el envío es gratis dentro de CABA, por compras inferiores al monto que informa la Tienda Web y otros destinos el costo del envió podes verlo en el check out, con tu código postal (en ambos casos no incluye subida por escaleras, ni exteriores, ni ingreso al lugar) previa coordinación con el sector de envíos de acuerdo con los tiempos de entrega estipulados para este producto.</span>
                </div>
                <div className='flex flex-row'>
                    <span className='mx-2'>•</span><span className='mb-3'>Si elegís envío a domicilio al Interior: El envío es gratis hasta la dirección del servicio de transporte de tu preferencia, dentro de CABA por compras superiores al monto  que informa la Tienda Web, por compras inferiores al monto  que informa la Tienda Web, comunícate con Nosotros a través de WhatsApp y consúltanos 11 3261-9277. El costo del envío desde el servicio de transporte hasta el domicilio es a cargo del cliente. (Recordá informarle al equipo de logística el nombre y dirección del expreso)</span>
                </div>
                <div className='flex flex-row'>
                    <span className='mx-2'>•</span><span className='mb-3'>Si elegís retiro en una de nuestras Sucursales: Retiro SIN CARGO por Nuestro deposito ubicado en Barracas, seleccionada en el check out en 5 días hábiles una vez acreditado el pago. Retiras presentando la factura de tu compra con coordinación previa.</span>
                </div>
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Tengo que estar presente para recibir mi compra?
            </p>
            <p>
                Si seleccionaste Envío a Domicilio, no es necesario que el/la titular de la compra reciba el pedido, cualquier persona mayor de 18 años podrá recibir tu compra presentando al servicio de entrega responsable su DNI y firmando el correspondiente remito.
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Cuánto demora en llegar mi compra?
            </p>
            <p>
                En CABA y GBA: Recibirás tu pedido al domicilio indicado al momento de la
                compra. El plazo de entrega es de hasta 5 días hábiles, una vez confirmado el
                pago. En el interior del país: Enviaremos tu pedido hasta el transporte dentro de
                CABA solicitado por el cliente, el plazo de entrega al expreso es de hasta 5 días
                hábiles, Sentate con Estilo no tiene injerencia sobre los plazos de entrega del
                expreso, para conocerlos el cliente debe comunicarse con la empresa de
                transporte.  Te recordamos que para eventos especiales como Hot Sale o Cyber
                Monday puede llegar a demorar 72 horas hábiles más de lo estipulado
                anteriormente
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿En qué horarios puedo retirar mi pedido?
            </p>
            <p>Nuestro punto de entrega tiene horarios de L a V de 9:30hs a 14:30hs para
                retirar (previa coordinación con el sector de envíos de acuerdo con los tiempos
                de entrega estipulados para el producto)</p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Qué tengo que hacer si mi entrega se encuentra demorada?
            </p>
            <p>
                Nuestro punto de entrega tiene horarios de L a V de 9:30hs a 14:30hs para
                retirar (previa coordinación con el sector de envíos de acuerdo con los tiempos
                de entrega estipulados para el producto).
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Hacen envíos al interior? ¿Qué transporte utilizan?
            </p>
            <p>
                ¡Sí, llegamos a todo el país! hacemos los envíos a través de una agencia de confianza, lo que nos asegura que no va a haber ningún inconveniente. Esta agencia a su vez trabaja con varios transportes, llegamos a cada rincón de la Argentina. En el momento del envío se decide el transporte más conveniente según la zona, pero pueden consultarnos por WhatsApp antes para más exactitud.
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Qué tengo que hacer si escribí mal mi dirección?
            </p>
            <p>
                <p>Podés escribirnos a hola@sentate-conestilo.com indicándonos los siguientes datos: </p>
                <p className='mt-2 ml-10'>Número de pedido: </p>
                <p className='mt-2 ml-10'>Domicilio de entrega: Calle - Altura - Dpto. </p>
                <p className='mt-2 ml-10'>Localidad - Provincia y Código Postal </p>
                <p className='my-2 ml-10'>Datos Adicionales </p>
                De esta forma podremos enviar los datos corregidos al responsable de la entrega. También podés comunicarte a través de WhatsApp al +54 9 11 3261-9277 de lunes a viernes de 9 a 18 hs. Recordá tener a mano el número de DNI del/la comprador/a o el número de tu pedido.
            </p>
            <div className='mt-10 border-b-2 mb-32'></div>
        </div>
    );
};

export default Metodos;