import './Footer.css';
import logo from '../../assets/sce-logo-horizontal-blanco.svg'
import ig from '../../assets/instagram.svg'
import fb from '../../assets/facebook.svg'
import wsp from '../../assets/whatsapp.svg'
import { useState } from 'react';
import axios from 'axios';

export default function Footer() {
    const [email, setEmail] = useState('');
    const cuerpoCorreo = `<body><div style='text-align:center;'><p>Hola, se contactó el siguiente correo por venta mayorista ${email}</p></div></body>`

    const params = {
        asunto: "Nuevo mensaje Venta mayorista",
        correo: 'hola@sentate-conestilo.com',
        CuerpoCorreo: cuerpoCorreo
    };

    const onClickEmail = async () => {
        await axios.get("https://sistema.depcsuite.com/certificanna/enviarCorreo", {
            params: params,
            headers: {
                "Content-Type": "application/json"
            }
        });
    }

    return (
        <div className='footer-container'>
            <div className="flex flex-row px-10 items-center justify-between 2xl:pl-[14%] 2xl:pr-[12%]">
                <div className='flex flex-col mt-16'>
                    <img className="w-[195.43px] h-[18.01px]" src={logo} alt="Logo" />
                    <span className="text-left text-xs my-3">hola@sentate-conestilo.com</span>
                    <div className="flex flex-row">
                        <a href='https://www.instagram.com/sentate_con_estilo/' target="_blank" rel="noopener noreferrer">
                            <img src={ig} className='h-6' alt="social" />
                        </a>
                        <a href='https://www.facebook.com/profile.php?id=100063771705288&mibextid=WC7FNe&rdid=vDdwEXVSg5Kxwvfq' target="_blank" rel="noopener noreferrer">
                            <img src={fb} className='h-6 mx-4' alt="social" />
                        </a>
                        <a href='https://api.whatsapp.com/send?phone=541132619277' target="_blank" rel="noopener noreferrer">
                            <img src={wsp} className='h-6' alt="social" />
                        </a>
                    </div>
                    <div className='flex flex-row mt-10'>
                        <img className="-ml-0 mr-3 h-16 mt-0.5" src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg" alt="external-qr" />
                        <button onClick={() => window.location.href = '/nosotros?q=arrepentimiento'} className='border border-white h-5 text-[11px] px-4 flex items-center py-3 mt-10'>Botón de arrepentimiento</button>
                    </div>
                </div>
                <div className='text-left flex mt-16 whitespace-nowrap text-[14px] flex-row text-white'>
                    <div className="flex flex-col gap-1.5">
                        <p className='font-bold'>Atención al cliente</p>
                        <a href="/atencion-al-cliente/entrega" className='opacity-90'>Preguntas frecuentes</a>
                        <a href="/atencion-al-cliente/politicas-envio" className='opacity-90'>Políticas de envío</a>
                        <a href="/atencion-al-cliente/politicas-devolucion" className='opacity-90'>Cambios y devoluciones</a>
                        <a href="/atencion-al-cliente/politicas" className='opacity-90'>Garantía</a>
                        <a href="/atencion-al-cliente/tienda" className='opacity-90'>¿Cómo comprar online?</a>
                        <a href="/nosotros" className='font-bold'>Nosotros</a>
                    </div>
                </div>
                <div className='text-left flex mt-16 whitespace-nowrap text-[14px] flex-row text-white'>
                    <div className="flex flex-col gap-1.5">
                        <a href="/atencion-al-cliente/medios" className='opacity-90'>Medios de pago</a>
                        <a href="/atencion-al-cliente/metodos" className='opacity-90'>Métodos de envío</a>
                        <a href="/atencion-al-cliente/retiro" className='opacity-90'>Retiro en depósito</a>
                        <span className='mt-10'>Ventas mayoristas</span>
                        <div className='relative'>
                            <input onChange={(e) => setEmail(e.target.value)} type="text" className='bg-[#1C1C1C] text-base border pr-3 w-72 pl-2 py-2 border-white rounded-sm' placeholder="Dejanos tu mail" />
                            <img onClick={() => onClickEmail()} className='hover:cursor-pointer absolute right-3 top-3.5 w-3' src="https://img.icons8.com/ios-filled/50/FFFFFF/sort-right.png" alt="sort-right" />
                        </div>
                    </div>
                </div>
                <div className='text-left flex mt-16 whitespace-nowrap text-[14px] mr-10 flex-row text-white'>
                    <div className="flex flex-col gap-1.5">
                        <p className='font-bold'>Productos</p>
                        <a href="/sillas" className='opacity-90'>Sillas & Banquetas</a>
                        <a href="/mesas" className='opacity-90'>Mesas</a>
                        <a href="/puffs-sillones" className='opacity-90'>Puffs & Sillones</a>
                        <a href="/deco-iluminaciones" className='opacity-90'>Deco & Iluminación</a>
                        <a href="/exterior" className='opacity-90'>Exterior</a>
                        <a href="/otros" className='opacity-90'>Otros</a>
                    </div>
                </div>
            </div>
            <div className='border-t-2 w-[95%] mx-10 absolute mb-12 bottom-0'></div>
            <div className='text-left absolute bottom-0 ml-10 mb-4 text-[10px]'>
                <span className='opacity-40 '>© 2023 - SCE - Todos los derechos reservados</span>
            </div>
        </div>
    );
}