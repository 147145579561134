import React from 'react';

const PoliticasEnvio = () => {
    return (
        <div className='text-sm text-left lg:w-3/5 lg:px-0 px-5'>
            <p className='font-semibold mt-10 mb-5'>Políticas de Envíos</p>
            <p>
                <p>• La entrega de los productos dentro de CABA es gratis con una compra
                    superior al monto que informa la Tienda Web hasta la puerta del domicilio
                    declarado (puerta del edificio en caso de departamentos). </p>
                <p>• La entrega de los productos incluye el traslado con costo hasta la puerta del
                    domicilio declarado (puerta del edificio en caso de departamentos), en el caso
                    de los envíos al interior del País es hasta la puerta del expreso. Recordá
                    desembalar los productos al recibirlos, para controlar la cantidad y calidad de
                    los mismos. Si querés anular una compra a través del mail hola@sentate-
                    conestilo.com, pero el producto ya fue despachado, deberás pagar
                    adicionalmente el total del costo del retiro. Si cuando te llega el producto
                    rechazas la entrega por una razón ajena a la responsabilidad de Sentate Con
                    Estilo, el costo del envío deberá ser abonado por el comprador. El encargado
                    del despacho no está facultado para armar ni instalar ningún producto. En caso
                    que escoja retiro voluntario de nuestro depósito, el mismo puede realizarse de
                    lunes a viernes de 9:30 a 14:30 hs, previa coordinación con él.</p>
            </p>
            <p className='font-semibold mt-10 mb-5'>
                Costos de Envío
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Cuánto demora en llegar mi compra?
            </p>
            <p>
                En CABA y GBA: Recibirás tu pedido al domicilio indicado al momento de la
                compra. El plazo de entrega es de hasta 5 días hábiles, una vez confirmado el
                pago. En el interior del país: Enviaremos tu pedido hasta el transporte dentro de
                CABA solicitado por el cliente, el plazo de entrega al expreso es de hasta 5 días
                hábiles, Sentate con Estilo no tiene injerencia sobre los plazos de entrega del
                expreso, para conocerlos el cliente debe comunicarse con la empresa de
                transporte.  Te recordamos que para eventos especiales como Hot Sale o Cyber
                Monday puede llegar a demorar 72 horas hábiles más de lo estipulado
                anteriormente
            </p>
            <p>
                <p>• El costo del envió se abona en el momento de la entrega y en efectivo ya que el flete es tercerizado la empresa. </p>
                <p>• El costo de envió se calculará teniendo en cuenta tu ubicación y complejidad en la entrega tales como ascensores, escaleras, balcón, de acuerdo al tipo de producto.</p>
            </p>
            <p className='font-semibold mt-10 mb-5'>
                Envíos al interior
            </p>
            <p>
                • Realizamos envíos al interior a cargo del cliente a través de un expreso
                con que habitualmente trabajamos o través del expreso de su confianza. De
                acuerdo a la tipología del producto (dimensiones y peso) podes optar por el
                envío a través de Andreani, en este caso el pago se debe realizar con
                anterioridad al envío a la empresa a través de un link que te generamos.
                • Consulte el envío de nuestro depósito hasta el expreso ya que depende de
                la cantidad de muebles a despachar, y la zona de su Residencia.
            </p>
            <p>
                • La empresa no se responsabiliza de daños, faltantes o roturas, en el caso
                de que tu producto no llegue en las condiciones deseadas, Ud debe iniciar
                un reclamo con la empresa de transporte, ya que dentro del costo de envió
                Ud esta abonando un seguro y solicitar que se aplique el mismo.
            </p>
            <p>
                • Sentate con Estilo su responsabilidad termina luego que el expreso haya
                recepcionado el producto, temas como plazo de entrega, daños de
                producto, etc, el cliente debe tratarlo directamente con el Expreso.
            </p>
            <p>
                • El costo de los envíos corre por cuenta del comprador.
            </p>
            <p>
                • El cliente tiene la opción de enviar a su transporte a retirar la mercadería
                en nuestro depósito de lunes a viernes de 9:30 am a 14:30 pm.
            </p>
            <p className='font-semibold mt-10 mb-5'>
                Embalaje
            </p>
            <p>
                Sentate con Estilo presenta los productos con un embalaje que protege de daños externos el cual es gratis, es decir no tiene un cargo adicional, excepto para las mesas de producción nacional que requieren de un embalaje especial el cual tiene un costo que deben consultar.
            </p>
            <div className='mt-10 border-b-2 mb-32'></div>
        </div>
    );
};

export default PoliticasEnvio;