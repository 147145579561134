import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CardDetalles from './cardDetalles/cardDetalles';
import { useDispatch, useSelector } from 'react-redux';
import { getMoneyChange } from '../../../../redux/actions';
import cancelar from '../../../../assets/close.svg';

const Envios = () => {
  const [loading, setLoading] = useState(true);
  const [datos, setDatos] = useState([]);
  const [ver, setVer] = useState(false);
  const dolar = useSelector((state) => state.dolarPrice);
  const dispatch = useDispatch();

  const [detallesVisibles, setDetallesVisibles] = useState(null);
  useEffect(() => {
    const conseguirPedidos = async () => {
      const token = localStorage.getItem('token');
      try {
        const res = await axios.post(
          'https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/mis-pedidos',
          {
            httpMethod: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            queryStringParameters: {},
            pathParameters: {},
            body: JSON.stringify({ token }),
            isBase64Encoded: false,
          }
        );
        const response = JSON.parse(res.data.body)
        if (response.error) {
          window.localStorage.removeItem('token')
          window.location.href = '/ingresar'
        }
        setDatos(response);
        setLoading(false);
      } catch (error) {
        console.error('Error al conseguir los pedidos:', error);
        setLoading(false);
      }
    };
    dispatch(getMoneyChange())
    conseguirPedidos();
  }, []);

  if (loading) {
    return <div>Cargando historial de compras...</div>;
  }

  return (
    <div className='pb-4 lg:pr-64 ml-3 mb-60'>
      <p className='border-b-2 mb-2 text-left'>
        Historial de compras {ver && detallesVisibles && `- Pedido #${detallesVisibles}`}
      </p>

      <div className='w-full text-sm text-left overflow-x-auto max-w-full-md mx-auto'>
        <table className='w-full'>
          <thead>
            <tr className='whitespace-nowrap'>
              <th className='font-normal opacity-90'>Fecha</th>
              <th className='font-normal opacity-90 ml-4'>Número de pedido</th>
              <th className='font-normal opacity-90 ml-4'>Importe</th>
              <th className='font-normal opacity-90 ml-4'>Estado</th>
              <th className='font-normal opacity-90 ml-4'>Comprobantes</th>
              <th className='font-normal opacity-90 ml-4'>Detalles</th>
              <th className='font-normal opacity-90 ml-4'>Cancelar</th>
            </tr>
          </thead>
          <tbody>
            {[...datos].reverse().map((item) => {
              const hora = item.fecha.split('T')[1].split('.')[0].split(':').slice(0, 2).join(':');
              return (
                <React.Fragment key={item.idpedido}>
                  <tr className='whitespace-nowrap'>
                    <td>{new Date(item.fecha).toLocaleDateString('es-AR') + ' ' + hora}</td>
                    <td className='ml-4'>#{item.idpedido}</td>
                    <td className='ml-4'>{Number(item.total).toLocaleString("es-AR", {
                      style: "currency",
                      currency: "ARS",
                      maximumFractionDigits: 0,
                    })}</td>
                    <td className='ml-4'>{item.estado}</td>
                    <td className='ml-4'>Descargar</td>
                    <td
                      onClick={() => {
                        if (detallesVisibles === item.idpedido && ver) {
                          setVer(false);
                          setDetallesVisibles(null);
                        } else {
                          setDetallesVisibles(item.idpedido);
                          setVer(true);
                        }
                      }}
                      className='hover:cursor-pointer ml-4'
                    >
                      {detallesVisibles === item.idpedido && ver ? 'Ocultar' : 'Ver'}
                    </td>
                    <td className='text-center ml-4'>
                      <img
                        src={cancelar}
                        className='w-2.5 mt-1.5 hover:cursor-pointer'
                      />
                    </td>
                  </tr>
                  {detallesVisibles === item.idpedido && ver && (
                    <tr key={`detalles-${item.idpedido}`} className='whitespace-nowrap'>
                      <td colSpan="7">
                        <CardDetalles item={item} />
                        <div className="mt-3 border-b-[0.5px]"></div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>

  );
};

export default Envios;
