import axios from "axios";

//Acá se les pega a los endpoint para guardarlos en los estados
const URL_API = 'https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com';
//se exporta y utiliza así para el switch del reducer, es una buena práctica pero no abosulatamente necesaria
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_DESTACADAS = 'GET_DESTACADAS';
export const GET_IMPORTS = 'GET_IMPORTS';
export const GET_CART_SHOP = 'GET_CART_SHOP';
export const GET_FILTER = 'GET_FILTER';
export const DELETE_FILTER = 'DELETE_FILTER';
export const GET_PRODUCT_PAGE = 'GET_PRODUCT_PAGE';
export const GET_REGISTER = 'GET_REGISTER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const GET_FAVORITOS = 'GET_FAVORITOS';
export const GET_CHANGE_DOLAR = 'GET_CHANGE_DOLAR';
export const GET_RELACIONADOS = 'GET_RELACIONADOS';
export const GET_BUSCADOR = 'GET_BUSCADOR';
export const GET_CARRITO = 'GET_CARRITO';
export const CHANGE_PRODUCT_QUANTITY = 'CHANGE_PRODUCT_QUANTITY';

const data = {
    httpMethod: "POST",
    headers: {
        "Content-Type": "application/json",
    },
    queryStringParameters: {},
    pathParameters: {},
    body: "",
    isBase64Encoded: false,
}

export const loginSuccess = (token) => {
    return {
        type: LOGIN_SUCCESS,
        payload: token
    };
};

export const logout = () => {
    return {
        type: LOGOUT
    };
};

export const loginFailure = (error) => {
    return {
        type: LOGIN_FAILURE,
        payload: error
    };
};

//Esto es igual para todos las action, el payload el dispatch se usa para dispachar la acción
//y el payload es lo que se guarda en el estado asignado en el reducer
export function getProduct(tipoProducto, tipoArray, category, setLoading) {
    function formatString(str) {
        return str?.split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }
    const formattedStringArray = tipoProducto?.map(item => formatString(item?.toLowerCase()));

    const arraySinFiltro = formattedStringArray[0] == undefined ? [] : formattedStringArray;
    const array = arraySinFiltro.filter(item => item != 'Todos');
    return async dispatch => {
        try {
            const response = await axios.post(`${URL_API}/prod/sce-productos`, {
                httpMethod: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                queryStringParameters: {},
                pathParameters: {},
                body: JSON.stringify({
                    tipoProducto: array,
                }),
                isBase64Encoded: false,
            });
            if (response.data && setLoading) {
                setLoading(false);
            }
            const data = JSON.parse(response.data.body);
            if (!category || category == 'shop') {
                return dispatch({
                    type: GET_PRODUCT,
                    payload: data
                });
            }
            dispatch({
                type: GET_PRODUCT,
                payload: JSON.parse(response.data.body).filter((item) => item.categorias?.includes(category))
            });
        } catch (error) {
            console.error('Error during request:', error);
        }
    }
}

export function getProductPage(id) {
    return async dispatch => {
        try {
            const apiResponse = await axios.post(`${URL_API}/prod/sce-productos`, data);
            const response = JSON.parse(apiResponse.data.body).filter(item => item.idproducto == id);
            return dispatch({
                type: GET_PRODUCT_PAGE,
                payload: response
            });
        } catch (error) {
            console.error('Error during request:', error);
        }
    }
}

export function getProductosRelacionados(id) {
    return async dispatch => {
        try {
            const apiResponse = await axios.post(`https://5fmuobow2m.execute-api.us-east-1.amazonaws.com/prod/productos-relacionado`, {
                httpMethod: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                queryStringParameters: {},
                pathParameters: {},
                body: JSON.stringify({
                    idproducto: id,
                }),
                isBase64Encoded: false,
            });
            const response = JSON.parse(apiResponse.data.body);;
            return dispatch({
                type: GET_RELACIONADOS,
                payload: response
            });
        } catch (error) {
            console.error('Error during request:', error);
        }
    }
}

export function getFavoritos(token) {
    return async dispatch => {
        try {
            const response = await axios.post(`https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/conseguir-favoritos`, {
                httpMethod: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                queryStringParameters: {},
                pathParameters: {},
                body: JSON.stringify(token),
                isBase64Encoded: false,
            });
            const data = JSON.parse(response.data.body)
            if (data.error) {
                window.localStorage.removeItem('token')
                window.location.href = '/ingresar'
            }
            return dispatch({
                type: GET_FAVORITOS,
                payload: data
            });
        } catch (error) {
            console.error('Error during request:', error);
        }
    }
}

export function getBuscador(nombre, loading) {
    return async dispatch => {
        try {
            const response = await axios.post(`https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/buscador`, {
                httpMethod: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                queryStringParameters: {},
                pathParameters: {},
                body: JSON.stringify({ busqueda: nombre }),
                isBase64Encoded: false,
            })
            loading(true);
            return dispatch({
                type: GET_BUSCADOR,
                payload: JSON.parse(response.data.body)
            });
        } catch (error) {
            console.error('Error during request:', error);
        }
    }
}

export function getMoneyChange(loading) {
    return async dispatch => {
        try {
            const response = await axios.get(`https://5fmuobow2m.execute-api.us-east-1.amazonaws.com/prod/get-datos-moneda`)
            const precioArray = JSON.parse(response.data.body);
            const precioDolar = precioArray[0].importeARS;
            if (loading) {
                loading(false);
            }
            return dispatch({
                type: GET_CHANGE_DOLAR,
                payload: precioDolar
            });
        } catch (error) {
            console.error('Error during request:', error);
        }
    }
}

export function getCarrito(token, reedirect = false, setLoading) {
    return async dispatch => {
        try {
            const response = await axios.post(`https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/conseguir-carrito`, {
                httpMethod: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                queryStringParameters: {},
                pathParameters: {},
                body: JSON.stringify({ token: token }),
                isBase64Encoded: false,
            });
            const carritoData = JSON.parse(response.data.body);
            if (carritoData.error) {
                localStorage.removeItem('token');
                window.location.href = '/ingresar';
            }
            const carritoSinCantidad = carritoData?.some((objeto) => objeto.cantidadTotal < objeto.cantidad || objeto.cantidadTotal === 0)
            if (carritoSinCantidad) {
                axios.post('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/delete-carrito', {
                    httpMethod: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    queryStringParameters: {},
                    pathParameters: {},
                    body: JSON.stringify({ token: token }),
                    isBase64Encoded: false,
                })
                    .then(response => {
                        localStorage.removeItem('carrito');

                        window.location.href = '/?isredirect=1';
                    })
                    .catch(error => {
                        console.error('Error al borrar el carrito:', error);
                    });
            }
            if (reedirect && carritoData.length === 0) {
                window.location.href = '/';
            }
            if (setLoading) {
                setLoading(true);
            }
            localStorage.setItem('carrito', JSON.stringify(carritoData))
            return dispatch({
                type: GET_CARRITO,
                payload: carritoData
            });
        } catch (error) {
            console.error('Error during request:', error);
        }
    }
}

export function getNavbarShow(payload) {
    return {
        type: 'GET_NAVBAR_SHOW',
        payload
    };
}

export function getEmptyCarrito(payload) {
    return {
        type: 'GET_EMPTY_CART',
        payload: payload
    };
}

export function changeTypeFilter(payload) {
    return {
        type: 'CASE_TYPE_FILTER',
        payload
    };
}

export function getDestacadas() {
    return async dispatch => {
        try {
            const response = await axios.post(`${URL_API}/prod/sce-productos`, {
                httpMethod: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                queryStringParameters: {},
                pathParameters: {},
                body: JSON.stringify({
                    tipoProducto: {},
                }),
                isBase64Encoded: false,
            });
            const destacadas = JSON.parse(response.data.body).filter((item) => item.categorias?.includes('DESTACADAS'));
            return dispatch({
                type: GET_DESTACADAS,
                payload: destacadas
            });
        } catch (error) {
            console.error('Error during request:', error);
        }
    }
}

export function getCartShop(product) {
    return {
        type: GET_CART_SHOP,
        payload: product
    }
}

export function setCantidad(productId, newQuantity) {
    return {
        type: CHANGE_PRODUCT_QUANTITY,
        payload: { productId, newQuantity },
    };
};
export function getFilter(product) {
    return {
        type: GET_FILTER,
        payload: product
    }
}

export function getFilterOrigen(product) {
    return {
        type: 'GET_FILTER_ORIGEN',
        payload: product
    }
}

export function deleteFilter(product) {
    return {
        type: DELETE_FILTER,
        payload: product
    }
}

export function priceFilter(price) {
    console.log(price)
    return {
        type: 'PRICE_FILTER',
        payload: price
    }
}

export function emptyCarrito(empty) {
    return {
        type: 'CARRITO_EMPTY',
        payload: empty
    }
}

export const loginUser = (credentials, setLoading) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(`${URL_API}/prod/sce-login-user`, {
                httpMethod: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                queryStringParameters: {},
                pathParameters: {},
                body: JSON.stringify(credentials),
                isBase64Encoded: false,
            });
            const token = JSON.parse(response.data.body)["token"];
            const mail = JSON.parse(response.data.body)["mail"];
            const error = JSON.parse(response.data.body)["error"];
            const sucess = JSON.parse(response.data.body)["message"];
            if (error) {
                window.localStorage.removeItem('token')
                window.location.href = '/ingresar'
            }
            if (sucess) {
                localStorage.setItem('token', token);
                localStorage.setItem('mail', mail);
                await dispatch(loginSuccess(token));
                window.location.href = '/';
                setLoading(false);
            } else {
                dispatch(loginFailure(error));
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    };
};