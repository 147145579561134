import React from 'react';

const PoliticasDevolucion = () => {
    return (
        <div className='text-sm text-left lg:w-3/5 lg:px-0 px-5'>
            <p className='font-semibold mt-10 mb-5'>Políticas de Devolución</p>
            <p>
                Si usted recibe el producto y quiere desistir de la compra, usted tiene el derecho de devolverlo y recibir la restitución del valor de su compra. El plazo para desistir de la compra es de 10 (diez) días corridos a partir de la fecha de recepción del producto. Deberá contactarse con SENTATE CON ESTILO y coordinar la devolución en nuestro deposito ubicado en Barracas, el producto será verificado por nuestro personal que el mismo se encuentre en perfectas condiciones y sin uso, como también debe conservar todo el embalaje con el que fue recibido, sin este no se puede ejecutar el cambio o devolución. El producto debe estar acompañado del ticket fiscal, etiquetas, accesorios y empaques originales, es decir; en el mismo estado en que lo recibió y sin señales de uso y/o maltrato. Los gastos de devolución serán asumidos por el cliente. Por cualquier duda o consulta comunicarse a: hola@sentate-conestilo.com
            </p>
            <p className='font-semibold mt-10 mb-5'>
                Políticas de Cambios
            </p>
            <p>
                Los cambios pueden realizarse en cualquier día y horario de atención al público en un tiempo no mayor a los treinta (30) días luego de la operación. Es muy importante que al momento de la recepción de la compra revise el buen estado de los productos antes de firmar el remito de entrega. Es necesario al hacer el cambio, que el producto esté sin uso, en perfectas condiciones, con etiquetas, accesorios y empaques originales. Los gastos de los cambios serán asumidos por el cliente. Para mayor información puedes comunicarte con nuestro servicio de atención al cliente a: hola@sentate-conestilo.com </p>
            <p>• En caso de solicitar un cambio, el valor tomado será el que aparezca en el ticket o factura de compra de acuerdo a lo establecido en el Art. 3 de la Ley 3281: "En los supuestos enumerados en el Artículo 1° se respetará el valor del producto al momento de la compra, debiendo presentar factura de compra o comprobante para realizar cambios o devoluciones quedando a libre opción del consumidor o usuario la presentación de uno u otro comprobante¨.</p>
            <p>• Los productos fabricados a pedido no tiene devolución. Tener presente que los productos de origen natural como el mármol y la madera tienen vetas, nudos y demás características que son propias de los materiales. No se aceptan devoluciones o cambios por gustos estéticos. </p>
            <p>• No se aceptarán reclamos por daños estético y/o faltantes una vez firmado el remito conforme.
            </p>
            <div className='mt-10 border-b-2 mb-32'></div>
        </div>
    );
};

export default PoliticasDevolucion;