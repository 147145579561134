import React from 'react';

const Pedido = () => {
    return (
        <div className='text-sm text-left lg:w-3/5 lg:px-0 px-5'>
            <p className='font-semibold text-lg'>Pedidos Online</p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Qué puedo hacer si recibí un producto defectuoso?
            </p>
            <p>
                Escribinos a hola@sentate-conestilo.com o a través de WhatsApp al +54 9 11 3261-9277 contándonos lo sucedido, envianos una foto donde podamos ver la falla y otra foto con el código del producto, el cual podrás encontrar en el packaging (Ejemplo de código: ABC0004 o 123456).
            </p>
            <p className='font-semibold mt-10 mb-5'>
                Recibí mi compra incompleta, ¿qué hago?
            </p>
            <p>
                En este caso podes contactarnos por mail a hola@sentate-conestilo.com o a través de WhatsApp al +54 9 11 3261-9277 de lunes a viernes de 9 a 18 hs y sábados de 10 a 14 hs enviándonos número de pedido e informado el artículo faltante.
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Qué puedo hacer si recibí otros artículos en mi compra?
            </p>
            <p>
                Escribinos a hola@sentate-conestilo.com o a través de WhatsApp al +54 9 11 3261-9277 contándonos lo sucedido, mandanos una foto del producto incorrecto y otra foto con el código del mismo, el cual podrás encontrar en el packaging (Ejemplo de código: ABC0004 o 123456).
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Puedo modificar mi compra después de haberla realizado?
            </p>
            <p>
                Sí, pero tenés que avisarnos por mail a hola@sentate-conestilo.com dentro de las 24 hs. luego de realizada la compra, transcurrido este lapso de tiempo podrás realizar el cambio del producto una vez lo recibas o retires y coordines con nuestro punto de entrega. En el correo nos debes informar el número de pedido y nombre del producto a cambiar que podrás encontrarlo en el mail que te llega minutos después de realizada tu compra.
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Qué puedo hacer si no recibo los mails de confirmación de mi compra?
            </p>
            <p>
                Te pedimos que revises tu carpeta de Spam o también podés escribirnos a hola@sentate-conestilo.com o a través de WhatsApp al +54 9 11 3261-9277 contándonos lo sucedido para reenviarte la información.
            </p>
            <div className='mt-10 border-b-2 mb-32'></div>
        </div>
    );
};

export default Pedido;