import React from 'react';

const Cambios = () => {
    return (
        <div className='text-sm text-left lg:w-3/5 lg:px-0 px-5'>
            <p className='font-semibold text-lg'>Cambio y devoluciones</p>
            <p className='font-semibold mt-10 mb-5'>¿Cómo puedo realizar un cambio?</p>
            <p>
                Los cambios se pueden hacer en nuestro punto de entrega presentando factura o comprobante de compra. Recordá que tenés como plazo máximo 30 días desde la emisión de la factura en tiendas físicas. En tienda online desde que recibes o retiras el producto.
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Qué puedo hacer si me arrepiento de la compra que realicé?
            </p>
            <p>
                Podés pedir la cancelación en el botón de arrepentimiento o escribirnos a hola@sentate-conestilo.com indicándonos el número de tu pedido y producto que deseas devolver o cambiar.
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Qué pasos debo seguir para cancelar mi compra?
            </p>
            <p>
                Podés pedir la cancelación en el botón de arrepentimiento o escribirnos a hola@sentate-conestilo.com indicándonos el número de tu pedido y producto que deseas devolver o cambiar.
            </p>
            <p className='font-semibold mt-10 mb-5'>
                Si cancelo una compra, ¿en cuánto tiempo se acredita mi dinero en la tarjeta con la que compré?
            </p>
            <p>
                Si cancelás una compra realizada a través de nuestra Tienda Online,
                el tiempo de reembolso dependerá principalmente de los plazos de la entidad
                bancaria:
                <p><p className='font-semibold mt-1'>• Devoluciones de compras abonadas con Tarjeta de Crédito:</p>Tienen un plazo aproximado de 30 días hábiles para ver reflejado el monto en el resumen de tu tarjeta. Si pagaste en cuotas tené en cuenta que cada tarjeta anula los cargos de manera diferente, posiblemente te devuelvan el dinero de la cuota ya cobrada y anulen las siguientes.</p>
                <p><p className='font-semibold mt-1'>• Devoluciones de compras abonadas con Tarjeta de Débito:</p>  La gestión puede demorar entre 7 y 10 días hábiles para verse reflejada en el Home Banking.</p>
            </p>
            <p className='font-semibold mt-10 mb-5'>
                Me equivoqué al seleccionar un producto en mi compra, ¿lo puedo cambiar?
            </p>
            <p>
                Sí, podés acercarte a nuestro punto de entrega con los productos que deseas cambiar y el ticket de compra impreso o en tu teléfono. Los horarios de nuestro sector devoluciones es de lunes a viernes de 9:30 am a 13:30 pm, con previa coordinación vía mail a hola@sentate-conestilo.com o a través de WhatsApp al +54 9 11 3261-9277 de lunes a viernes de 9 a 18 hs. Recordá tener a mano el número de DNI del/la comprador/a o el número de tu pedido.
            </p>
            <p className='font-semibold mt-10 mb-5'>
                ¿Qué valor toman al momento de cambiar un producto?
            </p>
            <p>
                Cuando realices el cambio, el crédito disponible en tienda será por el valor de la factura, si solicitas un cambio por un producto de mayor valor, deberás abonar la diferencia del valor.
            </p>
            <div className='mt-10 border-b-2 mb-32'></div>
        </div>
    );
};

export default Cambios;