import search from '../../assets/search.svg';
import favorite from '../../assets/favorite.svg';
import user from '../../assets/user.svg';
import cart from '../../assets/cart.svg';
import searchWhite from '../../assets/searchWhite.svg';
import favoriteWhite from '../../assets/favoriteWhite.svg';
import userWhite from '../../assets/userWhite.svg';
import menu from '../../assets/menu.svg';
import carrito2 from '../../assets/carrito_2.svg';
import carrito2White from '../../assets/carrito_2_white.svg';
import menuWhite from '../../assets/menuWhite.svg';
import cartWhite from '../../assets/cartWhite.svg';
import logo from '../../assets/logo-horizontal-ok.svg';
import logoHome from '../../assets/sce-logo-horizontal-blanco.svg';
import { useEffect, useState } from 'react';
import Search from '../Search/Search';
import Carrito from '../Carrito/Carrito';
import { useDispatch, useSelector } from 'react-redux';
import { getEmptyCarrito, getNavbarShow } from '../../redux/actions';
import Menu from '../Menu/Menu';

export default function HeaderLower({ isTransparent = false }) {
    const [searchBar, setSearchBar] = useState(false);
    const [carrito, setCarrito] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [showShop, setShowShop] = useState(false);
    const token = { token: localStorage.getItem('token') };
    const [scrollY, setScrollY] = useState(0);
    const focusHeader = useSelector((state) => state.show);
    const carritoEmpty = useSelector((state) => state.emptyCart);
    const dispatch = useDispatch();

    useEffect(() => {
        const carritoData = JSON.parse(window.localStorage.getItem('carrito'));
        console.log(carritoData)
        dispatch(getEmptyCarrito(carritoData?.length != 0))
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const navbarFocus = () => {
        if (scrollY < 300 || focusHeader) {
            return `fixed ${isTransparent && !focusHeader ? 'border-white bg-black bg-opacity-50' : 'bg-white border-black'}`;
        } else {
            return `lg:flex lg:relative bg-white lg:bg-opacity-100 fixed`;
        }
    };

    const navbarFocusButtons = () => {
        if (scrollY < 300 || focusHeader) {
            return `${isTransparent && !focusHeader && 'text-white'}`;
        } else {
            return `flex`;
        }
    };
    const navbarFocusButtonsColor = () => {
        if (scrollY < 300 || focusHeader) {
            return `${isTransparent && !focusHeader && 'hover:text-white'}`;
        } else {
            return `hover:text-white`;
        }
    };

    const navbarFocusLogo = () => {
        if (scrollY < 300 || focusHeader) {
            return isTransparent && !focusHeader ?
                <img src={logoHome} alt="Logo" className="ml-4 w-52 mt-3 p-2" />
                :
                <img src={logo} alt="Logo" className="ml-4 w-52 mt-3 p-2" />
        } else {
            return <img src={logo} alt="Logo" className="ml-4 w-52 mt-3 p-2" />;
        }
    };

    return (
        <>
            <div onMouseLeave={() => { dispatch(getNavbarShow(false)) }} className={`${showShop ? '' : 'border-b-2'} ${navbarFocus()} w-full z-[10]`}>
                <div className='h-12 pt-9 mb-10 flex flex-row 2xl:pl-[13%] 2xl:pr-[13%] lg:mx-10 mx-3'>
                    <div className='w-[70%]'>
                        <a href="/">
                            {
                                navbarFocusLogo()
                            }
                        </a>
                    </div>
                    <div></div>
                    <div className="whitespace-nowrap mt-3 flex justify-start font-semibold text-[16px]">
                        <button className={`mx-3 ${navbarFocusButtons()} hidden lg:block hover:opacity-50 opacity-100 shadow-2xl shadow-white`}>
                            <a href="/">Home</a>
                        </button>
                        <button
                            className={`mx-3 ${navbarFocusButtons()} hidden lg:block hover:opacity-50 opacity-100`}
                            onMouseEnter={() => setShowShop(true)}
                        ><a href="/shop">Shop</a></button>
                        <button className={`mx-3 ${navbarFocusButtons()} hidden lg:block hover:opacity-50 opacity-100`}>
                            <a href="/set-de-productos">Set de Productos</a>
                        </button>
                        <button className={`mx-3 ${navbarFocusButtons()} hidden lg:block hover:opacity-50 opacity-100`}>
                            <a href="/entrega-inmediata">Entrega Inmediata</a>
                        </button>
                        <button className={`mx-3 ${navbarFocusButtons()} hidden lg:block hover:opacity-50 opacity-100`}><a href="/sale">Sale</a></button>
                        {/* <button className={`mx-3 hover:text-black ${navbarFocusButtonsColor()} hidden lg:block text-red-600 opacity-100`}><a href="/cyber-off">CYBER OFF</a></button> */}
                        <button className={`mx-3 ${navbarFocusButtons()} hidden lg:block hover:opacity-50 opacity-100`}>
                            <a href="/collection">Collection</a>
                        </button>
                    </div>
                    <div className="flex flex-row w-[20rem] mt-6 justify-end items-center">
                        <button onClick={() => setSearchBar(true)} className="w-5">{!isTransparent || focusHeader || scrollY > 300 ? <img src={search} /> : <img src={searchWhite} />}</button>
                        <div className="w-5 mx-3">
                            <a href={token.token ? '/mis-datos/fav' : '/ingresar'}>
                                {!isTransparent || focusHeader || scrollY > 300 ?
                                    <img src={favorite} />
                                    :
                                    <img src={favoriteWhite} />
                                }

                            </a>
                        </div>
                        <div className="w-5 mr-3">
                            <a href={token.token ? '/mis-datos' : '/ingresar'}>
                                {!isTransparent || focusHeader || scrollY > 300 ?
                                    <img src={user} />
                                    :
                                    <img src={userWhite} />
                                }
                            </a>
                        </div>
                        {!carritoEmpty ?
                            <div className="w-5 lg:mr-0 mr-3" onClick={() => setCarrito(true)}>{!isTransparent || focusHeader || scrollY > 300 ? <img src={cart} /> : <img src={cartWhite} />}</div>
                            :
                            <div className="w-5 lg:mr-0 mr-3" onClick={() => setCarrito(true)}>{!isTransparent || focusHeader || scrollY > 300 ? <img src={carrito2} /> : <img src={carrito2White} />}</div>
                        }
                        <button className="w-5 lg:hidden" onClick={() => setShowMenu(!showMenu)}>{!showMenu ? !isTransparent || focusHeader || scrollY > 300 ? <img src={menuWhite} /> : <img src={menu} /> : 'X'}</button>
                    </div>
                    <Search isSearch={searchBar} handleSearch={setSearchBar} />
                    {carrito &&
                        <Carrito setIsOpen={setCarrito} isOpen={carrito} />
                    }
                    <Menu menu={showMenu} />
                </div>
                {showShop &&
                    <div onMouseLeave={() => setShowShop(false)} className={`flex flex-col bg-white`}>
                        <div className='border-b-2 mb-2 border-black'></div>
                        <div className='h-44 flex flex-row text-left ml-60 justify-center'>
                            <div className='flex flex-col text-[12px] mr-8 w-28'>
                                <a href='/sillas' className='border-b-2 hover:font-semibold pl-1 text-[14px] mb-2'>Sillas</a>
                                <a href='/sillas/polipropileno' className='hover:font-semibold pl-1'>Polipropileno</a>
                                <a href='/sillas/policarbonato' className='hover:font-semibold pl-1'>Policarbonato</a>
                                <a href='/sillas/transparentes' className='hover:font-semibold pl-1'>Transparentes</a>
                                <a href='/sillas/madera' className=' hover:font-semibold pl-1'>Madera</a>
                                <a href='/sillas/metálica' className='hover:font-semibold pl-1'>Metálica</a>
                                <a href='/sillas/tapizadas' className='hover:font-semibold pl-1'>Tapizadas</a>
                                <a href='/sillas/combinada' className='hover:font-semibold pl-1'>Combinada</a>
                                <a href='/sillas/banquetas' className='hover:font-semibold pl-1'>Banquetas</a>
                                <a href='/sillas/de-escritorio' className='hover:font-semibold pl-1'>de Escritorio</a>
                            </div>
                            <div className='flex flex-col text-[12px] mr-8 w-28'>
                                <a href='/mesas' className='border-b-2 text-[14px] mb-2 pl-1 hover:font-semibold'>Mesas</a>
                                <a href='/mesas/de-comedor' className='hover:font-semibold pl-1'>de Comedor</a>
                                <a href='/mesas/ratonas' className='hover:font-semibold pl-1'>Ratonas</a>
                                <a href='/mesas/auxiliar' className='hover:font-semibold pl-1'>Auxiliar</a>
                                <a href='/mesas/arrime' className='hover:font-semibold pl-1'>Arrime</a>
                                <a href='/mesas/de-luz' className='hover:font-semibold pl-1'>de Luz</a>
                                <a href='/mesas/escritorios' className='hover:font-semibold pl-1'>Escritorios</a>
                                <a href='/mesas/organizador' className='hover:font-semibold pl-1'>Organizador</a>
                            </div>
                            <div className='flex flex-col text-[12px] mr-8 w-28'>
                                <a href='/puffs-sillones' className='border-b-2 text-[14px] mb-2 pl-1 hover:font-semibold'>Puffs & Sillones</a>
                                <a href='/puffs-sillones/puffs' className='hover:font-semibold pl-1'>Puffs</a>
                                <a href='/puffs-sillones/sillones' className='hover:font-semibold pl-1'>Sillones</a>
                            </div>
                            <div className='flex flex-col text-[12px] mr-8 w-28'>
                                <a href='/exterior' className='border-b-2 text-[14px] mb-2 hover:font-semibold pl-1'>Exterior</a>
                                <a href='/exterior/exterior-sillas' className='hover:font-semibold pl-1'>Sillas</a>
                                <a href='/exterior/exterior-mesas' className='hover:font-semibold pl-1'>Mesas</a>
                                <a href='/exterior/exterior-sillones' className='hover:font-semibold pl-1'>Sillones</a>
                            </div>
                            <div className='flex flex-col text-[12px] mr-8 w-28'>
                                <a href='/deco-iluminaciones/' className='border-b-2 whitespace-nowrap pl-1 text-[14px] mb-2 hover:font-semibold'>Deco & Iluminación</a>
                                <a href='/deco-iluminaciones/almohadones' className='hover:font-semibold pl-1'>Almohadones</a>
                                <a href='/deco-iluminaciones/lamparas' className='hover:font-semibold pl-1'>Lámparas</a>
                                <a href='/deco-iluminaciones/objetos' className='hover:font-semibold pl-1'>Objetos</a>
                            </div>
                            <div className='flex flex-col text-[12px] mr-8 w-28'>
                                <a href='/otros' className='border-b-2 text-[14px] mb-2 hover:font-semibold pl-1'>Otros</a>
                                <a href='/otros/kids' className='hover:font-semibold pl-1'>Kids</a>
                                <a href='/otros/pets' className='hover:font-semibold pl-1'>Pets</a>
                            </div>
                        </div>
                        <div className='text-sm text-right mt-3 mr-16 mb-2'>
                            <a href='/shop/todos' className='hover:font-semibold pl-1'>Ver todos los productos</a>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}