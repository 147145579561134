import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardCarrito from "../../ui/cardCarrito/CardCarrito";
import { getCarrito } from "../../redux/actions";

const Carrito = ({ isOpen, setIsOpen, isNew }) => {
    const carritoRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const carrito = useSelector((state) => state.carrito);
    const precioDolar = useSelector((state) => state.dolarPrice);
    const dispatch = useDispatch();
    const token = localStorage.getItem('token');
    useEffect(() => {
        dispatch(getCarrito(token))
        let timer;
        const segundos = carrito.length == 0 ? 2700 : 1500;
        timer = setTimeout(() => {
            setLoading(true);
        }, segundos);

        return () => clearTimeout(timer);
    }, []);

    const precioTotalOferta = carrito && carrito.reduce((previous, current) => {
        const itemPriceUSDOffert = current.precio_oferta_dolares;
        const itemPriceUSD = current.precio_dolar;
        const precioConIncremento = (itemPriceUSD * precioDolar);
        const precioConIncrementoOferta = itemPriceUSDOffert * precioDolar;

        const descuento = precioConIncremento * 0;
        const descuentoOferta = precioConIncrementoOferta * 0;

        const precioFinal = ((precioConIncremento - descuento) * current.cantidad).toFixed(0);
        const precioFinalOferta = ((precioConIncrementoOferta - descuentoOferta) * current.cantidad).toFixed(0);
        return previous + (Number(itemPriceUSDOffert) != 0 ? Number(precioFinalOferta) : Number(precioFinal));
    }, 0);

    const precioTotal = carrito && carrito.reduce((previous, current) => {
        const itemPriceUSD = current.precio_dolar;
        const precioConIncremento = itemPriceUSD * precioDolar;
        
        // Definimos porcentaje y cantidad de la promoción
        const porcentajePromo = Number(current.porcentaje_promo) || 0;
        const cantidadPromo = Number(current.cantidad_promo) || 0;
      
        let resultado;
        if (porcentajePromo) {
            resultado = precioConIncremento * (1 - porcentajePromo / 100);
        } else if (cantidadPromo) {
            resultado = precioConIncremento - cantidadPromo;
        } else {
            resultado = precioConIncremento; // Si no hay promo, se toma el precio con incremento directamente
        }
        
        // Aplicamos un descuento adicional del 20%
        const precioConDescuentoAdicional = resultado;
      
        // Calculamos el precio final por la cantidad de artículos
        const precioFinal = (precioConDescuentoAdicional * current.cantidad).toFixed(0);
    
        // Sumar el precio final al total acumulado
        return previous + Number(precioFinal);
    }, 0);

    const restante = precioTotal;

    return (
        <div>
            {isOpen &&
                <div className="z-[2200] absolute"
                    ref={carritoRef}
                    onClick={(e) => {
                        if (e.target === carritoRef.current) {
                            setIsOpen(false);
                        }
                    }}
                >
                    <div
                        className="fixed top-8 lg:w-1/3 w-full bottom-0 right-0 bg-white shadow-2xl z-50"
                    >
                        <div className="mx-7">
                            <h1 className="my-5 text-sm font-bold text-left">TU CARRITO {'>'} {carrito && carrito.length > 0 ? carrito.length : 0} producto/s</h1>
                            <button
                                onClick={() => setIsOpen(false)}
                                className="absolute top-5 right-7 text-gray-500 hover:text-gray-800 cursor-pointer"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </button>
                            <div className="border-t-2 border-opacity-50 mb-3"></div>
                            <div className="overflow-x-auto max-h-[calc(100vh-270px)]">
                                <div className="flex flex-col">
                                    {carrito && loading ?
                                        carrito.map((item) => (
                                            <div>
                                                {<CardCarrito item={item} dolar={precioDolar} setLoading={setLoading} />}
                                                <div className="border-t-2 my-2 border-opacity-30"></div>
                                            </div>
                                        )) :
                                        <div className="flex justify-center items-center mt-32 mb-60">
                                            <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#E40F1B]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                            </svg>
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    }
                                </div>
                            </div>
                            {carrito.length > 0 ?
                                <div>
                                    <div className="flex flex-col">
                                        <div className="flex flex-row text-sm justify-between mb-1">
                                            <p className="font-medium">Totales:</p>
                                            <p>{precioTotal.toLocaleString("es-AR", {
                                                style: "currency",
                                                currency: "ARS",
                                                maximumFractionDigits: 0,
                                            })}.-</p>
                                        </div>
                                        <div className="border-b-2"></div>
                                        <button className="border my-1 bg-[#E40F1B] text-[13px] py-2 rounded-md hover:bg-red-400 text-white mt-1"><a href="/carrito">PAGAR AHORA</a></button>
                                        <a href="/carrito" className="border my-1 text-[13px] py-2 rounded-md hover:bg-gray-400 border-gray-400 hover:text-white">
                                            <a href="/carrito">VER CARRITO</a>
                                        </a>
                                    </div>
                                </div> : loading ? <div className="font-semibold">No hay productos</div> : null
                            }
                        </div>
                    </div>
                </div>}
        </div>
    );
};

export default Carrito;
