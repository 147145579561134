import React from 'react';
import fav from '../../../../../assets/fav_full.svg';
import disenamos from '../../../../../assets/_disenos.svg';
import embalaje from '../../../../../assets/__embalaje_1.svg';
import cuotas from '../../../../../assets/_cuotas.svg';
import envios from '../../../../../assets/_envios.svg';

const cardDetalles = ({ item }) => {
    const estado = item.pedido;

    const now = new Date();

    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Enero es 0
    const year = now.getFullYear();

    const currentDateFormatted = `${day}/${month}/${year}`;
    const mediopago = item.mediopago;

    return (
        <div className="flex flex-row">
            <div className="absolute border lg:w-[63%] w-full -z-10 mt-16 border-b-2 border-[#E40F1B]"></div>
            <div className="flex flex-row justify-between">
                <div className="flex flex-col ml-16 items-center">
                    <p className={`text-[10px] mt-5 font-semibold`}>Pedido recibido</p>
                    <img src={disenamos} className="w-10 my-2 border-[2px] border-[#E40F1B] rounded-full" alt="" />
                    <p className={`text-[10px] font-semibold`}>{currentDateFormatted}</p>
                </div>
                <div className="flex flex-col mt-5 ml-16 items-center">
                    <p className={`text-[10px]  ${mediopago !== 20 ? 'opacity-70' : ''} font-semibold`}>Pago pendiente</p>
                    <img src={cuotas} className="w-10 my-2 border-[2px] border-[#E40F1B] rounded-full" alt="" />
                    <p className={`text-[10px]  ${mediopago !== 20 ? 'opacity-70' : ''} font-semibold`}>{mediopago !== 20 ? '--/--/----' : currentDateFormatted}</p>
                </div>
                <div className="flex flex-col ml-16 mt-4 items-center">
                    <p className={`text-[10px] opacity-70 font-semibold mt-1 w-40 text-center`}>Pedido pendiente de preparación</p>
                    <img src={embalaje} className="w-10 mt-3 mb-2 border-[2px] border-[#E40F1B] rounded-full" alt="" />
                    <p className={`text-[10px]  opacity-70 font-semibold`}>--/--/----</p>
                </div>
                <div className="flex flex-col mt-5 ml-16 items-center">
                    <p className="text-[10px] font-semibold opacity-70">Envío pendiente</p>
                    <img src={envios} className="w-10 my-2 border-[2px] border-[#E40F1B] rounded-full" alt="" />
                    <p className="text-[10px] font-semibold opacity-70">--/--/----</p>
                </div>
                <div className="flex flex-col mt-5 ml-16 items-center">
                    <p className="text-[10px] font-semibold opacity-70">Entrega estimada</p>
                    <img src={fav} className="w-10 p-1 bg-gray-100 my-2 border-[2px] border-[#E40F1B] rounded-full" alt="" />
                    <p className="text-[10px] font-semibold opacity-70">--/--/----</p>
                </div>
            </div>
        </div>
    );
};

export default cardDetalles;