import React, { useState } from 'react';
import axios from 'axios';
import trash from "../../assets/_quitar_producto.svg";
import { useDispatch } from 'react-redux';
import { getCarrito } from '../../redux/actions';


const CardCarritoPageMobile = ({ item, dolar, setLoading }) => {
    const [localCant, setLocalCant] = useState(item.cantidad);
    const dispatch = useDispatch();

    const handleCantidad = async (suma, idproducto) => {
        let updatedCant = localCant;

        if (suma === 'suma') {
            updatedCant = updatedCant <= item.cantidadTotal ? Number(updatedCant) + 1 : item.cantidadTotal;
        } else if (suma === 'resta' && updatedCant > 1) {
            updatedCant -= 1;
        }

        setLocalCant(updatedCant);

        await axios.post(`https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/cambiar-cantidad`, {
            httpMethod: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            queryStringParameters: {},
            pathParameters: {},
            body: JSON.stringify({ token, idproducto, cantidad: updatedCant }),
            isBase64Encoded: false,
        });

        dispatch(getCarrito(token));
    }

    const token = localStorage.getItem("token");
    const itemPriceUSD = item.precio_dolar;
    const precioConIncremento = itemPriceUSD * dolar;
    const descuento = precioConIncremento;

    const precioFinal = ((precioConIncremento || descuento) * localCant).toFixed(0);

    const handleClick = async (idproducto) => {
        setLoading(false);
        await axios.post(`https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/borrar-producto-carrito`, {
            httpMethod: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            queryStringParameters: {},
            pathParameters: {},
            body: JSON.stringify({ token, idproducto }),
            isBase64Encoded: false,
        });
        dispatch(getCarrito(token, true));
        setLoading(true);
    }

    const handleChange = async (e, idproducto) => {
        const inputValue = e.target.value;
        let newLocalCant;

        if (!isNaN(inputValue) && inputValue > 0) {
            if (inputValue > item.cantidadTotal) {
                newLocalCant = item.cantidadTotal;
            } else {
                newLocalCant = inputValue;
            }
        } else {
            newLocalCant = 1;
        }


        setLocalCant(newLocalCant);

        await axios.post(`https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/cambiar-cantidad`, {
            httpMethod: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            queryStringParameters: {},
            pathParameters: {},
            body: JSON.stringify({ token, idproducto, cantidad: newLocalCant }),
            isBase64Encoded: false,
        });

        dispatch(getCarrito(token));
    }

    let porcentajePromo = Number(item.porcentaje_promo) || 0;
    let cantidadPromo = Number(item.cantidad_promo) || 0;
  
    let resultado;
    let label;
    if (porcentajePromo) {
      resultado = precioConIncremento * (1 - porcentajePromo / 100);
      label = `${porcentajePromo}% OFF`;
    } else if (cantidadPromo) {
      resultado = precioConIncremento - cantidadPromo;
    }


    return (
        <div className="flex flex-col my-4 mx-3">
            <div className='flex flex-row items-center justify-between text-sm lg:h-20'>
                <div className='flex lg:flex-row flex-col'>
                    <img
                        src={`https://sistema.depcsuite.com/productos/${item.imagen}`}
                        alt={item.nombre}
                        className='w-20 border'
                    />
                </div>
                <div className='border w-36'>
                    <button className='font-semibold h-6 mr-3' onClick={() => handleCantidad('resta', item.idproducto)}>-</button>
                    <input type="text" className={`h-12 w-10 text-center`} onChange={(e) => handleChange(e, item.idproducto)} value={localCant} name="" id="" />
                    <button className='font-semibold h-6 ml-3' onClick={() => handleCantidad('suma', item.idproducto)}>+</button>
                </div>
                <div className="flex flex-col items-center">
                    <button onClick={() => handleClick(item.idproducto)}>
                        <img src={trash} className='ml-2 w-16' alt="" />
                    </button>
                </div>
            </div>
            <div className='flex flex-row justify-between'>
                <h5 className="w-40 text-sm text-left">{item.nombre}</h5>
                <div className='flex flex-col w-20'>
                    <p className='text-xs'>Precio total</p>
                    <span className="whitespace-nowrap text-[14px] ml-2">{Number(resultado||precioFinal).toLocaleString("es-AR", {
                        style: "currency",
                        currency: "ARS",
                        maximumFractionDigits: 0,
                    })}-</span>
                </div>
            </div>
        </div>
    );
};

export default CardCarritoPageMobile;