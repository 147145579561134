import React, { useEffect, useState, useRef } from 'react';
import card from '../../assets/black-card.svg';
import share from '../../assets/share.svg';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCarrito, getCartShop } from '../../redux/actions';
import axios from 'axios';

function ModalPromo({ isOpen, setIsOpen, item, setCarrito, dolar, porcentaje, precioTachado }) {
    const dispatch = useDispatch();
    const modalRef = useRef(null);
    const [localCant, setLocalCant] = useState(1);
    const [currentIndex, setCurrentIndex] = useState(0);
    const careousel = JSON.parse(item?.imagenes);
    const token = localStorage.getItem('token');
    const changeImage = (index) => {
        if (index >= 0 && careousel !== null && index < careousel.length) {
            setCurrentIndex(index);
        }
    };

    let porcentajePromo = Number(item.porcentaje_promo) || 0;
    let cantidadPromo = Number(item.cantidad_promo) || 0;

    let resultado;
    
    const itemPriceUSD = item.precio_dolar || 0;
    const itemPriceUSDOffert = item.precio_oferta_dolares || 0;
    
    const precioConIncremento = itemPriceUSD * dolar;
    const precioOfertaIncremento = itemPriceUSDOffert * dolar;
    const descuentoOferta = precioOfertaIncremento * 0.2;
    
    if (porcentajePromo) {
        resultado = precioConIncremento * (1 - porcentajePromo / 100);
    } 
    else if (cantidadPromo) {
        resultado = precioConIncremento - cantidadPromo;
    } 
    else {
        resultado = precioConIncremento - descuentoOferta;
    }
    if (porcentajePromo) {
        resultado = precioConIncremento * (1 - porcentajePromo / 100);
    } else if (cantidadPromo) {
        resultado = precioConIncremento - cantidadPromo;
    } else {
        resultado = precioConIncremento;
    }

    const precioConDescuentoAdicional = resultado * 0.8;

    const precioFinal = precioConDescuentoAdicional.toFixed(0);

    const calculateInstallment = () => (resultado / 6).toFixed(2);

    const porcentajeDiferencia = itemPriceUSDOffert
        ? `${Math.abs(
            ((100 - (itemPriceUSDOffert * 100) / itemPriceUSD)).toFixed(0)
        )}% OFF`
        : '';

    const [installmentPrice, installmentDecimal] = calculateInstallment().split(
        '.'
    );

    const shiftThumbnailPosition = () => {
        if (careousel == null) return 0;
        let shift = (currentIndex - 1) * -100 / 3;
        if (currentIndex === 0) {
            shift = (careousel.length - 1) * -0;
        } else if (currentIndex === careousel.length) {
            shift = -100 / 3;
        }
        return shift;
    };
    const previousImage = () => {
        if (careousel == null) return 0;
        if (currentIndex === 0) {
            setCurrentIndex(careousel.length - 1);
        } else {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const nextImage = () => {
        if (careousel == null) return 0;
        if (currentIndex === careousel.length - 1) {
            setCurrentIndex(0);
        } else {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handleCantidad = async (suma) => {
        let updatedCant = localCant;

        if (suma === 'suma') {
            updatedCant = updatedCant < item.cantidadTotal ? Number(updatedCant) + 1 : item.cantidadTotal;
        } else if (suma === 'resta' && updatedCant > 1) {
            updatedCant -= 1;
        }

        setLocalCant(updatedCant);
    }


    const handleChange = async (e) => {
        const inputValue = e.target.value;
        let newLocalCant;

        if (!isNaN(inputValue) && inputValue > 0) {
            if (inputValue > item.cantidadTotal) {
                newLocalCant = item.cantidadTotal;
            }
            else {
                newLocalCant = inputValue;
            }
        } else {
            newLocalCant = 1;
        }

        setLocalCant(newLocalCant);
    }

    const objAddCart = {
        token,
        idproducto: item.idproducto,
        cantidad: localCant
    }

    const handleAddCart = async () => {
        if (!token) return window.location.href = '/ingresar';
        await axios.post(`https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/agregar-carrito`, {
            httpMethod: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            queryStringParameters: {},
            pathParameters: {},
            body: JSON.stringify(objAddCart),
            isBase64Encoded: false,
        });
        dispatch(getCarrito(token))
    }

    return (
        <div>
            {isOpen && (
                <div
                    className="fixed z-[1000] top-0 left-0 w-full h-full flex justify-center items-center bg-gray-700 bg-opacity-50"
                    ref={modalRef}
                    onClick={(e) => {
                        if (e.target === modalRef.current) {
                            setIsOpen(false);
                        }
                    }}
                >
                    <div>
                        <div className="relative w-[812px] h-[498px] flex flex-row bg-white rounded-lg py-6 px-3">
                            <button
                                onClick={() => setIsOpen(false)}
                                className="absolute top-2 right-2 text-gray-500 hover:text-gray-800 cursor-pointer"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </button>
                            <div className='relative'>
                                <div className="flex items-center flex-col">
                                    {porcentaje && <span className='absolute text-lg text-center items-center rounded-l-md text-white w-32 h-7 right-0 bg-[#E40F1B]'>{porcentaje}</span>}
                                    <img className='w-[309px] mb-6 justify-center' src={`https://sistema.depcsuite.com/productos/${careousel ? careousel[currentIndex]?.imagen : item.imagen}`} alt="" />

                                    {careousel && <div className='flex flex-row items-center'>
                                        <button className="p-2 rounded-full" onClick={previousImage}>&lt;</button>

                                        <div className="flex overflow-hidden" style={{ width: 'calc(33.333% * 3)' }}>
                                            <div className="transition-transform duration-300 ease-in-out flex" style={{ transform: `translateX(${shiftThumbnailPosition()}%)` }}>
                                                {careousel && careousel.map((image, index) => (
                                                    <div key={index} className="flex-shrink-0" onClick={() => changeImage(index)}>
                                                        <img className={`border-2 h-20 cursor-pointer ${currentIndex === index ? 'border-gray-400' : 'border-transparent'}`} src={`https://sistema.depcsuite.com/productos/${image.imagen}`} alt={`Image ${index}`} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <button className="p-2 rounded-full" onClick={nextImage}>&gt;</button>
                                    </div>}
                                </div>
                            </div>
                            <div className='flex flex-col ml-5 items-start'>
                                <div className='w-96'>
                                    <p className='text-[20px] font-semibold mb-3 text-left'>{item.nombre}</p>
                                </div>
                                    <div className="text-lg flex flex-col text-left">
                                        <div className='flex flex-col mb-2'>
                                            {precioConIncremento && <span className="opacity-90 line-through">{Number(precioFinal) !== 0 && Number(item.cantidad) != 0 ? `${Number(precioConIncremento).toLocaleString("es-AR", {
                                                style: "currency",
                                                currency: "ARS",
                                                maximumFractionDigits: 0,
                                            })} de lista` : 'AGOTADO'}</span>}
                                            <span className="mr-2 font-semibold text-red-600 font-semiBoldQuicksand">{Number(precioFinal) !== 0 && Number(item.cantidad) != 0 ? `${Number(resultado).toLocaleString("es-AR", {
                                                style: "currency",
                                                currency: "ARS",
                                                maximumFractionDigits: 0,
                                            })} Promo CyberOFF ${item.porcentaje_promo ? `${item.porcentaje_promo}%` : `- ${item.cantidad_promo}`}` : 'AGOTADO'}</span>
                                        </div>
                                    </div>
                                    <div className="text-lg flex flex-col text-left">
                                        {Number(precioFinal) !== 0 && Number(item.cantidad) != 0 && <label className='text-sm uppercase'>PRECIO 20% adicional con transferencia:</label>}
                                        <div className='flex flex-row'>
                                            <span className="mr-2 font-semibold">{Number(precioFinal) !== 0 && Number(item.cantidad) != 0 ? Number(precioTachado || precioFinal).toLocaleString("es-AR", {
                                                style: "currency",
                                                currency: "ARS",
                                                maximumFractionDigits: 0,
                                            }) : 'AGOTADO'}</span>
                                            {precioTachado != 0 && <span className="opacity-90 line-through">{Number(precioFinal) !== 0 && Number(item.cantidad) != 0 ? Number(precioFinal).toLocaleString("es-AR", {
                                                style: "currency",
                                                currency: "ARS",
                                                maximumFractionDigits: 0,
                                            }) : 'AGOTADO'}</span>}
                                        </div>
                                    </div>
                                {Number(precioFinal) !== 0 && Number(item.cantidad) != 0 &&
                                    <div className="flex flex-row justify-center mt-2">
                                        <img src={card} alt="Icono" className="w-3 mt-[3.8px]" />
                                        <span className="text-[11px] mt-1 ml-1">6 cuotas de </span>
                                        <span className="font-semibold ml-1 mt-1 text-[11px]">{Number(installmentPrice).toLocaleString("es-AR", {
                                            style: "currency",
                                            currency: "ARS",
                                            maximumFractionDigits: 0,
                                        })}</span>
                                        <span className="font-semibold text-[7px] mt-[4.4px] ml-[2px]">{installmentDecimal}</span>
                                    </div>}
                                <div className='mt-5 flex flex-col items-start'>
                                    <p className='text-[12px]'>Cantidad:</p>
                                    <div className='flex flex-row border'>
                                        <button className='font-semibold w-8 h-8' onClick={(e) => handleCantidad('resta')}>-</button>
                                        <input type="text" className='w-11 h-8 pl-4' value={localCant} onChange={(e) => handleChange(e)} name="" id="" />
                                        <button className='font-semibold w-8 h-8' onClick={(e) => handleCantidad('suma')}>+</button>
                                    </div>
                                </div>
                                <div className='opacity-90 hover:opacity-100 '>
                                    <button
                                        disabled={Number(precioFinal) !== 0 && Number(item.cantidad) != 0 ? false : true}
                                        onClick={() => {
                                            handleAddCart();
                                            setCarrito(true)
                                            setIsOpen(false)
                                        }}
                                        className='w-[200%] cursor-pointer mt-10 border hover:border-gray-400 hover:bg-gray-400 py-1 rounded-sm hover:text-white border-black'>AGREGAR AL CARRITO</button>
                                </div>
                                <div className='flex flex-col justify-start mt-4 w-[23rem] items-start'>
                                    <p className='text-[10px] text-left'>Es un modelo con una estética y funcional que combina con cualquier
                                        estilo de decoración, su transparencia hace que sea una silla de alto impacto
                                        visual respetando la imagen del espacio sin
                                    </p>
                                    <div className='text-[10px] w-[93%] mt-5 flex flex-row justify-between'>
                                        <div>
                                            <a href={`/producto/${item.slug ? item.slug : ''}${item.slug ? '-' : ''}${item.idproducto}`} className='border-b-2'>
                                                Ver más
                                            </a>
                                        </div>
                                        <div>
                                            <img src={share} className='w-4' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ModalPromo;