import React, { useState } from 'react';
import left from '../../assets/_arrow_left.svg';
import right from '../../assets/_arrow_left-rigth.svg';

const Menu = ({ menu }) => {
    const [selectMenu, setSelectMenu] = useState('Menu');
    const handleQuitSession = () => {
        localStorage.removeItem('token');
        window.location.href = '/';
    }
    const token = localStorage.getItem('token');
    return (
        <div className='text-lg'>

            {menu && <div className="bg-white z-[50000] border-t-2 border-black h-screen w-full mt-12 right-0 fixed">
                {selectMenu === 'Menu' &&
                    <div className='mt-5 mx-[20px]'>
                        <div onClick={() => setSelectMenu('Shop')} className='flex flex-row justify-between mt-[10px]'>
                            <p>Shop</p>
                            <img src={right} alt="" />
                        </div>
                        <a href='/set-de-productos' className='flex flex-row justify-between mt-[10px]'>
                            <p>Set de Productos</p>
                            <img src={right} alt="" />
                        </a>
                        <a href='/entrega-inmediata' className='flex flex-row justify-between mt-[10px]'>
                            <p>Entrega Inmediata</p>
                            <img src={right} alt="" />
                        </a>
                        <a href='/sale' className='flex flex-row justify-between mt-[10px]'>
                            <p>Sale</p>
                            <img src={right} alt="" />
                        </a>
                        <a href='/collection' className='flex flex-row justify-between mt-[10px]'>
                            <p>Collection</p>
                            <img src={right} alt="" />
                        </a>
                    </div>
                }
                {selectMenu === 'Shop' && <div className='mt-5 mx-[20px]'>
                    <div className='flex mb-10 flex-row' onClick={() => setSelectMenu('Menu')}>
                        <img src={left} className='mr-3' alt="" />
                        <p className='font-semibold'>Atras</p>
                    </div>
                    <div className='flex flex-col mx-[3px]'>
                        <a href='/shop' className='flex my-1 justify-between flex-row'>
                            <p>Todos</p>
                            <img src={right} alt="" />
                        </a>
                        <div onClick={() => setSelectMenu('Sillas')} className='flex mt-[10px] justify-between flex-row'>
                            <p>Sillas</p>
                            <img src={right} alt="" />
                        </div>
                        <div onClick={() => setSelectMenu('Mesas')} className='flex mt-[10px] justify-between flex-row'>
                            <p>Mesas</p>
                            <img src={right} alt="" />
                        </div>
                        <div onClick={() => setSelectMenu('Puffs')} className='flex mt-[10px] justify-between flex-row'>
                            <p>Puffs & Sillones</p>
                            <img src={right} alt="" />
                        </div>
                        <div onClick={() => setSelectMenu('Exterior')} className='flex mt-[10px] justify-between flex-row'>
                            <p>Exterior</p>
                            <img src={right} alt="" />
                        </div>
                        <div onClick={() => setSelectMenu('Deco')} className='flex mt-[10px] justify-between flex-row'>
                            <p>Deco & Iluminación</p>
                            <img src={right} alt="" />
                        </div>
                        <div onClick={() => setSelectMenu('Otros')} className='flex mt-[10px] justify-between flex-row'>
                            <p>Otros</p>
                            <img src={right} alt="" />
                        </div>
                    </div>
                </div>}
                {
                    selectMenu === 'Mesas' &&
                    <div className='mt-5 mx-[20px]'>
                        <div className='flex flex-row' onClick={() => setSelectMenu('Shop')}>
                            <img src={left} className='mr-3' alt="" />
                            <p className='font-semibold'>Mesas</p>
                        </div>
                        <div className='grid grid-cols-2 my-5 text-left mx-[3px] gap-y-3'>
                            <a href="/mesas">Todos</a>
                            <a href='/mesas/de-comedor' className=''>de Comedor</a>
                            <a href='/mesas/ratonas' className=''>Ratonas</a>
                            <a href='/mesas/auxiliar' className=''>Auxiliar</a>
                            <a href='/mesas/arrime' className=''>Arrime</a>
                            <a href='/mesas/de-luz' className=''>de Luz</a>
                            <a href='/mesas/escritorios' className=''>Escritorios</a>
                            <a href='/mesas/organizador' className=''>Organizador</a>
                        </div>
                    </div>
                }
                {
                    selectMenu === 'Sillas' &&
                    <div className='mt-5 mx-[20px]'>
                        <div className='flex flex-row' onClick={() => setSelectMenu('Shop')}>
                            <img src={left} className='mr-3' alt="" />
                            <p className='font-semibold'>Sillas</p>
                        </div>
                        <div className='grid grid-cols-2 my-5 text-left mx-[3px] gap-y-3'>
                            <a href="/sillas">Todos</a>
                            <a href='/sillas/polipropileno' className=''>Polipropileno</a>
                            <a href='/sillas/policarbonato' className=''>Policarbonato</a>
                            <a href='/sillas/transparentes' className=''>Transparentes</a>
                            <a href='/sillas/madera' className=' '>Madera</a>
                            <a href='/sillas/metálica' className=''>Metálicas</a>
                            <a href='/sillas/tapizadas' className=''>Tapizadas</a>
                            <a href='/sillas/combinada' className=''>Combinadas</a>
                            <a href='/sillas/banquetas' className=''>Banquetas</a>
                            <a href='/sillas/de-escritorio' className=''>de Escritorio</a>
                        </div>
                    </div>
                }
                {
                    selectMenu === 'Puffs' &&
                    <div className='mt-5 mx-[20px]'>
                        <div className='flex flex-row' onClick={() => setSelectMenu('Shop')}>
                            <img src={left} className='mr-3' alt="" />
                            <p className='font-semibold'>Puffs & Sillones</p>
                        </div>
                        <div className='grid grid-cols-2 my-5 text-left mx-[3px] gap-y-3'>
                            <a href="/puffs-sillones">Todos</a>
                            <a href='/puffs-sillones/puffs' className=''>Puffs</a>
                            <a href='/puffs-sillones/sillones' className=''>Sillones</a>
                        </div>
                    </div>
                }
                {
                    selectMenu === 'Exterior' &&
                    <div className='mt-5 mx-[20px]'>
                        <div className='flex flex-row' onClick={() => setSelectMenu('Shop')}>
                            <img src={left} className='mr-3' alt="" />
                            <p className='font-semibold'>Exterior</p>
                        </div>
                        <div className='grid grid-cols-2 my-5 text-left mx-[3px] gap-y-3'>
                            <a href="/exterior">Todos</a>
                            <a href='/exterior/exterior-sillas' className='hover:font-semibold pl-1'>Sillas</a>
                            <a href='/exterior/exterior-mesas' className='hover:font-semibold pl-1'>Mesas</a>
                            <a href='/exterior/exterior-sillones' className='hover:font-semibold pl-1'>Sillones</a>
                        </div>
                    </div>
                }
                {
                    selectMenu === 'Deco' &&
                    <div className='mt-5 mx-[20px]'>
                        <div className='flex flex-row' onClick={() => setSelectMenu('Shop')}>
                            <img src={left} className='mr-3' alt="" />
                            <p className='font-semibold'>Deco & Iluminación</p>
                        </div>
                        <div className='grid grid-cols-2 my-5 text-left mx-[3px] gap-y-3'>
                            <a href="/deco-iluminaciones">Todos</a>
                            <a href='/deco-iluminaciones/almohadones' className='hover:font-semibold pl-1'>Almohadones</a>
                            <a href='/deco-iluminaciones/lamparas' className='hover:font-semibold pl-1'>Lámparas</a>
                            <a href='/deco-iluminaciones/objetos' className='hover:font-semibold pl-1'>Objetos</a>
                        </div>
                    </div>
                }
                {
                    selectMenu === 'Otros' &&
                    <div className='mt-5 mx-[20px]'>
                        <div className='flex flex-row' onClick={() => setSelectMenu('Shop')}>
                            <img src={left} className='mr-3' alt="" />
                            <p className='font-semibold'>Otros</p>
                        </div>
                        <div className='grid grid-cols-2 my-5 text-left mx-[3px] gap-y-3'>
                            <a href='/otros/kids' className='hover:font-semibold pl-1'>Kids</a>
                            <a href='/otros/pets' className='hover:font-semibold pl-1'>Pets</a>
                        </div>
                    </div>
                }
                <div className='mx-[20px] mt-4'>
                    <div className='border-b-[0.5px]'></div>
                    {!token && <div className='flex flex-col my-4 text-left'>
                        <a href='/ingresar'>Iniciar sesión</a>
                        <a href='/registrarse'>Registrarse</a>
                    </div>}
                    {token && <div className='flex flex-col my-4 text-left'>
                        <a href='/mis-datos'>Mi cuenta</a>
                        <div onClick={() => handleQuitSession()}>Salir</div>
                    </div>
                    }
                    <div className='border-b-[0.5px]'></div>
                    <div className='mt-4 text-left'>
                        <p>hola@sentate-conestilo.com</p>
                        <div>
                            <img src="" alt="" />
                            <img src="" alt="" />
                            <img src="" alt="" />
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
};

export default Menu;
